import common_ca from './ca/common.json';
import common_es from './es/common.json';
import custom_ca from '../overrides/i18n/ca.json';
import custom_es from '../overrides/i18n/es.json';
import { getUrlParams } from "../utils/misc";
import jwt_decode from 'jwt-decode';
import { store } from "../store/configureStore";
import Cookies from '../utils/cookies';
import Settings from "../settings";
import _ from 'lodash';

const PARTNER_LANGUAGES = [
  { localeCode: "ca_ES", displayName: "Català" },
  { localeCode: "es_ES", displayName: "Español" },
  { localeCode: "en_US", displayName: "English" },
  { localeCode: "gl_ES", displayName: "Galego" },
  { localeCode: "fr_FR", displayName: "Français" },
  { localeCode: "de_DE", displayName: "Deutsch" },
];

const updateTranslationsWithCustom = () => {
  _.merge(common_ca, custom_ca);
  _.merge(common_es, custom_es);
}

updateTranslationsWithCustom();

export const resources = {
  ca: {
    common: common_ca,
  },
  es: {
    common: common_es,
  },
};

const getLangCode = (locale) => {
  return locale.indexOf('-') > 0 ? locale.split('-')[0]
    : locale.indexOf('_') > 0 ? locale.split('_')[0]
    : locale
}

const DEFAULT_LANGUAGE = getLangCode(Settings.language.default);

export const getLangByToken = (token) => {
  try {
    const token_values = jwt_decode(token);
    return getLangCode(token_values.locale);
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
}

const getLangByUrlParam = () => {
  try {
    const urlParams = getUrlParams();
    return (urlParams && urlParams.lang && getLangCode(urlParams.lang)) || null;
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
};

const getLangByCookies = () => {
  let lang = Cookies.getLang();
  return (typeof lang === 'string') ? 
    getLangCode(Cookies.getLang()) : null
};

export const getLangByBrowser = () => {
  try {
    return getLangCode(navigator.language);
  } catch (e) {
    console.debug('ERROR getting browser language');
    return null;
  }
}

export const getLang = () => {
  let lang = getLangByUrlParam();
  if (lang !== null) {
    Cookies.saveLang(lang);
  } else {
    lang = getLangByCookies();
    if (lang === null) {
      const auth = store.getState().auth;
      const token = (store.getState().auth === undefined) ? null : store.getState().auth.token;
      
      if (auth && token) {
        console.debug('Getting lang by token');
        lang = getLangByToken(token);
      } else {
        console.debug('Getting lang by browser');
        lang = getLangByBrowser();
      }
    }
  }
  return (lang !== null) ? lang : DEFAULT_LANGUAGE;
}

export const getAvailablePartnerLangs = () => {
  const enabledLangs = _.get(Settings, "language.partner", []);
  return PARTNER_LANGUAGES.filter(
    (partnerLang) => enabledLangs.indexOf(partnerLang.localeCode) !== -1
  );
};