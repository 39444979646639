import _ from "lodash";
import {
    FETCH_INVOICES_REQUEST,
    RECEIVE_INVOICES,
    EXPORT_INVOICE_REQUEST, EXPORT_INVOICE_DONE, MESSAGE_INVOICE
} from '../constants/index'
import {
    data_fetch_api_resource,
    data_download_api_resource
} from '../utils/http_functions'
import {
    parseJSON
} from '../utils/misc'
import {
    logoutAndRedirect
} from './auth'
import saveAs from 'file-saver';
import Settings from "../settings";
import { i18n } from "../config";


export function fetchInvoicesRequest(initial) {
    const message = (initial)?null:"Actualizando lista de facturas...";

    return {
        type: FETCH_INVOICES_REQUEST,
        payload: {
            message,
        },
    };
}

export function receiveInvoices(response, initial) {
    const message = (initial)?null:"Listado de facturas actualizado";
    return {
        type: RECEIVE_INVOICES,
        payload: {
            response,
            message,
        },
    };
}

export const messageInvoice = message => {
    return {
        type: MESSAGE_INVOICE,
        payload: {
            message
        }
    }
}

export function fetchInvoices(token, contractId = false, initial = false, offset = 0) {
    return (dispatch) => {
        dispatch(fetchInvoicesRequest(initial));


        let resource;
        if (contractId) {
            resource = `contracts/${contractId}/invoices/`;
        } else {
            resource = 'invoices/';
        }
        resource = `${resource}?offset=${offset}&limit=${_.get(Settings, "invoices.itemsPerPage", 100)}`;

        data_fetch_api_resource(token, resource)
            .then(parseJSON)
            .then((response) => {
                dispatch(receiveInvoices(response, initial));
            })
            .catch((error) => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
            });
    };
}

export function exportInvoiceRequest(invoice) {
    return {
        type: EXPORT_INVOICE_REQUEST,
        payload: {
            invoice,
            message: null,
        },
    };
}

export function exportInvoiceDone(invoice) {
    return {
        type: EXPORT_INVOICE_DONE,
        payload: {
            invoice
        },
    };
}

export function exportInvoice(token, invoice) {
    return (dispatch) => {
        dispatch(exportInvoiceRequest(invoice));
        if (_.get(Settings, "invoices.downloadInNewTab", false)) {
            let newTab = window.open(i18n.t('common:url.loading'), '_blank');
            return data_download_api_resource(token, "invoices/" + invoice + "/pdf/")
                .then(response => response.data)
                .then(blob => URL.createObjectURL(blob))
                .then(anUrl => {
                    newTab.location.href = anUrl;
                    URL.revokeObjectURL(i18n.t('common:url'));
                })
                .catch(error => {
                    if (error.status === 401) {
                        dispatch(logoutAndRedirect(error));
                    }
                })
                .finally(() => {
                    dispatch(exportInvoiceDone(invoice));
                });
        } else {
            data_download_api_resource(token, `invoices/${ invoice }/pdf/`)
              .then(response => {
                  const filename = response.headers["content-disposition"].split("=");
                  dispatch(messageInvoice(i18n.t('common:text.invoices_download_ready')));
                  saveAs(response.data, filename[1]);
              })
              .catch(error => {
                  if (error.status === 401) {
                      dispatch(logoutAndRedirect(error));
                  }
              })
              .finally(() => {
                  dispatch(exportInvoiceDone(invoice));
              });
        }
    };
}
