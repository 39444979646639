import { combineReducers } from 'redux';
import auth from './auth';
import about from './about';
import profile from './profile';
import password from './password';
import settings from './settings';

import contracts from './contracts';
import contractsGas from './contractsGas';
import newElectricityContract from "./newElectricityContract";
import newGasContract from "./newGasContract";
import invoices from './invoices';
import invoicesGas from './invoicesGas';
import contact from './contact';
import { atcTipusReducer, atcSubtipusReducer } from './atcReducer';
import ibansReducer from "./ibans";
import CRM from "./CRM";
import { LOGOUT_SUCCESS } from '../constants/index';

const appReducer = combineReducers({
    auth,
    profile,
    about,
    password,
    settings,
    contracts,
    contractsGas,
    newElectricityContract,
    newGasContract,
    invoices,
    invoicesGas,
    contact,
    atcTipus: atcTipusReducer,
    atcSubtipus: atcSubtipusReducer,
    ibans: ibansReducer,
    CRMCases: CRM
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
    // Clear all states except routing
        Object.keys(state).forEach((oneState, index) => {
            if (oneState !== 'routing') { state[oneState] = undefined; }
        });
    }
    return appReducer(state, action);
};

export default rootReducer;
