import _ from "lodash";
import {
  FETCH_CRM_CASE_LIST,
  FETCH_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_SECTIONS,
  FETCH_CRM_CASE_CATEGORIES,
  ADD_CRM_CASE_MESSAGE,
  OPEN_CRM_CASE_MESSAGE,
} from "../constants";

import { parseResponse, CRMService } from "../utils/http_functions";
import jwt_decode from "jwt-decode";

export const FETCH_CRM_CASE_SUCCESSFUL_MSG = "Caso CRMs obtenido con éxito";
export const FETCH_CRM_CASE_FAILURE_MSG = "Error durante la obtención del caso CRM";

export const actionSuccessfulCRMCasesFetch = (actionType, data, section) => {
  return {
    type: actionType,
    payload: {
      data: data,
      fetchedSectionCases: section,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    }
  }
};

export const actionFailedCRMCasesFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};

export const actionSuccessfulCRMCaseDetailFetch = (actionType, data) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    }
  }
};

export const actionFailedCRMCaseDetailFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};


export const fetchCRMCases = (section) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCasesList(section)
  .then(
    response => {
      dispatch(
        actionSuccessfulCRMCasesFetch(FETCH_CRM_CASE_LIST, _.get(response, 'data.items', []), section)
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedCRMCasesFetch(FETCH_CRM_CASE_LIST));
      return parseResponse(error);
    }
  )
};

export const fetchCRMCaseDetail = (id) => async (dispatch, getState) => {
  if (!_.isEmpty(getState().CRMCases.case) && getState().CRMCases.case.id === id) {
    dispatch(
      actionSuccessfulCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL, getState().CRMCases.case)
    )
    return [200, getState().CRMCases.case];
  }
  else {
    const _CRMService = new CRMService();
    return await _CRMService.getCRMCaseDetail(id)
    .then(
      response => {
        // Order case mails by date
        let data = _.get(response, 'data', []);
        if( !_.isEmpty(data) && data.mails.length > 1 ){
          data.mails = data.mails.sort((a,b) => {
            return new Date(a.date) - new Date(b.date);
          })
        }
        dispatch(
          actionSuccessfulCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL, data)
        )
        return parseResponse(response);
      }
    )
    .catch(
      error => {
        dispatch(actionFailedCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL));
        return parseResponse(error);
      }
    )
  }
};

export const fetchCRMCaseSections = (section) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseSections(section)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_SECTIONS,
        payload: {
          data: response,
          fetchedSections: section
        }
      });
    }
  )
}

export const fetchCRMCaseCategories = (id) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseCategories(id)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_CATEGORIES,
        payload: {
          data: response
        }
      });
    }
  )
}

export const sendNewCRMCase = data => async () => {
  const _CRMService = new CRMService();
  return await _CRMService.postCRMCase(data)
  .then(
    response => {
      return parseResponse(response);  
    }
  )
  .catch(
    error => {
      return parseResponse(error); 
    }
  );
}

export const sendNewCRMCaseMessage = (id, data) => async (dispatch, getState) => {
  const token_values = jwt_decode(getState().auth.token)
  const _CRMService = new CRMService();
  return await _CRMService.postCRMCaseMessage(id, data)
  .then(
    response => {
      const d = new Date();
      const date = d.getHours() + ":" + d.getMinutes() 
        + ":" + d.getSeconds() + " " + d.getDay()
        + "/" + d.getMonth() + "/" + d.getFullYear()
      dispatch({
        type: ADD_CRM_CASE_MESSAGE,
        payload: {
          data: {
            'message': data.message,
            'date': date,
            'from': 
              {
                'customer': true,
                'email': token_values.email,
                'name': token_values.name
              },
            'attachments': response.data
          }
        }
      });
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      return parseResponse(error);
    }
  );
}

export const downloadAttachment = (caseId, attachmentId) => async () => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDownloadAttachment(caseId, attachmentId)
  .then(
    response => {
      return parseResponse(response);
    }
  )
}  

export const openCRMMessageDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_CRM_CASE_MESSAGE,
    payload: {}
  })
}