import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ErrorMessage from "../ErrorMessage";

import { CircularProgress, Button, MenuItem,
  FormControl, InputLabel, Select,
  Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import {
  data_update_api_resource,
  data_fetch_api_resource,
} from "../../utils/http_functions";
import { i18n } from "../../config";
import { locale_code } from "../../constants/i18n";

class CustomizeHappyDay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dayValue: 0,
      days: [],

      selectedDay: 0,
      dialogOpen: false,
      error: false,
      sending: false,
    };
    moment.locale(locale_code);
  }

  fetchActual = async () => {
    const response = await data_fetch_api_resource(
      null,
      `contract/${this.props.contractId}/happy-day/`
    );

    if (response && !_.isEmpty(response.data)) {
      const { data } = response;
      this.setState({
        dayValue: data.value,
        dayLabel: data.label,
        selectedDay: data.value,
      });
    }
  };

  handleRequestClose = async () => {
    this.setState({ dialogOpen: false });
  };

  handleRequestOpen = async () => {
    // Fetch available options
    this.setState({ error: false });
    try {
      const response = await data_fetch_api_resource(
        null,
        `contract/${this.props.contractId}/available-happy-days/`
      );

      if (response && !_.isEmpty(response.data)) {
        const days = response.data;
        this.setState({
          days,
        });
      }
    } catch (error) {
      this.setState({ error: true });
      // TODO: Add sentry
    }

    this.setState({ dialogOpen: true });
  };

  handleChangeDay = (event, index, value) => {
    this.setState({ selectedDay: event.target.value });
  };

  handleUpdateDay = async () => {
    this.setState({ error: false, sending: true });
    const { days, selectedDay } = this.state;
    console.log("Selected value: ", selectedDay);
    const selectedDayObj = days.find((e) => e.value === selectedDay);
    console.log("Selected object: ", selectedDayObj);
    try {
      await data_update_api_resource(
        null,
        `contract/${this.props.contractId}/happy-day/`,
        selectedDayObj
      );
      this.setState({ sending: false });
      this.fetchActual();
      if (this.props.onUpdated) {
        this.props.onUpdated(selectedDayObj);
      }
    } catch (error) {
      // TODO: Add sentry
      this.setState({ error: true });
    } finally {
      this.setState({ dialogOpen: false });
    }
  };

  componentDidMount() {
    this.fetchActual();
  }

  formatDay = (day) => {
    return day;
  };

  render() {
    const { dayValue, dayLabel, selectedDay, dialogOpen, days, error, sending } =
      this.state;

    if (
      typeof dayValue == "undefined" ||
      dayValue === null ||
      dayValue === false ||
      dayValue === 0
    ) {
      return null;
    }

    let formattedDay = this.formatDay(dayLabel);

    let dayEntries = [];
    if (!_.isEmpty(days)) {
      dayEntries = days.map((d, index) => {
        return (
          <MenuItem
            key={index}
            value={d.value}
          >{this.formatDay(d.label)}</MenuItem>
        );
      });
    }

    return (
      <span>
        {formattedDay}
        <Button
          style={{ marginLeft: "1rem" }}
          variant={'text'}
          color={'primary'}
          onClick={this.handleRequestOpen}
        >
          {i18n.t('common:text.contract_modification')}
        </Button>
        <Dialog
          open={dialogOpen}
          disableBackdropClick={true}
          onClose={this.handleRequestClose}
          scroll={'body'}
        >
          <DialogTitle>
            {i18n.t('common:text.customize_happy_day_title')}
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControl>
                <InputLabel shrink htmlFor="">
                  {i18n.t('common:text.customize_happy_day_label')}
                </InputLabel>
                <Select
                  onChange={this.handleChangeDay}
                  value={selectedDay || 0}
                  style={{ width: "100%", maxWidth: "300px" }}
                  autoWidth={true}
                >
                  {dayEntries}
                </Select>
              </FormControl>
            </div>
            <div>{error && <ErrorMessage />}</div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                style={{ marginRight: "1rem" }}
                variant={'contained'}
                onClick={this.handleRequestClose}
                disabled={sending}
              >
                {i18n.t('common:text.customize_happy_day_close')}
              </Button>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={this.handleUpdateDay}
                disabled={sending || selectedDay === dayValue}
              >
                {sending ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.customize_happy_day_update')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

export default CustomizeHappyDay;

CustomizeHappyDay.defaultProps = {
  readonly: false,
};

CustomizeHappyDay.propTypes = {
  readonly: PropTypes.bool,
  contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
