import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import * as gasActionCreators from "../../actions/newGasContract";
import _ from "lodash";

import { Step, Stepper, StepLabel, Box, Typography } from "@material-ui/core";
import { i18n } from "../../config";

import Settings from "../../settings";
import { collectFormData } from "./form-data";
import { sendElectricityLeads, sendGasLeads } from "../../utils/http_functions";

import FormAboutHome from "./FormAboutHome";
import FormAboutYou from "./FormAboutYou";
import FormPayment from "./FormPayment";
import FormAboutContract from "./FormAboutContract";
import FormAboutGasContract from "./FormAboutGasContract";
import Confirm from "./Confirm";
import Confirmed from "./Confirmed";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    electricity: state.newElectricityContract,
    gasProps: state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    electricityCreators: bindActionCreators(actionCreators, dispatch),
    gasCreators: bindActionCreators(gasActionCreators, dispatch),
  };
}

const NewElectricityContract = (props) => {

  const stepLimit = 7;
  const [index, setIndex] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateWindowDimensions = () => {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);


  const stepTabs = (
    <Stepper activeStep={index - 1} orientation={screenWidth > 996 ? "horizontal" : "vertical" }>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_home')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_you')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_contract')}</StepLabel>
      </Step>
      {props.gas && (
        <Step>
          <StepLabel>{i18n.t('common:text.contractation_about_gas_contract')}</StepLabel>
        </Step>
      )}
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_about_payment')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_confirmation')}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{i18n.t('common:text.contractation_confirmed')}</StepLabel>
      </Step>
    </Stepper>
  );

  const handleSubmit = () => {
    if (index < stepLimit) {
      let inc = 1;
      if (!props.gas && index === 3) {
        inc = 2;
      }
      setIndex(index + inc);
    }
  };

  const handleGoBack = () => {
    if (index !== 1) {
      let dec = 1;
      if (!props.gas && index === 5) {
        dec = 2;
      }
      setIndex(index - dec);
    }
  };

  const steps = [
    {
      leadIndex: 1,
      content: <FormAboutHome onSubmit={handleSubmit} gas={props.gas} />,
    },
    {
      leadIndex: 2,
      content: (
        <FormAboutYou
          onGoBack={handleGoBack}
          onSubmit={handleSubmit}
          gas={props.gas}
        />
      ),
    },
    {
      leadIndex: 3,
      content: (
        <FormAboutContract onGoBack={handleGoBack} onSubmit={handleSubmit} />
      ),
    },
    {
      leadIndex: 3,
      content: (
        <FormAboutGasContract onGoBack={handleGoBack} onSubmit={handleSubmit} />
      ),
    },
    {
      leadIndex: 4,
      content: <FormPayment onGoBack={handleGoBack} onSubmit={handleSubmit} />,
    },
    {
      leadIndex: 5,
      content: <Confirm onGoBack={handleGoBack} onSubmit={handleSubmit} />,
    },
    { leadIndex: 5, content: <Confirmed onGoBack={handleGoBack} /> },
  ];

  const stepContent = () => {
    return steps[index - 1].content;
  };

  useEffect(() => {
    props.electricityCreators.fetchContractationConfig();
    // eslint-disable-next-line
  }, []);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevIndex = usePrevious(index);

  useEffect(() => {
    (async () => {
      const leadIndex = steps[index - 1].leadIndex;

      if (
        _.get(Settings, "features.leads", false) &&
        props.electricity.leadsConsent &&
        index !== stepLimit &&
        leadIndex &&
        prevIndex < index
      ) {
        const formData = collectFormData(props.electricity);
        const electricityResponse = await sendElectricityLeads({
          FormConfirm: formData,
          step: leadIndex,
        });

        if (props.gas && props.gasProps.cups) {
          const gasFormData = collectFormData({
            ...props.electricity,
            cups: props.gasProps.cups,
            comerOrigin: props.gasProps.comerOrigin,
            accessTariff: props.gasProps.accessTariff,
            selectedProduct: props.gasProps.selectedProduct,
            invoice: props.gasProps.invoice,
            owner: {
              ...props.electricity.owner,
              changeOwner: props.gasProps.changeOwner,
            },
          });
          gasFormData.step = leadIndex;
          if (_.get(electricityResponse, "data.lead_id")) {
            gasFormData.electricityLeadId = electricityResponse.data.lead_id;
          }
          sendGasLeads(gasFormData);
        }
      }

      // Confirm
      if (index === stepLimit) {
        const formData = collectFormData(props.electricity);
        formData.step = leadIndex;
        const electricityResponse = await sendElectricityLeads({
          FormConfirm: formData,
          step: leadIndex,
        });
        formData.confirm = true;
        props.electricityCreators.confirmNewContract({
          FormConfirm: formData,
          step: leadIndex,
        });

        if (props.gas) {
          const gasFormData = collectFormData({
            ...props.electricity,
            cups: props.gasProps.cups,
            comerOrigin: props.gasProps.comerOrigin,
            accessTariff: props.gasProps.accessTariff,
            selectedProduct: props.gasProps.selectedProduct,
          });
          gasFormData.step = leadIndex;
          if (_.get(electricityResponse, "data.lead_id")) {
            gasFormData.electricityLeadId = electricityResponse.data.lead_id;
          }
          gasFormData.confirm = true;
          props.gasCreators.confirmNewContract(gasFormData);
        }
      }
    })();
    // eslint-disable-next-line
  }, [index]);

  return (
    <div className="form-contractation">
      <Box>
        <Typography variant="h4">
          {props.gas ? 
          i18n.t('common:text.contractation_dual_title') : 
          i18n.t('common:text.contractation_title')
          }
        </Typography>
      </Box>
      {stepTabs}
      {stepContent()}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewElectricityContract);
