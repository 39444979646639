import React, { useState } from "react";
import _ from "lodash";
import { history } from '../../config';
import { connect } from 'react-redux';
import { i18n } from "../../config";
import Settings from "../../settings";

import { Button, Box, Typography } from "@material-ui/core"
import { Help, KeyboardArrowRight } from "@material-ui/icons"

function mapStateToProps(state) {
  return {
      isAuthenticated: state.auth.isAuthenticated,
  };
}

const HelpDialog = (props) => {
  const [folded, setFolded] = useState(true);
  const email = _.get(Settings, "organization.email", "");
  const phoneLink = _.get(Settings, "organization.phone_crm", "").replace(
    " ",
    ""
  );
  const phone = _.get(Settings, "organization.phone_crm", "");
  
  let contactUrl = i18n.t('common:url.unsigned_contact');
  if (props.isAuthenticated) {
    contactUrl = i18n.t('common:url.contact');
  }

  const handleFold = () => {
    setFolded(!folded);
  }

  const handleContact = () => {
    const url = _.get(Settings, "helpDialog.customURL", contactUrl);
    ((url.search('www') !== -1) || url.search('http') !== -1) ? 
      window.location.replace(url) : history.push(url);
  };

  return (
    <div className={"help-dialog" + (folded ? " folded" : "")}>
      {folded ? (
        <Help className="hide-button" onClick={handleFold} />
      ) : (
        <KeyboardArrowRight className="hide-button" onClick={handleFold} />
      )}
      {!folded && (
        <div>
          <Box mb={1}>
            <Typography variant="h5" noWrap>
              {i18n.t('common:text.help_dialog_title')}
            </Typography>
          </Box>
          {_.get(Settings, "helpDialog.onlyButton", false) ?
            <div style={{marginTop:30}}>
              {i18n.t('common:text.help_dialog_custom_text')}
            </div>
            :
            <div>
              <Button size="small" href={`tel:${email}`} color="primary">
                {email}
              </Button>

              <Button size="small" href={`tel:${phoneLink}`} color="primary">
                {phone}
              </Button>
            </div>
          }

          {(_.get(Settings, "features.contact", false) ||
            _.get(Settings, "features.unsignedContact", false) ||
            _.get(Settings, "helpDialog.customURL", false)) && (
            <Box mt={1}>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleContact}
              >
                {i18n.t('common:text.help_dialog_contact_button')}
              </Button>
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, {})(HelpDialog);
