import { randomString, generateHash } from './misc';
import Cookies from "js-cookie";


export function store_token(token) {
    Cookies.set('token', token, { secure: true, sameSite: 'Lax' } );
}

export function retrieve_token() {
    return Cookies.get('token');
}

export function remove_token() {
    Cookies.remove('token');
}

// ----------------------------------------------------------------------------
// OpenID Connect
// ----------------------------------------------------------------------------

export function store_openid_token(token) {
    Cookies.set('openid_token', token, { secure: true, sameSite: 'Lax' });
}

export function retrieve_openid_token() {
    return Cookies.get('openid_token');
}

export function remove_openid_token() {
    Cookies.remove('openid_token');
}

export function generateAndSaveState(extraString) {
    const state = randomString() + extraString;
    Cookies.set('openid_state', state, { secure: true, sameSite: 'Lax' });

    return state;
}

export function isStateValid(state) {
    const savedState = Cookies.get('openid_state');

    return savedState === state;
}

export function generateAndSaveNonce() {
    // Get random string
    const nonce = randomString();
    // Save nonce to Cookies
    Cookies.set('openid_nonce', nonce, { secure: true, sameSite: 'Lax' });

    // Return hash of generated nonce
    return generateHash(nonce);
}

export function getNonce() {
    return Cookies.get('openid_nonce');
}

export function isNonceValid(nonceHash) {
    // Retrieve nonce from browser cookies
    const storedNonce = Cookies.get('openid_nonce');
    // Generate a hash
    const storedNonceHash = generateHash(storedNonce);
    
    // Compare if stored nonce is the same of received nonce
    return storedNonceHash === nonceHash;
}

export function clearState() {
    Cookies.remove('openid_state');
}

export function clearNonce() {
    Cookies.remove('openid_nonce');
}