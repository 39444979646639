import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import _ from 'lodash';
import { getLang, resources } from "../../i18n";
import Settings from "../../settings";

const SUPPORTED_LANGS = ['es', 'ca'];
const lang = getLang();

const defaultLanguage = _.get(Settings, "language.default", 'es')

i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: process.env.NODE_ENV === 'development',
    fallbackLang: defaultLanguage,
    lng: _.indexOf(SUPPORTED_LANGS, lang) > -1 ? lang : defaultLanguage,
    ns: [
      'common', 'validation'
    ],
    defaultNs: 'common',
  });


export default i18n;