import Settings from "../settings";
import {
  data_fetch_api_resource,
  data_create_api_resource,
} from "../utils/http_functions";
import { parseJSON } from "../utils/misc";
import { logoutAndRedirect } from "./auth";
import {
  SUBMIT_ABOUT_HOME_GAS_DATA,
  SUBMIT_ABOUT_YOU_GAS_DATA,
  SET_INCLUDE_GAS,
  SET_CUPS,
  SET_GAS_ACCESS_TARIFF,
  SET_GAS_COMER_ORIGIN,
  SET_CHANGE_OWNER,
  SET_GAS_INVOICE,
  SUBMIT_ABOUT_CONTRACT_GAS_DATA,
  SUBMIT_PAYMENT_GAS_DATA,
  SUBMIT_CONFIRM_GAS_DATA,
  STORE_SIPS_GAS_DATA,
  SELECT_GAS_PRODUCT,
  FETCH_AVAILABLE_GAS_PRODUCTS_REQUEST,
  RECEIVE_AVAILABLE_GAS_PRODUCTS,
  NEW_GAS_CONTRACT_CONFIRM_REQUEST,
  NEW_GAS_CONTRACT_CONFIRM_RECEIVE,
  FETCH_GAS_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_GAS_CONTRACTATION_CONFIG,
} from "../constants";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import { i18n } from "../config";

// ----------------------------------------------------------------------------
// New Contract Form
// ----------------------------------------------------------------------------

export const submitAboutHomeData = ({
  leadsConsent,
  cups,
  address,
  habitual,
  cnae,
  invoice,
  comerOrigin,
}) => {
  return {
    type: SUBMIT_ABOUT_HOME_GAS_DATA,
    payload: {
      leadsConsent,
      cups,
      address,
      habitual,
      cnae,
      invoice,
      comerOrigin,
    },
  };
};

export const submitAboutYouData = ({
  vat,
  company,
  name,
  surName1,
  surName2,
  cif,
  changeOwner,
  vatRepresentante,
  nameRepresentante,
  surName1Representante,
  surName2Representante,
  sameInvoiceAddress,
  invoiceAddress,
  commsLanguage,
  email,
  mobile,
  nifFaceup,
}) => {
  return {
    type: SUBMIT_ABOUT_YOU_GAS_DATA,
    payload: {
      vat,
      company,
      name,
      surName1,
      surName2,
      cif,
      changeOwner,
      vatRepresentante,
      nameRepresentante,
      surName1Representante,
      surName2Representante,
      sameInvoiceAddress,
      invoiceAddress,
      commsLanguage,
      email,
      mobile,
      nifFaceup,
    },
  };
};

export const setCUPS = (value) => {
  return {
    type: SET_CUPS,
    payload: {
      cups: value,
    }
  };
};

export const setIncludeGas = (value) => {
  return {
    type: SET_INCLUDE_GAS,
    payload: {
      includeGas: value,
    }
  };
};

export const setAccessTariff = (value) => {
  return {
    type: SET_GAS_ACCESS_TARIFF,
    payload: {
      accessTariff: value,
    }
  };
};

export const setChangeOwner = (value) => {
  return {
    type: SET_CHANGE_OWNER,
    payload: {
      changeOwner: value,
    }
  };
};

export const setComerOrigin = (value) => {
  return {
    type: SET_GAS_COMER_ORIGIN,
    payload: {
      comerOrigin: value,
    }
  };
};

export const setInvoice = (value) => {
  return {
    type: SET_GAS_INVOICE,
    payload: {
      invoice: value,
    }
  };
};

export const submitAboutContractData = ({
  cups,
  comerOrigin,
  selectedProduct,
  includeGas,
  changeOwner,
  invoice
}) => {
  return {
    type: SUBMIT_ABOUT_CONTRACT_GAS_DATA,
    payload: {
      cups,
      comerOrigin,
      selectedProduct,
      includeGas,
      changeOwner,
      invoice,
    },
  };
};

export const submitPaymentData = ({ iban, isOwner }) => {
  return {
    type: SUBMIT_PAYMENT_GAS_DATA,
    payload: {
      iban,
      isOwner,
    },
  };
};

export const submitConfirmData = ({
  promotionalCode,
  acceptOVConditions,
  acceptGdpr,
}) => {
  return {
    type: SUBMIT_CONFIRM_GAS_DATA,
    payload: {
      promotionalCode,
      acceptOVConditions,
      acceptGdpr,
    },
  };
};

// ----------------------------------------------------------------------------
// Contractation config (prefilled fields)
// ----------------------------------------------------------------------------

export const fetchContractationConfigRequest = () => {
  return {
    type: FETCH_GAS_CONTRACTATION_CONFIG_REQUEST,
  };
};

export const receiveContractationConfig = (response) => {
  return {
    type: RECEIVE_GAS_CONTRACTATION_CONFIG,
    payload: {
      contractationConfig: response,
    },
  };
};

export const fetchContractationConfig = () => {
  return async (dispatch) => {
    dispatch(fetchContractationConfigRequest());
    try {
      const json = await data_fetch_api_resource(null, `contractacio/config`);
      const response = await parseJSON(json);
      dispatch(receiveContractationConfig(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.newGasContractation &&
        Settings.newGasContract.authenticate &&
        e.status === 401
      ) {
        dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveContractationConfig(null));
      }
    }
  };
};

export const storeSIPSData = (data) => {
  return {
    type: STORE_SIPS_GAS_DATA,
    payload: {
      sipsData: data,
    },
  };
};

// ----------------------------------------------------------------------------
// Gas Products
// ----------------------------------------------------------------------------

export const selectGasProduct = (product) => {
  return {
    type: SELECT_GAS_PRODUCT,
    payload: {
      product,
    },
  };
};

export const fetchAvailableGasProductsRequest = () => {
  return {
    type: FETCH_AVAILABLE_GAS_PRODUCTS_REQUEST,
  };
};

const getFixedGasProductNameByParam = () => {
  const urlParams = window.location.search;
  console.log("urlParams=" + urlParams);
  let fixedProductName;
  if (urlParams) {
    // Get URL parameters object
    const values = queryString.parse(urlParams);
    fixedProductName = values.tarifa;
  }
  return fixedProductName;
};

const filterAvailableGasProductsByUrlParam = (currentAvailableProducts) => {
  // If we receive a product name by URL parameter we will only show that
  // product.
  // If we don't have any product with that name, we won't show any
  // selectable product.
  const fixedProductName = getFixedGasProductNameByParam();
  if (!fixedProductName) {
    return currentAvailableProducts;
  }
  let filteredAvailableProducts;
  let productMatch = currentAvailableProducts.find(
    (t) => t.name === fixedProductName
  );
  if (productMatch) {
    // We only show one selectable product.
    filteredAvailableProducts = [productMatch];
  } else {
    // We don't show any product
    filteredAvailableProducts = [];
  }
  return filteredAvailableProducts;
};

export const receiveAvailableGasProducts = (response) => {
  const filteredProducts = filterAvailableGasProductsByUrlParam(response);
  return {
    type: RECEIVE_AVAILABLE_GAS_PRODUCTS,
    payload: {
      availableProducts: filteredProducts,
    },
  };
};

export const fetchAvailableGasProducts = (
  cups,
  accessTariff
) => {
  return async (dispatch) => {
    dispatch(fetchAvailableGasProductsRequest());
    try {
      const json = await data_fetch_api_resource(
        null,
        `gas/pricelist?cups=${cups}&atr=${accessTariff || ''}`,
        2
      );
      const response = await parseJSON(json);
      dispatch(receiveAvailableGasProducts(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.newGasContraction &&
        Settings.newGasContract.authenticate &&
        e.status === 401
      ) {
        dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveAvailableGasProducts([]));
      }
    }
  };
};

export const confirmNewContractRequest = () => {
  return {
    type: NEW_GAS_CONTRACT_CONFIRM_REQUEST,
  };
};

export const receiveConfirmNewContractResponse = (response) => {
  return {
    type: NEW_GAS_CONTRACT_CONFIRM_RECEIVE,
    payload: {
      confirmResponse: response,
    },
  };
};

export const confirmNewContract = (postData) => {
  return async (dispatch) => {
    dispatch(confirmNewContractRequest());
    try {
      const json = await data_create_api_resource(
        null,
        "gas/leads",
        postData,
        2
      );
      const response = await parseJSON(json);
      dispatch(receiveConfirmNewContractResponse(response));
    } catch (e) {
      Sentry.captureException(e);
      if (
        Settings.newGasContraction &&
        Settings.newGasContract.authenticate &&
        e.status === 401
      ) {
        dispatch(logoutAndRedirect(e));
      } else {
        dispatch(receiveConfirmNewContractResponse({
          result: {
            error: true,
            render: {
              format: 'markdown',
              body: i18n.t('common:text.contractation_connectivity_error')
            }
          }
        }));
      }
    }
  };
};
