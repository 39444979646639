import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import CRMListItem from "./components/CRMListItem";
import { withTranslation } from "react-i18next";
import { InfoContainer } from "../InfoContainer";
import { LoadingAnimation } from '../LoadingAnimation';
import { Fade, Table, TableBody, Button } from "@material-ui/core";
import { fetchCRMCases } from '../../actions/crm';
import { dispatchNewRoute } from '../../utils/http_functions';
import { i18n } from "../../config";

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
});

class CRMList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false
    };
  }

  componentDidMount() {
    this.updateCRMCases();
  }

  updateCRMCases() {
    if(_.isEmpty(this.props.CRMCases.items) || this.props.fetchedSectionCases !== _.get(this, "props.state.section", null)) {
      this.props.fetchCRMCases(_.get(this, "props.state.section", 'OV'))
      .then(
        ([statusCode,]) => {
          this.setState(() => ({ isFetching: false }));
          if (statusCode === 401) {
            //this.props.openGlobalSnackbar(this.props.t('common:validation.error_fetching_data'), true);
          }
        }
      );
    } else {
      this.setState(() => ({ isFetching: false }));
    } 
  }

  renderListItems () {
    if(!_.isEmpty(this.props.CRMCases.items)){
      return this.props.CRMCases.items.map(
        (el, _) => (
         <CRMListItem
            key={el.id}
            detailCase={el}
            handleClick={() => {dispatchNewRoute(i18n.t('common:url.CRM_detail_id', { id: el.id }))}}
            t={this.props.t}
          />
        )
      )
    } else {
      return null;
    }
  }

  renderTable() {
    if (!this.state.isFetching) {
      return (
        <Table>
          <TableBody>
            {this.renderListItems()}
          </TableBody>
        </Table>
      )
    }
    else {
      return <LoadingAnimation/>;
    }
  }

  newCaseButton() {
    return (
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => {dispatchNewRoute(i18n.t('common:url.CRM_create'))}}
      >
        {i18n.t('common:crm_new_case')}
      </Button>
    );
  }

  render() {
    const { classes, t } = this.props;
    return (
        <Fade in={true}>
            <InfoContainer
            title={t("common:crm_list_title")}
            subtitle={t("common:contract_list_subtitle")}
            className={classes.container}
            actionButton={this.newCaseButton()}
            >
            { this.state.isFetching ?
                <LoadingAnimation/>
                :
                this.renderTable()
            }
            </InfoContainer>
        </Fade>
    );
  }
}

const mapStateToProps = state => {
  return {
    CRMCases: _.get(state, 'CRMCases', {}),
    fetchedSectionCases: _.get(state, 'CRMCases.fetchedSectionCases', null),
    isSignedIn: state.auth.isAuthenticated,
    userName: state.auth.userName
  };
};

const mapDispatchToProps = {
  fetchCRMCases,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common'])
)(CRMList);
