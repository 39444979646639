import { createReducer } from '../utils/misc';

import {
    FETCH_CONTACT_REQUEST,
    RECEIVE_CONTACT,
    RECEIVE_CONTACT_ERROR,
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_OK,
    CREATE_CONTACT_KO,
} from '../constants/index'


const initialState = {
    data: {},
    isFetching: false,
    loaded: false,
    message_text: null,
    message_open: false,
    error: false,
};

export default createReducer(initialState, {
    [FETCH_CONTACT_REQUEST]: (state, payload) =>
        Object.assign({}, state, {
            isFetching: true,
            message_text: payload.message,
        }),

    [RECEIVE_CONTACT]: (state, payload) =>
        Object.assign({}, state, {
            data: payload.data,
            isFetching: false,
            loaded: true,
            message_text: payload.message,
        }),

    [RECEIVE_CONTACT_ERROR]: (state, payload) =>
        Object.assign({}, state, {
            error: true,
            isFetching: false,
            loaded: false,
            message_text: payload.message,
            message_open: true,
        }),

    [CREATE_CONTACT_OK]: (state, payload) =>
        Object.assign({}, state, {
            data: payload.data,
            isUpdating: false,
            loaded: true,
            statusText: payload.statusText,
            statusType: payload.statusType,
            message_open: true,
        }),
    [CREATE_CONTACT_KO]: (state, payload) =>
        Object.assign({}, state, {
            data: payload.data,
            isUpdating: false,
            loaded: true,
            status: payload.status,
            statusText: payload.statusText,
            statusType: payload.statusType,
            message_open: true,
        }),
    [CREATE_CONTACT_REQUEST]: (state) =>
        Object.assign({}, state, {
            isUpdating: true,
        }),

})
