import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as gasActionCreators from "../../actions/newGasContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import { data_fetch_api_resource } from "../../utils/http_functions";
import { i18n } from "../../config";

import CheckboxField from "../form-fields/CheckboxField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import GasProductSelectField from "../form-fields/GasProductSelectField";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import Address from "../Address";
import FlexRow from "../FlexRow";

import Settings from "../../settings";

import { CircularProgress, Button, Box, Typography } from "@material-ui/core"

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    electricity: state.newElectricityContract,
    ...state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(gasActionCreators, dispatch);
}

const FormAboutGasContract = (props) => {
  const [comerOriginEnabled, setComerOriginEnabled] = useState(false);
  const [cupsInvalid, setCupsInvalid] = useState(null);

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    props.submitAboutContractData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const validateCUPSAvailability = async (values, storeSIPSData = false) => {
    const result = await data_fetch_api_resource(
      null,
      `gas/contractacio/config/cups/${values.cups}`,
      2
    );
    let r = null;
    if (result && result.status === 200) {
      if (result.data.cups_availability) {
        if (!result.data.cups_availability.available) {
          r = result.data.cups_availability.reason;
          if (storeSIPSData) {
            props.setCUPS(values.cups);
          }
          setCupsInvalid(result.data.cups_availability.reason);
        } else {
          setCupsInvalid(null)
          if (storeSIPSData) {
            props.storeSIPSData({
              ...result.data,
              leadsConsent: props.leadsConsent,
              includeGas: props.includeGas,
              changeOwner: props.changeOwner,
              cups: values.cups,
            });
          }
        }
      }
    } else {
      r = i18n.t('common:text.cannot_validate_async');
    }

    return (r && { cups: r }) || null;
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value && cupsValid(value)) {
      validateCUPSAvailability(values, true);
    }
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleComerOriginUpdate = async (value) => {
    props.setComerOrigin(value);
  };

  const handleIncludeGasUpdate = async (value) => {
    props.setIncludeGas(value);
  };

  const handleAccessTariffSelectUpdate = async (value) => {
    props.setAccessTariff(value);
    let accessTariff = value;
    props.fetchAvailableGasProducts(props.cups, accessTariff);
  };

  const handleChangeOwnerUpdate = async (value) => {
    props.setChangeOwner(value);
  };

  const handleInvoiceUpdate = async (value) => {
    props.setInvoice(value);
  }

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">{i18n.t('common:text.contractation_about_gas_contract')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          includeGas: _.get(Settings, "newDualContract.gasIsMandatory", false)
            ? true
            : props.includeGas,
          cups: props.cups,
          changeOwner: props.changeOwner,
          accessTariff: props.accessTariff,
          selectedProduct: props.selectedProduct,
          comerOrigin: props.comerOrigin,
          invoice: props.invoice,
        }}
        validate={(values) => {
          const errors = {};

          if (values.includeGas) {
            if (!values.cups) {
              errors.cups = i18n.t('common:text.required_field');
            } else if (!cupsValid(values.cups)) {
              errors.cups = i18n.t('common:text.contractation_cups_not_valid');
            }

            if (!values.accessTariff) {
              errors.accessTariff = i18n.t('common:text.required_field');
            }

            if (!values.selectedProduct) {
              errors.selectedProduct = i18n.t('common:text.required_field');
            }

            const fileErrors = validateFileUpload(values.invoice, {
              min: 1,
              minMessage: i18n.t('common:text.multiupload_validation_min'),
            });
            if (fileErrors) {
              _.set(errors, "invoice", fileErrors);
            }

            return Object.keys(errors).length
              ? errors
              : validateCUPSAvailability(values, false);
          } else {
            return errors;
          }
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              {!_.get(Settings, "newDualContract.gasIsMandatory", false) && (
                <Field
                  name="includeGas"
                  label={i18n.t('common:text.contractation_include_gas')}
                  component={CheckboxField}
                  onUpdate={handleIncludeGasUpdate}
                />
              )}
            </div>
            {values.includeGas && (
              <div>
                {!_.isEmpty(props.electricity.address) && (
                  <div>
                    {i18n.t('common:text.contractation_address_validation')}
                    <Address readonly value={props.electricity.address} />
                  </div>
                )}
                <Field
                  name="cups"
                  component={CUPSField}
                  label={i18n.t('common:text.contractation_cups_gas')}
                  onUpdate={(value) =>
                    handleCUPSUpdate({ ...values, cups: value })
                  }
                  style={{width: 300}}
                  cupsAvailability={cupsInvalid}
                />
                <div className="helper-text">{i18n.t('common:text.contractation_cups_gas_helper')}</div>
                <Field
                  name="changeOwner"
                  label={
                    i18n.t('common:text.contractation_change_gas_owner')
                  }
                  component={CheckboxField}
                  onUpdate={handleChangeOwnerUpdate}
                />

                {(!props.sipsData || !props.accessTariff) && props.cups && (
                  <div>
                    <Field
                      name="accessTariff"
                      component={AccessTariffSelectField}
                      gas={true}
                      onSelectUpdate={handleAccessTariffSelectUpdate}
                    />
                  </div>
                )}
                {props.accessTariff && (
                  <Field
                    name="selectedProduct"
                    component={GasProductSelectField}
                  />
                )}
                <div
                  style={{ display: !comerOriginEnabled ? "none" : "block" }}
                >
                  <FlexRow>
                    <Field
                      name="comerOrigin"
                      component={ComerOriginSelectField}
                      onAvailableComersUpdate={handleAvailableComersUpdate}
                      onUpdate={handleComerOriginUpdate}
                      gas={true}
                    />
                  </FlexRow>
                </div>
                <FlexRow>
                  <Field
                    name="invoice"
                    component={FileUploadField}
                    min={1}
                    max={3}
                    label={i18n.t('common:text.contractation_last_invoice')}
                    hint={i18n.t('common:text.contractation_last_gas_invoice_helper')}
                    anotherLabel={i18n.t('common:text.contractation_last_invoice_add')}
                    removeLabel={i18n.t('common:text.remove')}
                    onUpdate={handleInvoiceUpdate}
                  />
                </FlexRow>
              </div>
            )}
            <div style={{ marginTop: 25 }}>
              <Button
                variant={'text'}
                onClick={handleGoBack}
                style={{ marginRight: 12 }}
                disabled={submitting}
              >
                {i18n.t('common:text.contractation_previous')}
              </Button>
              <Button
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={submitting || (cupsInvalid !== null)}
              >
                {submitting ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormAboutGasContract);
