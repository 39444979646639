import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/auth";

import { debug } from "../utils/debug";
import { i18n } from "../config";
import Settings from "../settings";
import moment from "moment";
import _ from "lodash";
import { Box, Typography } from "@material-ui/core";

const styles = {
  center: {
    textAlign: "center",
  },
};

const formatDate = (date, format) => {
  let formattedDate = "";
  if (date && format) {
    formattedDate = moment(date).format(format);
  }
  return formattedDate;
};

function mapStateToProps(state) {
  return {
    data: state.data,
    token: state.auth.token,
    userName: state.auth.userName,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class MaintenanceView extends React.Component {

  outOfOrderMessage() {
    let timeFormat = "HH:mm", dateFormat = "DD/MM/YYYY";

    let starts = _.get(Settings, "maintenance.starts");
    console.log('starts', starts);
    let startTime = formatDate(starts, timeFormat);
    let startDate = formatDate(starts, dateFormat);
    
    let ends = _.get(Settings, "maintenance.ends");
    console.log('ends', ends);
    let endTime = formatDate(ends, timeFormat);
    let endDate = formatDate(ends, dateFormat);
    
    let timeTemplate = () => "";
    if (starts && ends) {
      timeTemplate = _.template(i18n.t('common:text.maintenance_view_message_out_of_order_start_end'));
    } else if (ends) {
      timeTemplate = _.template(i18n.t('common:text.maintenance_view_message_out_of_order_end'));
    }
    return timeTemplate({ startTime, startDate, endTime, endDate });
  }

  render() {
    return (
      <div style={styles.center}>
        <Box>
          <Typography variant="h4">
            {i18n.t('common:text.maintenance_view_title')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            {i18n.t('common:text.maintenance_view_subtitle')}
          </Typography>
        </Box>
        <p>
          <br />
          <img src="/images/logo.png" alt=""/>
          <br />
          <br />
        </p>
        <p>
          {i18n.t('common:text.maintenance_view_message')}{" "}{this.outOfOrderMessage()}
        </p>

        {debug(this.props)}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceView);
