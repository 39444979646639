import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { red } from '@material-ui/core/colors';

import { Radio, RadioGroup, FormControlLabel, Grid, Typography } from "@material-ui/core"

import * as actionCreators from "../../actions/newElectricityContract";
import { i18n } from "../../config";
import { LoadingAnimation } from '../LoadingAnimation';
import RichContent from "../RichContent";
import Settings from "../../settings";

function mapStateToProps(state) {
  return {
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class ElectricityProductSelect extends React.Component {

  fetchAvailableProducts = async () => {
    const { power, power2, power3, power4, power5, power6, accessTariff, selfcons, cups } = this.props;
    const max_power = Math.max(power, power2, power3, power4, power5, power6);
    console.log({ max_power, accessTariff, selfcons, cups });
    if (max_power) {
      this.props.fetchAvailableProducts(max_power, accessTariff, selfcons, cups);
    }
  };

  componentDidMount() {
    this.fetchAvailableProducts();
  }

  componentDidUpdate(prevProps) {
    if ( this.props.accessTariff.id !== null) {
      if (this.props.power !== prevProps.power || this.props.power2 !== prevProps.power2 || this.props.accessTariff !== prevProps.accessTariff) {
        this.fetchAvailableProducts();
      }
    }
  }

  handleChange = (event, value) => {
    const selected = this.props.availableProducts.filter((t) => t.id === Number(value));
    const product = selected[0];
    this.props.selectProduct(product);
    if (typeof this.props.onChange === "function") {
      this.props.onChange(product);
    }
  };

  renderAvailableProducts = () => {
    let products = [];
    if (!_.isEmpty(this.props.availableProducts)) {
      products = this.props.availableProducts.map((t) => {
        return (
          <Grid item xs={12} md={6} key={t.id}>
            <FormControlLabel 
              key={t.id} 
              value={t.id} 
              control={<Radio />} 
              label={<Typography variant={"h6"} component={"span"} style={{ verticalAlign: 'middle'}}>{t.name}</Typography>} 
              className="product-col"
            />
            <div className="description">
              <RichContent content={t.ov_description || ""} />
            </div>
            {t.ov_link && (
              <p>
                <a target="_blank" rel="noreferrer" href={t.ov_link}>
                  + info
                </a>
              </p>
            )}
          </Grid>
        );
      });
    }
    return products;
  };

  render() {
    const { selectedProduct } = this.props;
    const noAvailProductsTemplate = _.template(
      i18n.t('common:text.contractation_no_available_tariffs')
    );
    return this.props.isFetching ? (
      <LoadingAnimation />
    ) : (
      <div>
        <RadioGroup
          name="product"
          defaultValue={selectedProduct ? selectedProduct.id : ""}
          onChange={this.handleChange}
          className="products-container"
          value={selectedProduct ? selectedProduct.id : ""}
        >
          <Grid container spacing={3}>
            {this.renderAvailableProducts()}
          </Grid>
        </RadioGroup>
        {!!this.props.power &&
          !!this.props.accessTariff &&
          _.isEmpty(this.props.availableProducts) && (
            <div style={{ color: red[500] }}>
              <RichContent
                content={noAvailProductsTemplate({
                  phone: Settings.organization.phone_crm,
                  phoneLink: Settings.organization.phone_crm.replace(/\s/g, ''),
                })}
              />
            </div>
          )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElectricityProductSelect);

ElectricityProductSelect.propTypes = {
  accessTariff: PropTypes.object,
  power: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selfConsumption: PropTypes.bool,
  cups: PropTypes.string,
  availableProducts: PropTypes.array,
  selectedProduct: PropTypes.object,
  isFetching: PropTypes.bool,
};
