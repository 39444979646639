import React, { useState, useEffect } from "react";
import _ from "lodash";
import { locale_code } from "../../constants/i18n";
import { availableLanguages, i18n } from "../../config";

import { FormControl, Select, MenuItem, InputLabel, FormHelperText } from "@material-ui/core"

const LangSelectField = ({
  input: { onChange, value },
  initialValue,
  label,
  readOnly,
  meta,
  ...rest
}) => {

  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(locale_code.replace("-", "_"));

  const fetchAvailableItems = () => {
    setAvailableItems(availableLanguages());

    let defaultSelectedItem;
    if (value) {
      defaultSelectedItem = value;
      setSelectedItem(defaultSelectedItem);
    } else {
      defaultSelectedItem = selectedItem;
    }
    onChange(defaultSelectedItem);
  };

  const handleChange = (event, index, value) => {
    setSelectedItem(value);
    onChange(value);
  };

  useEffect(() => {
    fetchAvailableItems();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <FormControl style={{ width: "300px" }}>
        <InputLabel shrink htmlFor="">
        {(label || i18n.t('common:text.langselect_language'))}
        </InputLabel>
        <Select
          value={selectedItem}
          onChange={handleChange}
          autoWidth={true}
          disabled={readOnly}
          {...rest}
          error={!!(meta.touched)}
        >
          {(!_.isEmpty(availableItems) &&
          availableItems.map((c) => {
            return (
              <MenuItem
                key={c.localeCode}
                value={c.localeCode}
              >{c.displayName}</MenuItem>
            );
          })) ||
          []}
        </Select>
      </FormControl>
      {
        !!(meta.touched) &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </div>
  );
};

export default LangSelectField;
