import {
  SUBMIT_ABOUT_HOME_GAS_DATA,
  SUBMIT_ABOUT_YOU_GAS_DATA,
  SUBMIT_PAYMENT_GAS_DATA,
  SET_CUPS,
  SET_INCLUDE_GAS,
  SET_GAS_ACCESS_TARIFF,
  SET_CHANGE_OWNER,
  SET_GAS_INVOICE,
  SET_GAS_COMER_ORIGIN,
  SUBMIT_ABOUT_CONTRACT_GAS_DATA,
  SUBMIT_CONFIRM_GAS_DATA,
  STORE_SIPS_GAS_DATA,
  SELECT_GAS_PRODUCT,
  FETCH_AVAILABLE_GAS_PRODUCTS_REQUEST,
  RECEIVE_AVAILABLE_GAS_PRODUCTS,
  NEW_GAS_CONTRACT_CONFIRM_REQUEST,
  NEW_GAS_CONTRACT_CONFIRM_RECEIVE,
  FETCH_GAS_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_GAS_CONTRACTATION_CONFIG,
} from "../constants";
import { createReducer } from "../utils/misc";

const initialState = {
  leadsConsent: null,
  cups: null,
  address: {},
  habitual: true,
  cnae: null,
  invoice: null,
  comerOrigin: null,
  
  vat: null,
  company: false,
  name: null,
  surName1: null,
  surName2: null,
  cif: null,
  changeOwner: false,
  vatRepresentante: null,
  nameRepresentante: null,
  surName1Representante: null,
  surName2Representante: null,
  sameInvoiceAddress: true,
  invoiceAddress: {},
  commsLanguage: null,
  email: null,
  mobile: null,

  sipsData: false,
  includeGas: false,
  accessTariff: null,

  availableProducts: [],
  selectedProduct: null,

  iban: null,
  isOwner: false,

  promotionalCode: null,
  acceptOVConditions: false,
  acceptGdpr: false,

  confirmResponse: null,
  isConfirming: false,
};

export default createReducer(initialState, {
  [SUBMIT_ABOUT_HOME_GAS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      leadsConsent: payload.leadsConsent,
      cups: payload.cups,
      address: payload.address,
      habitual: payload.habitual,
      cnae: payload.cnae,
      invoice: payload.invoice,
      comerOrigin: payload.comerOrigin,
    }),
  [SUBMIT_ABOUT_YOU_GAS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      vat: payload.vat,
      company: payload.company,
      name: payload.name,
      surName1: payload.surName1,
      surName2: payload.surName2,
      changeOwner: payload.changeOwner,
      vatRepresentante: payload.vatRepresentante,
      nameRepresentante: payload.nameRepresentante,
      surName1Representante: payload.surName1Representante,
      surName2Representante: payload.surName2Representante,
      sameInvoiceAddress: payload.sameInvoiceAddress,
      invoiceAddress: payload.invoiceAddress,
      commsLanguage: payload.commsLanguage,
      email: payload.email,
      mobile: payload.mobile,
      nifFaceup: payload.nifFaceup,
    }),
  [SET_CUPS]: (state, payload) =>
    Object.assign({}, state, {
      cups: payload.cups,
    }),
  // Used in dual
  [SET_INCLUDE_GAS]: (state, payload) =>
    Object.assign({}, state, {
      includeGas: payload.includeGas,
    }),
  [SET_GAS_ACCESS_TARIFF]: (state, payload) =>
    Object.assign({}, state, {
      accessTariff: payload.accessTariff,
    }),
  // Used in dual
  [SET_CHANGE_OWNER]: (state, payload) =>
    Object.assign({}, state, {
      changeOwner: payload.changeOwner,
    }),
  // Used in dual
  [SET_GAS_INVOICE]: (state, payload) =>
  Object.assign({}, state, {
    invoice: payload.invoice,
  }),
  // Used in dual
  [SET_GAS_COMER_ORIGIN]: (state, payload) =>
    Object.assign({}, state, {
      comerOrigin: payload.comerOrigin,
    }),
  [SUBMIT_ABOUT_CONTRACT_GAS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      selectedProduct: payload.selectedProduct,
      // For dual contractation
      /*
      includeGas: payload.includeGas,
      comerOrigin: payload.comerOrigin,
      invoice: payload.invoice,
      changeOwner: payload.changeOwner,
      */
    }),
  [SUBMIT_PAYMENT_GAS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      iban: payload.iban,
      isOwner: payload.isOwner,
    }),
  [SUBMIT_CONFIRM_GAS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      promotionalCode: payload.promotionalCode,
      acceptOVConditions: payload.acceptOVConditions,
      acceptGdpr: payload.acceptGdpr,
    }),
  [SELECT_GAS_PRODUCT]: (state, payload) =>
    Object.assign({}, state, {
      selectedProduct: payload.product,
    }),
  [FETCH_AVAILABLE_GAS_PRODUCTS_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_AVAILABLE_GAS_PRODUCTS]: (state, payload) =>
    Object.assign({}, state, {
      availableProducts: payload.availableProducts,
      isFetching: false,
    }),
  [NEW_GAS_CONTRACT_CONFIRM_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      confirmResponse: null,
      isConfirming: true,
    }),
  [NEW_GAS_CONTRACT_CONFIRM_RECEIVE]: (state, payload) =>
    Object.assign({}, state, {
      confirmResponse: payload.confirmResponse,
      isConfirming: false,
    }),
  [FETCH_GAS_CONTRACTATION_CONFIG_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_GAS_CONTRACTATION_CONFIG]: (state, payload) =>
    Object.assign({}, state, {
      contractationConfig: payload.contractationConfig,
      ...payload.contractationConfig,
      isFetching: false,
    }),
  [STORE_SIPS_GAS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      accessTariff: payload.sipsData.access_tariff && payload.sipsData.access_tariff.id,
      address: payload.sipsData.address,
      sipsData: !!payload.sipsData.access_tariff,
      // To avoid data loss
      leadsConsent: payload.sipsData.leadsConsent,
      cups: payload.sipsData.cups,
      habitual: payload.sipsData.habitual,
      cnae: payload.sipsData.cnae,
      // To avoid data loss on dual contractation
      includeGas: payload.sipsData.includeGas,
      changeOwner: payload.sipsData.changeOwner,
    }),
});
