import _ from "lodash";
import {
    RECEIVE_INVOICES,
    FETCH_INVOICES_REQUEST,
    EXPORT_INVOICE_REQUEST,
    EXPORT_INVOICE_DONE,
    MESSAGE_INVOICE
} from '../constants';
import { createReducer } from '../utils/misc';
import Settings from "../settings";

const initialState = {
    data: null,
    isFetching: false,
    loaded: false,
    download_status: {},
    invoice_function: null,
    page_items: _.get(Settings, "invoices.itemsPerPage", 100),
};

export default createReducer(initialState, {
    [RECEIVE_INVOICES]: (state, payload) =>
        Object.assign({}, state, {
            items: payload.response.items,
            total_items: payload.response.n_items,
            offset_items: payload.response.offset,
            page_items: payload.response.limit,
            isFetching: false,
            loaded: true,
            message_text: payload.message,
        }),
    [FETCH_INVOICES_REQUEST]: (state, payload) =>
        Object.assign({}, state, {
            isFetching: true,
            loaded: false,
            message_text: payload.message,
        }),
    [EXPORT_INVOICE_REQUEST]: (state, payload) => {
        const download_status = Object.assign({}, state.download_status);
        download_status[payload.invoice] = true;
        return Object.assign({}, state, {
            download_status,
            message_text: payload.message
        });
    },
    [EXPORT_INVOICE_DONE]: (state, payload) => {
        const download_status = Object.assign({}, state.download_status);
        download_status[payload.invoice] = false;
        return Object.assign({}, state, {
            download_status
        });
    },
    [MESSAGE_INVOICE]: (state, payload) => {
      return Object.assign({}, state, {
            message_text: payload.message,
        })
    },
    ALT_INVOICE_FUNCTION: (state, payload) =>
        Object.assign({}, state, {
            invoice_function: payload
        }),
});
