import _ from "lodash";
import React from "react";
import moment from "moment";
import { LoadingAnimation } from "../LoadingAnimation";
import CUPSField, { cupsValid } from "../CUPSField";
import { EnergyConsumptionField } from "../EnergyConsumption";
import TextFieldReadOnly from "../TextFieldReadOnly";
import PowerField, { powerValid } from "../PowerField";
import EurosField from "../EurosField";
import LeadContact from "../LeadContact";
import {
  data_fetch_api_resource,
  data_create_api_resource,
  dispatchNewRoute
} from "../../utils/http_functions";
import Settings from "../../settings";
import validate from "validate.js";
import { i18n } from "../../config";
import Result from "./Result";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/price-comparator";

import { Button, MenuItem, Select, FormControl, Grid, FormHelperText, Divider,
  InputLabel, Paper, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

class PriceComparator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorText: "",
      invoiceSent: false,
      mode: "auto",
      availableComers: [],
      cups: "",
      comerOrigin: "",
      access_tariff: "",
      availableAccessTariffs: [],
      px: "",
      p1: "",
      p2: "",
      p3: "",
      startDate: null,
      endDate: null,
      power: "",
      power2: "",
      invoiceAmount: "",
      invoice: null,
      email: "",
      errors: {},
    };

    validate.validators.presence.options = {
      message: `^${i18n.t('common:text.required_field')}`,
    };

    validate.validators.comparatorCups = (value, options, key, attributes) => {
      if (value && !cupsValid(value)) {
        return i18n.t('common:text.cups_field_not_valid');
      } else {
        return null;
      }
    };

    validate.validators.comparatorPower = (value, options, key, attributes) => {
      if (!powerValid(value)) {
        return i18n.t('common:text.power_field_not_valid');
      } else {
        return null;
      }
    };

    validate.validators.comparatorMaxConsumption = (value, options, key, attributes) => {
      let valid = null;
      const { p1, p2, p3, startDate, endDate, power } = attributes;
      if ((p1 || p2 || p3) && startDate && endDate && power) {
          // 1. sum all consumptions
          const consumptionSum = +(p1 || 0) + +(p2 || 0) + +(p3 || 0);
          // 2. calculate absolute days diff
          const days = moment(endDate).diff(startDate, 'days') + 1;
          
          // Check that consumption sum is not greater than power * 24 * days
          console.log('days:', days);
          console.log('power:', power);
          console.log('consumptionSum:', consumptionSum);
          console.log(`${consumptionSum} > ${days * 24 * power} ?`);

          if (consumptionSum > (power * 24 * days)) {
            valid = '^' + i18n.t('common:text.price_comparator_consumption_not_valid');
          }
      }

      return valid;
    };

    this.validations = {
      cups: {
        presence: { allowEmpty: false },
        comparatorCups: true,
      },
      comerOrigin: {
        presence: { allowEmpty: false },
      },
      access_tariff: {
        presence: { allowEmpty: false },
      },
      px: {
        comparatorMaxConsumption: true,
      },
      p1: {
        presence: { allowEmpty: false },
      },
      startDate: {
        presence: { allowEmpty: false },
      },
      endDate: {
        presence: { allowEmpty: false },
      },
      power: {
        presence: { allowEmpty: false },
        comparatorPower: true,
      },
      invoiceAmount: {
        presence: { allowEmpty: false },
      },
    };

    this.fetchComers();
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  validate = (attrs = null, validations) => {
    let onlyValidate = {};
    const _validations = validations || this.validations;
    if (attrs) {
      // eslint-disable-next-line no-return-assign
      Object.keys(attrs).forEach((key) => (onlyValidate[key] = _validations[key]));
    } else {
      onlyValidate = Object.assign({}, _validations);
    }
    const errors = (validate(attrs || this.state, onlyValidate) || {});
    this.setState({ errors });
    return errors;
  };

  handleChangeMode = (event, value) => {
    this.setState({ mode: value, errors: {} });
  };

  fetchDataFromCUPS = async (cups) => {
    if (cups.length === 20 || cups.length === 22) {
      const result = await data_fetch_api_resource(
        null,
        `contractacio/config/cups/${cups}?old=0`
      );
      if (result && result.status === 200) {
        // Always clear previous data on state.
        const clearedState = {
          power: "",
          power_p2: "",
          access_tariff: "",
        };
        await this.setStateAsync(clearedState);
        // Populate data from CUPS
        await this.setStateAsync({
          power: result.data.power,
          power2: result.data.power_p2,
          access_tariff: _.get(result, "data.access_tariff.id", ""),
        });

        if (result.data.power) {
          this.handleChangePower(result.data.power);
        }

        if (result.data.power_p2) {
          this.handleChangePower2(result.data.power_p2);
        }
      }
    }
  };

  fetchComers = async () => {
    const result = await data_fetch_api_resource(null, "leads/comers");
    if (result && result.status === 200 && result.data) {
      this.setState({ availableComers: result.data });
    }
  };

  fetchAvailableAccessTariffs = async (power) => {
    let availableAccessTariffs = [];
    const result = await data_fetch_api_resource(null, `tariff?power=${power}&old=0`);
    if (result && result.status === 200 && result.data) {
      availableAccessTariffs = result.data;
    }
    return availableAccessTariffs;
  };

  handleChangeCUPS = (event, value) => {
    const change = { cups: value };
    change.errors = Object.assign({}, this.state.errors);
    if (change.errors.cups) {
      delete change.errors.cups;
    }
    if (event.target.errors) {
      change.errors = Object.assign({}, this.state.errors);
      change.errors.cups = [...event.target.errors];
    }
    this.setState(change);
    this.fetchDataFromCUPS(value);
  };

  handleChangeComerOrigin = (event, index, value) => {
    this.setState({ comerOrigin: event.target.value }, () =>
      this.validate({ comerOrigin: this.state.comerOrigin })
    );
  };

  handleChangePower = async (value) => {
    console.log("power = " + value);
    this.setState({ power: value });
    this.validate({ power: value });
    if (value && powerValid(value)) {
      let accessTariffs = await this.fetchAvailableAccessTariffs(value);
      console.log(accessTariffs);
      this.setState({ availableAccessTariffs: accessTariffs });
    }
  };

  handleChangePower2 = async (value) => {
    console.log("power2 = " + value);
    this.setState({ power2: value });
    this.validate({ power2: value });
  };

  handleChangeAccessTariff = async (event, index, value) => {
    const accessTariff = this.state.availableAccessTariffs.find(
      (i) => i.id === event.target.value
    );

    if (/dh|DH|3\.0A|2.0TD/gm.test(accessTariff.name)) {
      // Add P2 to validations
      this.validations["p2"] = {
        presence: { allowEmpty: false },
      };
      if (/2.0TD/gm.test(accessTariff.name)) {
        this.validations["p2"]["comparatorPower"] = true;
        this.validations["power2"] = {
          presence: { allowEmpty: false },
        };
      }
      if (/dhs|DHS|3\.0A|2.0TD/gm.test(accessTariff.name)) {
        // Add P3 to validations
        this.validations["p3"] = {
          presence: { allowEmpty: false },
        };
        if (/2.0TD/gm.test(accessTariff.name)) {
          this.validations["p3"]["comparatorPower"] = true;
        }
      } else if (this.validations.p3) {
        // Remove from validations
        delete this.validations.p3;
      }
    } else {
      if (this.validations.p2) {
        // Remove from validations
        delete this.validations.p2;
      }
      if (this.validations.p3) {
        // Remove from validations
        delete this.validations.p3;
      }
      if (this.validations.power2) {
        // Remove from validations
        delete this.validations.power2;
      }
    }
    this.setState({ access_tariff: event.target.value, p1: "", p2: "", p3: "" });
    this.validate({ access_tariff: event.target.value });
  };

  handleChangeP1 = (value) => {
    this.setState({ p1: value });
    this.validate({ p1: value });
  };

  handleChangeP2 = (value) => {
    this.setState({ p2: value });
    this.validate({ p2: value });
  };

  handleChangeP3 = (value) => {
    this.setState({ p3: value });
    this.validate({ p3: value });
  };

  handleChangeStartDate = (event, value) => {
    this.setState({ startDate: event });
  };

  handleChangeEndDate = (event, value) => {
    this.setState({ endDate: event });
  };

  handleChangeInvoiceAmount = (value) => {
    this.setState(
      {
        invoiceAmount: value,
      },
      () => this.validate({ invoiceAmount: this.state.invoiceAmount })
    );
  };

  sendFormData = async (data) => {
    this.setState({ loading: true });
    let result;
    try {
      result = await data_create_api_resource(null, "price-compare/", data);
    } catch (error) {
      this.setState({ errorText: error.message });
    } finally {
      this.setState({ loading: false });
    }
    if (
      result &&
      result.data
    ) {
      // Set saving to state to show result page
      this.setState({ saving: result.data });
    }
  };

  sendInvoiceData = async (data) => {
    this.setState({ loading: true });
    try {
      await data_create_api_resource(null, "price-compare/", {
        mode: this.state.mode,
        ...data,
      });
      // Set invoiceSent to state to show success page
      this.setState({ invoiceSent: true });
    } catch (error) {
      console.log("Invoice sending failed.");
      // TODO: Add sentry
      this.setState({ errorText: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { state } = this;
    const errors = this.validate(state);
    if (_.isEmpty(errors)) {
      console.log("Yay! No validation errors!");
      const dateFormat = "YYYY-MM-DD";
      const data = {
        mode: state.mode,
        cups: state.cups,
        comerOrigin: state.comerOrigin,
        power: state.power,
        power2: state.power2,
        access_tariff: state.access_tariff,
        p1: state.p1,
        p2: state.p2,
        p3: state.p3,
        startDate: moment(state.startDate).format(dateFormat),
        endDate: moment(state.endDate).format(dateFormat),
        invoiceAmount: state.invoiceAmount,
      };

      this.sendFormData(data);
    }
  };

  handleChangeInvoice = (invoice) => {
    this.setState({ invoice });
  };

  handleChangeEmail = (event, value) => {
    this.setState({ email: value });
  };

  handleUpload = async (data) => {
    this.sendInvoiceData(data);
  };

  renderContact = () => {
    return (
      <div className="contact-container">
        <h2>{i18n.t('common:text.price_comparator_contact_subtitle')}</h2>
        <LeadContact onSubmit={this.handleUpload} attachInvoice={true} />
      </div>
    );
  };

  handleGoBack = () => {
    this.setState({ saving: null });
  }

  handleHelpPanelClick = () => {
    dispatchNewRoute(i18n.t('common:url.calculatorHelp'));
  };

  componentDidMount() {
    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  }

  render() {
    const { props } = this;
    const {
      errors,
      loading,
      mode,
      cups,
      saving,
      errorText,
      invoiceSent,
    } = this.state;

    const availableComers = !_.isEmpty(this.state.availableComers)
      ? this.state.availableComers.map((c) => {
          return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
        })
      : [];

    const availableAccessTariffs = this.state.availableAccessTariffs
      ? this.state.availableAccessTariffs.map((c) => {
          return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
        })
      : [];

    let accessTariff;
    if (this.state.access_tariff) {
      accessTariff = this.state.availableAccessTariffs.find(
        (i) => i.id === this.state.access_tariff
      );
    }

    const modeSelector = (
      <RadioGroup
        name="mode"
        defaultValue={"auto"}
        value={this.state.mode}
        onChange={this.handleChangeMode}
        className="mode-selector"
      >
        <FormControlLabel value="auto" label={i18n.t('common:text.price_comparator_option_manual')} control={<Radio/>}/>
        <FormControlLabel
          value="manual"
          label={i18n.t('common:text.price_comparator_option_email')}
          control={<Radio/>}
        />
      </RadioGroup>
    );

    const titleTemplate = _.template(i18n.t('common:text.price_comparator_title'));

    const header = (
      <div className="header">
        <div className="image" />

        <h1>
          {titleTemplate({
            companyName: _.get(Settings, "organization.name"),
          })}
        </h1>
        <p>{i18n.t('common:text.price_comparator_intro')}</p>

        {!saving && modeSelector}
      </div>
    );

    const autoForm = (
      <div className="auto-form">
        <div>
          <Paper elevation={1} className="mode-selector-panel">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <div style={{maxWidth: 300}}>
                  <CUPSField
                    onChange={this.handleChangeCUPS}
                    value={this.state.cups}
                    style={{width: "100%"}}
                    error={!!(errors.cups) ? errors.cups : ''}
                  />
                  <div className="helper-text">{i18n.t('common:text.contractation_cups_helper')}</div>
                </div>
                <div style={{maxWidth: 300, marginTop: 10 }}>
                  <PowerField
                    label={i18n.t('common:text.price_comparator_power') + "*"}
                    value={this.state.power || ""}
                    style={{width: "100%"}}
                    decimalScale={3}
                    onValueChange={({ value: v }) => this.handleChangePower(v)}
                    error={!!(errors.power)}
                    pattern="\d*"
                  />
                  { (errors && errors.power) &&
                    <FormHelperText error={true}>
                      {errors.power}
                    </FormHelperText>
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div style={{maxWidth: 300}}>
                  <FormControl style={{width: "100%"}}>
                    <InputLabel shrink htmlFor="">
                      {i18n.t('common:text.price_comparator_company') + "*"}
                    </InputLabel>
                    <Select
                      disabled={props.disabled}
                      autoWidth={true}
                      onChange={this.handleChangeComerOrigin}
                      value={this.state.comerOrigin}
                      error={!!(errors.comerOrigin)}
                      className="select-field"
                    >
                      {availableComers}
                    </Select>
                  </FormControl>
                  { !!(errors.comerOrigin) &&
                    <FormHelperText error={true}>
                      {errors.comerOrigin}
                    </FormHelperText>
                  }
                </div>
                {this.state.access_tariff &&
                  accessTariff &&
                  /2.0TD/gm.test(accessTariff.name) && (
                    <div style={{maxWidth: 300}}>
                      <PowerField
                        label={i18n.t('common:text.price_comparator_power2') + "*"}
                        value={this.state.power2 || ""}
                        style={{width: "100%"}}
                        decimalScale={3}
                        onValueChange={({ value: v }) => this.handleChangePower2(v)}
                        error={!!(errors.power2)}
                        pattern="\d*"
                      />
                      { (errors && errors.power2) &&
                        <FormHelperText error={true}>
                          {errors.power2}
                        </FormHelperText>
                      }
                    </div>
                  )}
                <div style={{maxWidth: 300, marginTop: 20 }}>
                  <FormControl style={{width: "100%"}}>
                    <InputLabel shrink htmlFor="">
                      {i18n.t('common:text.price_comparator_access_tariff') + "*"}
                    </InputLabel>
                    <Select
                      disabled={props.disabled}
                      autoWidth={true}
                      onChange={this.handleChangeAccessTariff}
                      value={this.state.access_tariff}
                      error={!!(errors.access_tariff)}
                      className="select-field"
                    >
                      {availableAccessTariffs}
                    </Select>
                  </FormControl>
                  { !!(errors.access_tariff) &&
                    <FormHelperText error={true}>
                      {errors.access_tariff}
                    </FormHelperText>
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {this.state.access_tariff && (
                  <div style={{maxWidth: 300}}>
                    <label className="field-label">
                      {i18n.t('common:text.price_comparator_consumption')}
                    </label>
                  </div>
                )}
                {this.state.access_tariff && (
                  <div style={{maxWidth: 300}}>
                    <EnergyConsumptionField
                      label="P1"
                      className="energy-consumption-field"
                      decimalScale={0}
                      value={this.state.p1 || ""}
                      onValueChange={({ value: v }) => this.handleChangeP1(v)}
                      error={!!(errors.p1)}
                    />
                    { (errors && errors.p1) &&
                      <FormHelperText error={true}>
                        {errors.p1}
                      </FormHelperText>
                    }
                  </div>
                )}
                {this.state.access_tariff &&
                  accessTariff &&
                  /dh|DH|3\.0A|2.0TD/gm.test(accessTariff.name) && (
                    <div style={{maxWidth: 300}}>
                      <EnergyConsumptionField
                        label="P2"
                        className="energy-consumption-field"
                        decimalScale={0}
                        value={this.state.p2 || ""}
                        onValueChange={({ value: v }) => this.handleChangeP2(v)}
                        error={!!(errors.p2)}
                      />
                      { (errors && errors.p2) &&
                        <FormHelperText error={true}>
                          {errors.p2}
                        </FormHelperText>
                      }
                    </div>
                  )}
                {this.state.access_tariff &&
                  accessTariff &&
                  /dhs|DHS|3\.0A|2.0TD/gm.test(accessTariff.name) && (
                    <div style={{maxWidth: 300}}>
                      <EnergyConsumptionField
                        label="P3"
                        className="energy-consumption-field"
                        decimalScale={0}
                        value={this.state.p3 || ""}
                        onValueChange={({ value: v }) => this.handleChangeP3(v)}
                        error={!!(errors.p3)}
                      />
                      { (errors && errors.p2) &&
                        <FormHelperText error={true}>
                          {errors.p2}
                        </FormHelperText>
                      }
                    </div>
                  )}
                {(errors && errors.px) && 
                  <div style={{color: red[500], width: 300}}>
                    {errors.px}
                  </div>
                }
                {this.state.access_tariff &&
                  accessTariff &&
                  /dha|DHA/.test(accessTariff.name) && (
                    <div className="helper-text">
                      {i18n.t('common:text.price_comparator_consumption_dha_help_text')}
                    </div>
                  )}
                {this.state.access_tariff &&
                  accessTariff &&
                  /dhs|DHS/.test(accessTariff.name) && (
                    <div className="helper-text">
                      {i18n.t('common:text.price_comparator_consumption_dhs_help_text')}
                    </div>
                  )}
              </Grid>
            </Grid>
            <Divider style={{marginTop: 20 }}/>
            <TextFieldReadOnly label={i18n.t('common:text.price_comparator_invoicing_period')}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <KeyboardDatePicker
                    variant="inline"
                    format={'dd/MM/yyyy'}
                    className={"date"}
                    autoOk={true}
                    style={{ maxWidth: 300 }}
                    label={i18n.t('common:text.date_interval_start') + "*"}
                    value={this.state.startDate}
                    onChange={this.handleChangeStartDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <KeyboardDatePicker
                    variant="inline"
                    format={'dd/MM/yyyy'}
                    className={"date"}
                    autoOk={true}
                    style={{ maxWidth: 300 }}
                    label={i18n.t('common:text.date_interval_end') + "*"}
                    value={this.state.endDate}
                    onChange={this.handleChangeEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <EurosField
                    label={i18n.t('common:text.price_comparator_invoice_amount') + "*"}
                    value={this.state.invoiceAmount}
                    onValueChange={({ value: v }) => this.handleChangeInvoiceAmount(v)}
                    fullWidth={true}
                    error={!!(errors.invoiceAmount)}
                    pattern="\d*"
                  />
                  { (errors && errors.invoiceAmount) &&
                    <FormHelperText error={true}>
                      {errors.invoiceAmount}
                    </FormHelperText>
                  }
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <Button
              color={'primary'}
              variant={'contained'}
              className="primary-btn submit-btn"
              onClick={this.handleSubmit}
            >
              {i18n.t('common:text.price_comparator_submit')}
            </Button>
          </Paper>
        </div>
        {_.get(Settings, "comparator.sideContact") && (
          <HelpPanel onClick={this.handleHelpPanelClick} />
        )}
      </div>
    );

    const manualForm = (
      <Paper elevation={1} className="mode-selector-panel">
        {this.renderContact()}
      </Paper>
    );

    return (
      <div className="price-comparator-electricity">
        {loading && <LoadingAnimation />}
        {invoiceSent ? (
          <h3>{i18n.t('common:text.price_comparator_contact_sent')}</h3>
        ) : !loading && !saving ? (
          <div>
            {header}
            {mode === "auto" && autoForm}
            {mode === "manual" && manualForm}
          </div>
        ) : (
          !loading && <Result cups={cups} saving={saving} onGoBack={this.handleGoBack} />
        )}
        <div style={{ color: "#f00" }}>{errorText}</div>
      </div>
    );
  }
}

export default PriceComparator;
