import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Form, Field, FormSpy } from "react-final-form";
import {
  CircularProgress,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";
import TextField from "../form-fields/TextField";
import ContractSelectField from "../form-fields/ContractSelectField";
import CRMCategorySelectField from "../form-fields/CRMCategorySelectField";
import FileUploadField from "../form-fields/FileUploadField";
import { data_create_api_resource } from "../../utils/http_functions";
import ReclamATC from "../ReclamATC";
import Settings from "../../settings";

import { i18n } from "../../config";

const CRMForm = (props) => {
  const [caseId, setCaseId] = useState(null);
  const [autolectures, setAutolectures] = useState(false);
  const [sent, setSent] = useState(false);
  const [dialogReclamOpen, setDialogReclamOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    contractId: null,
    categoryId: null,
    subject: "",
    description: "",
    attachment: [],
  });

  const updateAutoreads = () => {
    const autoreads = _.get(Settings, "features.contactAutoReads", false);
    const should_filter_reads = props.location.search.includes("autolectures=true");
    if(autoreads) {
      if(should_filter_reads) {
        setAutolectures(true);
      } else {
        setAutolectures(false);
      }
    }
  }

  useEffect(() => {
    updateAutoreads()
    // eslint-disable-next-line
  }, [props.location.search]);

  const onSubmit = async (values) => {
    //window.alert(JSON.stringify(values, 0, 2)); This is for developers only
    try {
      const postData = {
        polissa_id: values.contractId,
        category: values.categoryId,
        subject: values.subject,
        description: values.description,
        attachment: values.attachment,
      };
      const result = await data_create_api_resource(
        null,
        "crm/cases/",
        postData
      );

      if (result.status === 200) {
        setCaseId(_.get(result, "data.id"));
        setSent(true);
      } else {
        throw new Error(result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const reclamActions = [
    <Button
      key={"close"}
      variant={"text"}
      onClick={() => setDialogReclamOpen(false)}
      color={"primary"}
    >
      {i18n.t("common:text.contact_close")}
    </Button>,
  ];
  const dialogReclam = (
    <Dialog open={dialogReclamOpen} fullWidth={true} maxWidth={"md"}>
      <DialogContent>
        <ReclamATC value={formValues.contractId} />
      </DialogContent>
      <DialogActions>{reclamActions}</DialogActions>
    </Dialog>
  );

  return (
    <div
      style={{ justifyContent: "center" }}
      className="flex-content-in-big-dimensions"
    >
      <div className="flex-content-dimensions">
        <Box mb={2}>
          <Typography variant="h4">
          { autolectures ? 
            i18n.t("common:text.contact_autolectures") 
           :
            i18n.t("common:text.contact_contact")  
          }
          </Typography>
        </Box>
        {sent ? (
          <div>
            <p>
              {i18n.t("common:text.contact_success")} <code>#{caseId}</code>
            </p>
          </div>
        ) : (
          <div>
            <Form
              onSubmit={onSubmit}
              initialValues={formValues}
              validate={(values) => {
                const errors = {};

                if (!values.contractId) {
                  errors.contractId = i18n.t("common:text.required_field");
                }

                if (!values.categoryId) {
                  errors.categoryId = i18n.t("common:text.required_field");
                }

                if (!values.subject) {
                  errors.subject = i18n.t("common:text.required_field");
                }

                if (!values.description) {
                  errors.description = i18n.t("common:text.required_field");
                }

                return Object.keys(errors).length && errors;
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={2}>
                  { autolectures ? 
                    <div>{i18n.t("common:text.contact_autolectures_sub")}</div>
                  :
                    (Settings.organization.phone_crm ? (
                      <div>
                        {i18n.t("common:text.contact_view_subtitle_with_phone")}
                        <a href={`tel:${Settings.organization.phone_crm}`}>
                          {Settings.organization.phone_crm}
                        </a>
                        .
                      </div>
                    ) : (
                      <div>{i18n.t("common:text.contact_view_subtitle")}</div>
                    ))
                  }
                  </Box>
                  <div style={{ marginBottom: "1em" }}>
                    <Field
                      name="contractId"
                      component={ContractSelectField}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={autolectures ? { display: "none" } : { marginBottom: "1em" }}>
                    <Field
                      name="categoryId"
                      component={CRMCategorySelectField}
                      fullWidth={true}
                      autolectures={autolectures}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ marginBottom: "1em" }}>
                    <label>{i18n.t("common:text.contact_subject")}</label>
                    <Field name="subject" component={TextField} type="text" />
                  </div>
                  <div style={{ marginBottom: "1em" }}>
                    <label>{i18n.t("common:text.contact_description")}</label>
                    <Field
                      name="description"
                      component={TextField}
                      type="text"
                    />
                  </div>
                  <div style={{ marginTop: 12, marginBottom: 15 }}>
                    <Field
                      name="attachment"
                      component={FileUploadField}
                      min={1}
                      max={6}
                      label={i18n.t("common:text.attachment")}
                      anotherLabel={i18n.t("common:text.attachment_add")}
                      removeLabel={i18n.t("common:text.remove")}
                    />
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                    >
                      {submitting ? <CircularProgress size={25} /> : null}
                      {i18n.t("common:text.contact_send")}
                    </Button>
                  </div>
                  <FormSpy onChange={(state) => setFormValues(state.values)} />
                </form>
              )}
            />
            <div style={{ marginTop: "1em" }}>
              {_.get(Settings, "features.reclamATCContact", false) && (
                <Button
                  size="small"
                  onClick={() => setDialogReclamOpen(true)}
                  color="primary"
                  variant="contained"
                  disabled={!formValues.contractId}
                >
                  {i18n.t("common:text.contact_create_atc")}
                </Button>
              )}
              {dialogReclam}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CRMForm;
