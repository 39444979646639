import React, { Component } from 'react';
import { resources as languages } from "../../i18n";
import { locale_code } from '../../constants/i18n';
import { i18n } from "../../config";

import { Button, Menu, MenuItem } from "@material-ui/core"
import { ExpandMore } from '@material-ui/icons';

class UILangSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            currentLang: locale_code.split('-')[0],
            element: null,
        };
        this.langs = Object.keys(languages);
    }

    handleMenuOpen = (event) => {
        this.setState({ openMenu: true });
        this.setState({ element: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ openMenu: false });
        this.setState({ element: null });
    };

    handleChangeLang = (value) => {
        i18n.changeLanguage(value);
        this.setState({ currentLang: locale_code.split('-')[0] });
        this.setState({ openMenu: false });
        this.setState({ element: null });
    };

    render() {
        const langs = this.langs.map((i) => {
          return (
            <MenuItem
              key={i}
              value={i}
              onClick={() => this.handleChangeLang(i)}
            >{i.toUpperCase()}</MenuItem>
          );
        });

        return (
            <div>
                <Button
                    variant={'text'}
                    className="ui-lang-selector"
                    onClick={this.handleMenuOpen}
                >
                    {i18n.t('common:locale.name') || ''}
                    {<ExpandMore />}
                </Button>
                <Menu
                    open={this.state.openMenu}
                    anchorEl={this.state.element}
                    onClose={this.handleClose}
                >
                    {langs}
                </Menu>
            </div>
        );
    }
}

export default UILangSelector;