import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import { i18n } from "../../config";

//import PowerSelectField from "../form-fields/PowerSelectField";
import PowerField from "../form-fields/PowerField";
import CheckboxField from "../form-fields/CheckboxField";
import FileUploadField from "../form-fields/FileUploadField";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import ElectricityProductSelectField from "../form-fields/ElectricityProductSelectField";
import FlexRow from "../FlexRow";

import { CircularProgress, Button, Grid, Box, Typography } from "@material-ui/core"

import Settings from "../../settings";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutContract = (props) => {

  const [tariffName, setTariffName] = useState("");

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    props.submitAboutContractData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleAccessTariffSelectUpdate = async (value, tariff_name) => {
    props.setAccessTariff(value);
    setTariffName(tariff_name);
    console.log(tariff_name)
  };

  const handlePowerUpdate = async (value) => {
    props.setPower(value);
  };

  const handlePower2Update = async (value) => {
    props.setPower2(value);
  };

  const handlePower3Update = async (value) => {
    props.setPower3(value);
  };

  const handlePower4Update = async (value) => {
    props.setPower4(value);
  };

  const handlePower5Update = async (value) => {
    props.setPower5(value);
  };

  const handlePower6Update = async (value) => {
    props.setPower6(value);
  };

  const sixPowers = () => {
    return (tariffName.charAt(0) === "3") 
  }

  const allPowersFull = () => {
    if (sixPowers()){
      return (props.power && props.power2 && props.power3 && 
              props.power4 && props.power5 && props.power6)
    } else {
      return (props.power && props.power2)
    }
  }

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">{i18n.t('common:text.contractation_about_contract')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          power: props.power,
          power2: props.power2,
          power3: props.power3,
          power4: props.power4,
          power5: props.power5,
          power6: props.power6,
          accessTariff: props.accessTariff,
          selectedProduct: props.selectedProduct,
          selfcons: props.selfcons,
          selfconsAttachment: props.selfconsAttachment,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.power) {
            errors.power = i18n.t('common:text.required_field');
          }

          if (!values.power2) {
            errors.power2 = i18n.t('common:text.required_field');
          }

          if (!values.accessTariff) {
            errors.accessTariff = i18n.t('common:text.required_field');
          }

          if (!values.selectedProduct) {
            errors.selectedProduct = i18n.t('common:text.required_field');
          }

          if (values.selfcons && !values.selfconsAttachment) {
            errors.selfconsAttachment = i18n.t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {!props.sipsData &&
                <Grid item xs={12} md={6}>
                  {(!props.sipsData) && (
                    <Field
                      name="accessTariff"
                      component={AccessTariffSelectField}
                      power={props.power}
                      onSelectUpdate={handleAccessTariffSelectUpdate}
                      style={{width: 300}}
                    />
                  )}
                  {!props.sipsData && props.accessTariff &&(
                    <Field
                      name="power"
                      label={i18n.t('common:text.contractation_power')}
                      component={PowerField}
                      onUpdate={handlePowerUpdate}
                    />
                  )}

                  {!props.sipsData && props.accessTariff &&(
                    <Field
                      name="power2"
                      label={i18n.t('common:text.contractation_power2')}
                      component={PowerField}
                      onUpdate={handlePower2Update}
                    />
                  )}
                  {!props.sipsData && props.accessTariff && sixPowers() && (
                    <Field
                      name="power3"
                      label={i18n.t('common:text.contractation_power3')}
                      component={PowerField}
                      onUpdate={handlePower3Update}
                    />
                  )}

                  {!props.sipsData && props.accessTariff && sixPowers() &&(
                    <Field
                      name="power4"
                      label={i18n.t('common:text.contractation_power4')}
                      component={PowerField}
                      onUpdate={handlePower4Update}
                    />
                  )}
                  {!props.sipsData && props.accessTariff && sixPowers() &&(
                    <Field
                      name="power5"
                      label={i18n.t('common:text.contractation_power5')}
                      component={PowerField}
                      onUpdate={handlePower5Update}
                    />
                  )}

                  {!props.sipsData && props.accessTariff && sixPowers() &&(
                    <Field
                      name="power6"
                      label={i18n.t('common:text.contractation_power6')}
                      component={PowerField}
                      onUpdate={handlePower6Update}
                    />
                  )}
                </Grid>
              }
              <Grid item xs={12} md={6}>
                {!props.sipsData && (
                  <Field
                    name="selfcons"
                    label={i18n.t('common:text.contractation_selfcons')}
                    component={CheckboxField}
                  />
                )}

                {values.selfcons && (
                  <FlexRow>
                    <Field
                      name="selfconsAttachment"
                      component={FileUploadField}
                      min={1}
                      max={3}
                      label={i18n.t('common:text.contractation_last_invoice')}
                      hint={i18n.t('common:text.contractation_last_invoice_helper')}
                      anotherLabel={i18n.t('common:text.contractation_last_invoice_add')}
                      removeLabel={i18n.t('common:text.remove')}
                    />
                  </FlexRow>
                )}
              </Grid>
            </Grid>
            {props.accessTariff && allPowersFull() &&(
              <Field
                name="selectedProduct"
                component={ElectricityProductSelectField}
              />
            )}
            <div style={{ marginTop: 25 }}>
              <Button
                variant={'text'}
                onClick={handleGoBack}
                style={{ marginRight: 12 }}
                disabled={submitting}
              >
                {i18n.t('common:text.contractation_previous')}
              </Button>
              <Button
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={submitting}
              >
                {submitting ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutContract);
