import React, { useState, useEffect } from "react";
import { data_fetch_api_resource } from "../../utils/http_functions";
import { i18n } from "../../config";

import { TextField } from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';

const CNAEField = ({
  input: { label, placeholder, value, readOnly, onChange, name },
  meta,
  ...rest
}) => {
  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  
  const getItem = (itemName, dataSource) => {
    return (dataSource || availableItems).filter(
      (originalItem) => itemName === originalItem.name
    )[0];
  };

  const handleUpdateInput = (event, target, value) => {
    let foundItem = getItem(target.name);

    if (!foundItem) {
      foundItem = { descripcio: target.name };
    }
    setSelectedItem(foundItem);
    onChange(foundItem);
  };

  const fetchAvailableItems = async () => {
    const response = await data_fetch_api_resource(null, "misc/cnaes/");
    if (response.status === 200) {
      const items = response.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          descripcio: `${item.name}: ${item.descripcio}`,
        };
      });

      setAvailableItems(items);

      let defaultSelectedItem;
      if (value) {
        defaultSelectedItem = getItem(value, items);
        if (!defaultSelectedItem) {
          // value not found in items, select provided value by props
          defaultSelectedItem = value;
        }
        setSelectedItem(defaultSelectedItem);
      } else if (!selectedItem) {
        defaultSelectedItem = getItem("9820", items);
        setSelectedItem(defaultSelectedItem);
      }
      onChange(defaultSelectedItem);
    }
  };

  useEffect(() => {
    fetchAvailableItems();
    // eslint-disable-next-line
  }, []);

  return (
    <Autocomplete
      options={availableItems}
      onChange={handleUpdateInput}
      getOptionLabel={(option) => option.descripcio}
      includeInputInList={true}
      autoComplete={true}
      openOnFocus={true}
      fullWidth={true}
      disabled={readOnly}
      {...rest}
      renderInput={
        (params) =>
        <TextField
          label={i18n.t('common:text.contractation_cnae')}
          placeholder={i18n.t('common:text.contractation_cnae_hint')}
          {...params}
        />
      }
    />
  );
};

export default CNAEField;
