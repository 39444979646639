import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actionCreators from "../../actions/newGasContract";
import { i18n } from "../../config";
import { LoadingAnimation } from '../LoadingAnimation';
import RichContent from "../RichContent";
import Settings from "../../settings";

import { red } from '@material-ui/core/colors';
import { RadioGroup, FormControlLabel, Radio, Grid, Typography } from '@material-ui/core';

function mapStateToProps(state) {
  return {
    ...state.newGasContract
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class GasProductSelect extends React.Component {

  fetchAvailableProducts = async () => {
    const { cups, accessTariff } = this.props;
    this.props.fetchAvailableGasProducts(cups, accessTariff);
  };

  componentDidMount() {
    this.fetchAvailableProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.cups !== prevProps.cups){
      this.fetchAvailableProducts();
    }
  }

  handleChange = (event, value) => {
    const selected = this.props.availableProducts.filter((t) => t.id === Number(value));
    const product = selected[0];
    this.props.selectGasProduct(product);
    if (typeof this.props.onChange === "function") {
      this.props.onChange(null, product);
    }
  };

  renderAvailableProducts = () => {
    let products = [];
    if (!_.isEmpty(this.props.availableProducts)) {
      products = this.props.availableProducts.map((t) => {
        return (
          <Grid item xs={12} md={6} key={t.id}>
            <FormControlLabel
              key={t.id}
              value={t.id}
              label={<Typography variant={"h6"} component={"span"} style={{ verticalAlign: 'middle'}}>{t.name}</Typography>}
              control={<Radio/>}
              className="product-col"
            />
            <div className="description">
              <RichContent content={t.ov_description || ""} />
            </div>
            {t.ov_link && (
              <p>
                <a target="_blank" rel="noreferrer" href={t.ov_link}>
                  + info
                </a>
              </p>
            )}
          </Grid>
        );
      });
    }
    return products;
  };

  render() {
    const { selectedProduct } = this.props;
    const noAvailProductsTemplate = _.template(
      i18n.t('common:text.contractation_no_available_tariffs')
    );
    return this.props.isFetching ? (
      <LoadingAnimation />
    ) : (
      <div>
        <RadioGroup
          name="product"
          defaultValue={selectedProduct ? selectedProduct.id : ""}
          onChange={this.handleChange}
          className="products-container"
          value={selectedProduct ? selectedProduct.id : ""}
        >
          <Grid container spacing={3}>
            {this.renderAvailableProducts()}
          </Grid>
        </RadioGroup>
        {_.isEmpty(this.props.availableProducts) && (
            <div style={{ color: red[500] }}>
              <RichContent
                content={noAvailProductsTemplate({
                  phone: Settings.organization.phone_crm,
                  phoneLink: Settings.organization.phone_crm.replace(/\s/g, ''),
                })}
              />
            </div>
          )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GasProductSelect);
