import _ from "lodash";
import React from "react";
import moment from "moment";
import { LoadingAnimation } from "../LoadingAnimation";
import CUPSField, { cupsValid } from "../CUPSField";
import { EnergyConsumptionField } from "../EnergyConsumption";
import EurosField from "../EurosField";
import LeadContact from "../LeadContact";
import {
  data_fetch_api_resource,
  data_create_api_resource,
  dispatchNewRoute
} from "../../utils/http_functions";
import Settings from "../../settings";
import validate from "validate.js";
import { i18n } from "../../config";
import Result from "./Result";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/gas-price-comparator";
import {
  Button, Select, FormHelperText, FormControl, InputLabel, Grid,
  MenuItem, Paper, FormControlLabel, Radio, RadioGroup
} from "@material-ui/core"

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

class GasPriceComparator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorText: "",
      invoiceSent: false,
      mode: "auto",
      availableComers: [],
      cups: "",
      comerOrigin: "",
      access_tariff: "",
      availableAccessTariffs: [],
      consumption: "",
      startDate: null,
      endDate: null,
      invoiceAmount: "",
      invoice: null,
      email: "",
      errors: {},
    };

    validate.validators.presence.options = {
      message: `^${i18n.t('common:text.required_field')}`,
    };

    validate.validators.comparatorCups = (value, options, key, attributes) => {
      if (value && !cupsValid(value)) {
        return i18n.t('common:text.cups_field_not_valid');
      } else {
        return null;
      }
    };

    this.validations = {
      cups: {
        presence: { allowEmpty: false },
        comparatorCups: true,
      },
      comerOrigin: {
        presence: { allowEmpty: false },
      },
      access_tariff: {
        presence: { allowEmpty: false },
      },
      consumption: {
        presence: { allowEmpty: false },
      },
      startDate: {
        presence: { allowEmpty: false },
      },
      endDate: {
        presence: { allowEmpty: false },
      },
      invoiceAmount: {
        presence: { allowEmpty: false },
      },
    };

    this.fetchComers();
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  validate = (attrs = null, validations) => {
    let onlyValidate = {};
    const _validations = validations || this.validations;
    if (attrs) {
      // eslint-disable-next-line no-return-assign
      Object.keys(attrs).forEach((key) => (onlyValidate[key] = _validations[key]));
    } else {
      onlyValidate = Object.assign({}, _validations);
    }
    const errors = validate(attrs || this.state, onlyValidate);
    this.setState({ errors });
    return errors;
  };

  handleChangeMode = (event, value) => {
    this.setState({ mode: value, errors: {} });
  };

  fetchDataFromCUPS = async (cups) => {
    if (cups.length === 20 || cups.length === 22) {
      const result = await data_fetch_api_resource(
        null,
        `gas/contractacio/config/cups/${cups}`,
        2
      );
      if (result && result.status === 200) {
        // Always clear previous data on state.
        const clearedState = {
          access_tariff: "",
        };
        await this.setStateAsync(clearedState);
        // Populate data from CUPS
        await this.setStateAsync({
          access_tariff: _.get(result, "data.access_tariff.id", ""),
        });
      }
    }
  };

  fetchComers = async () => {
    const result = await data_fetch_api_resource(null, "gas/leads/comers", 2);
    if (result && result.status === 200 && result.data) {
      this.setState({ availableComers: result.data });
    }
  };

  fetchAvailableAccessTariffs = async (power) => {
    let availableAccessTariffs = [];
    const result = await data_fetch_api_resource(null, "gas/tariff", 2);
    if (result && result.status === 200 && result.data) {
      availableAccessTariffs = result.data;
    }
    return availableAccessTariffs;
  };

  handleChangeCUPS = (event, value) => {
    const change = { cups: value };
    change.errors = Object.assign({}, this.state.errors);
    if (change.errors.cups) {
      delete change.errors.cups;
    }
    if (event.target.errors) {
      change.errors = Object.assign({}, this.state.errors);
      change.errors.cups = [...event.target.errors];
    }
    this.setState(change);
    this.fetchDataFromCUPS(value);
  };

  handleChangeComerOrigin = (event, index, value) => {
    this.setState({ comerOrigin: value }, () =>
      this.validate({ comerOrigin: this.state.comerOrigin })
    );
  };

  handleChangeAccessTariff = async (event, index, value) => {
    // const accessTariff = this.state.availableAccessTariffs.find(
    //   (i) => i.id === value
    // );

    this.setState({ access_tariff: value, consumption: "" });
    this.validate({ access_tariff: value });
  };

  handleChangeConsumption = (value) => {
    this.setState({ consumption: value });
    this.validate({ consumption: value });
  };

  handleChangeStartDate = (event, value) => {
    this.setState({ startDate: event });
  };

  handleChangeEndDate = (event, value) => {
    this.setState({ endDate: event });
  };

  handleChangeInvoiceAmount = (value) => {
    this.setState(
      {
        invoiceAmount: value,
      },
      () => this.validate({ invoiceAmount: this.state.invoiceAmount })
    );
  };

  sendFormData = async (data) => {
    this.setState({ loading: true });
    let result;
    try {
      result = await data_create_api_resource(null, "gas/price-compare/", data, 2);
    } catch (error) {
      this.setState({ errorText: error.message });
    } finally {
      this.setState({ loading: false });
    }
    if (
      result &&
      result.data
    ) {
      // Set saving to state to show result page
      this.setState({ saving: result.data });
    }
  };

  sendInvoiceData = async (data) => {
    this.setState({ loading: true });
    try {
      await data_create_api_resource(null, "gas/price-compare/", {
        mode: this.state.mode,
        ...data,
      }, 2);
      // Set invoiceSent to state to show success page
      this.setState({ invoiceSent: true });
    } catch (error) {
      console.log("Invoice sending failed.");
      // TODO: Add sentry
      this.setState({ errorText: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { state } = this;
    const errors = this.validate(state);
    if (_.isEmpty(errors)) {
      console.log("Yay! No validation errors!");
      const dateFormat = "YYYY-MM-DD";
      const data = {
        mode: state.mode,
        cups: state.cups,
        comerOrigin: state.comerOrigin,
        access_tariff: state.access_tariff,
        consumption: state.consumption,
        startDate: moment(state.startDate).format(dateFormat),
        endDate: moment(state.endDate).format(dateFormat),
        invoiceAmount: state.invoiceAmount,
      };

      this.sendFormData(data);
    }
  };

  handleChangeInvoice = (invoice) => {
    this.setState({ invoice });
  };

  handleChangeEmail = (event, value) => {
    this.setState({ email: value });
  };

  handleUpload = async (data) => {
    this.sendInvoiceData(data);
  };

  renderContact = () => {
    return (
      <div className="contact-container">
        <h2>{i18n.t('common:text.price_comparator_contact_subtitle')}</h2>
        <LeadContact onSubmit={this.handleUpload} attachInvoice={true} />
      </div>
    );
  };

  handleGoBack = () => {
    this.setState({ saving: null });
  }

  handleHelpPanelClick = () => {
    dispatchNewRoute(i18n.t('common:url.calculatorHelp'));
  };

  componentDidMount() {
    (async () => {
      let accessTariffs = await this.fetchAvailableAccessTariffs();
      console.log(accessTariffs);
      this.setState({ availableAccessTariffs: accessTariffs });
    })();
    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  }

  render() {

    const { props } = this;
    const {
      errors,
      loading,
      mode,
      cups,
      saving,
      errorText,
      invoiceSent,
    } = this.state;

    const availableComers = !_.isEmpty(this.state.availableComers)
      ? this.state.availableComers.map((c) => {
          return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
        })
      : [];

    const availableAccessTariffs = this.state.availableAccessTariffs
      ? this.state.availableAccessTariffs.map((c) => {
          return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
        })
      : [];

    // let accessTariff;
    // if (this.state.access_tariff) {
    //   accessTariff = this.state.availableAccessTariffs.find(
    //     (i) => i.id === this.state.access_tariff
    //   );
    // }

    const modeSelector = (
      <RadioGroup
        name="mode"
        defaultValue={"auto"}
        value={this.state.mode}
        onChange={this.handleChangeMode}
        className="mode-selector"
      >
        <FormControlLabel
          value="auto"
          label={i18n.t('common:text.price_comparator_option_manual')}
          control={<Radio />} />
        <FormControlLabel
          value="manual"
          label={i18n.t('common:text.price_comparator_option_email')}
          control={<Radio/>}
        />
      </RadioGroup>
    );

    const titleTemplate = _.template(i18n.t('common:text.gas_price_comparator_title'));

    const header = (
      <div className="header">
        <div className="image" />

        <h1>
          {titleTemplate({
            companyName: _.get(Settings, "organization.name"),
          })}
        </h1>
        <p>{i18n.t('common:text.gas_price_comparator_intro')}</p>

        {!saving && modeSelector}
      </div>
    );

    const autoForm = (
      <div className="auto-form">
        <div>
          <Paper elevation={1} className="mode-selector-panel">
            <div style={{width: 300}}>
              <CUPSField
                onChange={this.handleChangeCUPS}
                value={this.state.cups}
                style={{width: "100%"}}
                errorText={errors && errors.cups}
              />
              <div className="helper-text">{i18n.t('common:text.contractation_cups_gas_helper')}</div>
            </div>
            <div>
              <FormControl
                disabled={!this.state.zipcode}
                style={{ width: '100%', maxWidth: '300px' }}
              >
                <InputLabel shrink htmlFor="">
                  {i18n.t('common:text.price_comparator_company') + "*"}
                </InputLabel>
                <Select
                  disabled={props.disabled}
                  autoWidth={true}
                  onChange={this.handleChangeComerOrigin}
                  value={this.state.comerOrigin}
                  fullWidth={true}
                  error={!!(errors.comerOrigin)}
                  className="select-field"
                >
                  {availableComers}
                </Select>
              </FormControl>
              {!!(errors.comerOrigin) &&
                <FormHelperText error={true}>
                  {errors.comerOrigin}
                </FormHelperText>
              }
            </div>
            <div>
              <FormControl
                disabled={!this.state.zipcode}
                style={{ width: '100%', maxWidth: '300px' }}
              >
                <InputLabel shrink htmlFor="">
                  {i18n.t('common:text.price_comparator_access_tariff') + "*"}
                </InputLabel>
                <Select
                  disabled={props.disabled}
                  autoWidth={true}
                  onChange={this.handleChangeAccessTariff}
                  value={this.state.access_tariff}
                  error={!!(errors.access_tariff)}
                  fullWidth={true}
                  className="select-field"
                >
                  {availableAccessTariffs}
                </Select>
              </FormControl>
              {!!(errors.access_tariff) &&
                <FormHelperText error={true}>
                  {errors.access_tariff}
                </FormHelperText>
              }
            </div>
            {this.state.access_tariff && (
              <EnergyConsumptionField
                floatingLabelText={i18n.t('common:text.price_comparator_consumption')}
                className="energy-consumption-field"
                decimalScale={0}
                value={this.state.consumption || ""}
                onValueChange={({ value: v }) => this.handleChangeConsumption(v)}
                errorText={errors && errors.consumption}
                style={{ marginTop: "-2rem" }}
              />
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format={'dd/MM/yyyy'}
                  className={"date"}
                  margin="normal"
                  label={i18n.t('common:text.date_interval_start') + "*"}
                  maxDate={this.state.endDate}
                  value={this.state.startDate}
                  onChange={this.handleChangeStartDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format={'dd/MM/yyyy'}
                  className={"date"}
                  margin="normal"
                  label={i18n.t('common:text.date_interval_end') + "*"}
                  value={this.state.endDate}
                  minDate={this.state.startDate}
                  onChange={this.handleChangeEndDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <div>
              <EurosField
                floatingLabelText={i18n.t('common:text.price_comparator_invoice_amount') + "*"}
                value={this.state.invoiceAmount}
                onValueChange={({ value: v }) => this.handleChangeInvoiceAmount(v)}
                fullWidth={true}
                errorText={errors && errors.invoiceAmount}
                pattern="\d*"
              />
            </div>
            <Button
              color={'primary'}
              variant={'contained'}
              className="primary-btn submit-btn"
              onClick={this.handleSubmit}
            >
              {i18n.t('common:text.price_comparator_submit')}
            </Button>
          </Paper>
        </div>
        {_.get(Settings, "comparator.sideContact") && (
          <HelpPanel onClick={this.handleHelpPanelClick} />
        )}
      </div>
    );

    const manualForm = (
      <Paper elevation={1} className="mode-selector-panel">
        {this.renderContact()}
      </Paper>
    );

    return (
      <div className="price-comparator-gas">
        {loading && <LoadingAnimation />}
        {invoiceSent ? (
          <h3>{i18n.t('common:text.price_comparator_contact_sent')}</h3>
        ) : !loading && !saving ? (
          <div>
            {header}
            {mode === "auto" && autoForm}
            {mode === "manual" && manualForm}
          </div>
        ) : (
          !loading && <Result cups={cups} saving={saving} onGoBack={this.handleGoBack} />
        )}
        <div style={{ color: "#f00" }}>{errorText}</div>
      </div>
    );
  }
}

export default GasPriceComparator;
