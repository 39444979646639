import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import { Avatar, Grid, Paper, Fade, Chip, TableContainer, Typography,
    Table, TableBody, TableRow, TableCell, Box } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import MD5 from "crypto-js/md5";
import { InfoContainer } from "../InfoContainer";
import { LoadingAnimation } from '../LoadingAnimation';
import { ArrowBack, QuestionAnswer, AccountCircle, GetApp } from '@material-ui/icons';
import StatusIndicator from "./components/StatusIndicator";
import NewComment from "./components/NewComment";
import { fetchCRMCaseDetail, downloadAttachment, openCRMMessageDialog } from '../../actions/crm';
import saveAs from 'file-saver';
import marked from 'marked';
import { dispatchNewRoute } from '../../utils/http_functions';
import { i18n } from "../../config";

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  table: {
    margin: "5px 0 0 20px",
    padding: "0 20px 0 0"
  },
  tableCell: {
    "&:hover": {
      backgroundColor: "rgba(1,1,1,0.0625)",
      cursor: "pointer",
    }
  },
  downloadButton: {
    "$tableCell:hover &": {
      opacity: "1.0",
    },
    opacity: "0.25",
    marginRight: "10px",
    marginTop: "5px",
  },
  firstChip: {
    margin: '0.5vh',
  },
  answerButton: {
    margin: "5px"
  },
  statusBarPending: {
    backgroundColor: "#FCBB6D",
    color: 'white'
  },
  statusBarAccepted: {
    backgroundColor: "#4AB19D",
    color: 'white'
  },
  statusBarDeclined: {
    backgroundColor: "#EF3D59",
    color: 'white'
  }
});

const Gravatar = ({ email, alt }) => (
  <Avatar
    alt={alt}
    src={`https://www.gravatar.com/avatar/${MD5(email).toString()}?d=identicon`}
  />
);

const Attachment = ({name, date, theme}) => {
  return(
    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
      <Grid item container justifyContent="flex-start" alignItems="center">
        <Grid item>
          <GetApp size={"small"} className={theme}/>
        </Grid>
        <Grid item>
          <div>
            {name}
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" display="block" gutterBottom style={{color: "gray"}}>
          {date}
        </Typography>
      </Grid>
    </Grid>
  )
};

class CRMDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      attachmentsPerRow: 5,
      answerOpen: false, 
      errorSending: false,
      errorMessage: '',
      sendingMessage: false,
      message: '',
    };
  }

  calculateWidth = () => {
    /* 448 és una unitat arbitrària per dividir els attachments en finestres més petites
      segons la mida d'una pantalla. Exemple: 1920 / 448 = 4
    */
    this.setState({
      attachmentsPerRow: Math.ceil(window.innerWidth / 448)
    });
  }

  componentDidMount(){
    window.addEventListener("resize", this.calculateWidth);
    this.props.fetchCRMCaseDetail(this.props.match.params.id)
    .then(
      ([statusCode,]) => {
        this.setState(() => ({ isFetching: false }));
        if (statusCode === 401) {
          //this.props.openGlobalSnackbar(this.props.t('common:validation.error_fetching_data'), true);
        }
      }
    );
  }
  
  componentWillUnmount(){
    window.removeEventListener("resize", this.calculateWidth);
  }

  downloadAttachment(attachment){
    this.props.downloadAttachment(
      this.props.match.params.id, 
      attachment.id
    ).then(
      response => {
        console.log(response);
        if(response[0] === 200){
          saveAs(response[1].content, response[1].name);
        }
      }
    );
  }

  renderAttachments(){
    const { classes, caseData } = this.props;
    const attachments = _.get(caseData, 'attachments');
    return(
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {
                _.chunk(attachments, this.state.attachmentsPerRow)
                .map(
                  (slicedAttachments, idx) => (
                    <TableRow key={idx}>
                    {
                      slicedAttachments.map(
                        (cell, idx) => (
                          <TableCell key={idx} className={classes.tableCell} onClick={() => {this.downloadAttachment(cell)}}>
                            <Attachment name={cell.name} date={cell.date} theme={classes.downloadButton} />
                          </TableCell>
                        )
                      )
                    }
                    </TableRow>
                  )
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  renderDescription() {
    const { caseData } = this.props;
    const mail = _.get(caseData, 'mails[0]');
    return (
      <Paper style={{ padding: "10px 10px", margin: 20, marginBottom: 0 }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Gravatar email={_.get(mail, 'from.email')} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <h4 style={{ margin: 0, textAlign: "left" }}>{_.get(mail, 'from.name')}</h4>
            <p style={{ textAlign: "left" }}>
              {_.get(mail, 'message')}
            </p>
            <p style={{ textAlign: "left", color: "gray" }}>
              {_.get(mail, 'date')}
            </p>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderConversation() {
    const { t, caseData } = this.props;
    const mails = _.get(caseData, 'mails');
    if (!_.isElement(mails)){
      return _.drop(mails).map(
        (mail, ids) => {
          return (
            <Paper key={ids} style={{ padding: "10px 10px", marginTop: 20 }}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  {
                    _.get(mail, 'from.customer', false) 
                    ?
                    <Gravatar email={_.get(mail, 'from.email')} />
                    :
                    <AccountCircle style={{ fontSize: 45 }} />
                  }
                </Grid>
                <Grid item zeroMinWidth>
                  <h4 style={{ margin: 0, textAlign: "left" }}>
                    {
                      _.get(mail, 'from.customer', false) 
                      ?
                      _.get(mail, 'from.name')
                      :
                      <span>{t("common:administrator")}</span>
                    }
                  </h4>
                  <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: marked(_.get(mail, 'message')) }} />
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {_.get(mail, 'date')}
                  </p>
                </Grid>
              </Grid>
            </Paper>
          );
        }
      );
    } else {
      return null;
    }
  }

  renderAnswerBox() {
    const { t, classes, caseData } = this.props;
    const name = _.get(caseData, 'mails[0].from.name', 'undefined');
    const email = _.get(caseData, 'mails[0].from.email', 'undefined');

    const openAnswerButton = (
      <Button
        variant="contained"
        color="primary"
        className={classes.answerButton}
        startIcon={<QuestionAnswer />}
        onClick={() => {this.props.openCRMMessageDialog()}}
      >
        {t("common:answer")}
      </Button>
    )

    return( 
      this.props.answerOpen 
      ? 
      <NewComment name={name} email={email} identificator={this.props.match.params.id}/> 
      : 
      openAnswerButton 
    );      
  }
  getCaseType () {
    return 'solicitudes';
  }

  returnButton() {
    const { classes, t } = this.props;
    return (
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<ArrowBack />}
        onClick={() => {dispatchNewRoute(i18n.t('common:url.CRM_list'))}}
      >
        {t("common:crm_go_back")}
      </Button>
    );
  }
  
  renderTitle(){
    const { classes, caseData } = this.props;
    return (
      <div>
        {_.get(caseData,'data.title')}
        <Chip 
            label={_.get(caseData, 'data.section')} 
            variant="outlined" 
            size="small"
            color="primary"

            className={classes.firstChip}
          />
          {_.get(caseData, 'data.category') &&
            <Chip
            label={_.get(caseData, 'data.category')} 
            variant="outlined"
            size="small"
            color="secondary"
            />
          }
      </div>
    );
  }

  render() {
    if (!this.state.isFetching) {
      const { classes, t, caseData } = this.props;
      if(caseData){
        return (
          <Fade in={true}>
              <InfoContainer
                title={this.renderTitle()}
                subtitle={t("common:crm_detail_subtitle")}
                className={classes.container}
                actionButton={this.returnButton()}
              >
                <Box mt={2}>
                  <StatusIndicator status={caseData.status}/>
                </Box>
                {this.renderDescription()}
                {this.renderAttachments()}
                <Grid 
                  container 
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={1}></Grid>
                  <Grid 
                    item
                    xs={10}
                  >
                    {this.renderConversation()}
                    {this.renderAnswerBox()}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </InfoContainer>
          </Fade>
        );
      } else {
        return(
          <Fade in={true}>
              <InfoContainer
                title={"Error"}
                subtitle={t("common:crm_error_no_info")}
                className={classes.container}
                actionButton={this.returnButton()}
              >
              </InfoContainer>
          </Fade>
        );
      }
    }
    else {
      return <LoadingAnimation fullHeight/>;
    }
  }
}

const mapDispatchToProps = {
  fetchCRMCaseDetail,
  downloadAttachment,
  openCRMMessageDialog
};

const mapStateToProps = state => {
  return {
    caseData: _.get(state, 'CRMCases.case', {}),
    isSignedIn: state.auth.isAuthenticated,
    userName: state.auth.userName,
    answerOpen: _.get(state, 'CRMCases.case.answerOpen', false),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common']),
)(CRMDetail);
