import React, { useState, useEffect } from "react";
import _ from "lodash";
import { data_fetch_api_resource } from "../../utils/http_functions";
import { i18n } from "../../config";
import Settings from "../../settings";

import { MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@material-ui/core";

const CategorySelectField = ({ input: { onChange, value }, initialValue, label, readOnly, onAvailableItemsUpdate, onSelectUpdate, gas, power, meta, autolectures, ...rest }) => {

  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  const fetchAvailableItems = async () => {
    const endpoint = "crm/cases/categories/";
    const response = await data_fetch_api_resource(null, endpoint);
    if (response.status === 200) {
      const { items } = response.data;

      setAvailableItems(items);

      if (items && typeof onAvailableItemsUpdate === "function") {
        onAvailableItemsUpdate();
      }

      let defaultSelectedItem;
      if (value) {
        defaultSelectedItem = value;
        setSelectedItem(defaultSelectedItem);
        onChange(defaultSelectedItem);
      }
    }
  };

  const handleChange = (event, index, value) => {
    setSelectedItem(event.target.value);
    onChange(event.target.value);
    if (typeof onSelectUpdate === "function") {
      onSelectUpdate(event.target.value, index.props.children);
    }
  };

  const setAutolectures = () => {
    let autoreads = _.get(Settings, "features.contactAutoReads", false);
    if (autoreads){
      if (autolectures) {
        const categ = availableItems.filter((ct) => ct.id === 20)[0]; //20 is the code for autolectures @lumina
        setSelectedItem(categ)
        onChange(categ)
      }
    }
  }

  useEffect(() => {
    fetchAvailableItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAutolectures();
    // eslint-disable-next-line
  }, [autolectures, availableItems]);

  return (
    <div>
      <FormControl fullWidth={true}
        disabled={readOnly}
      >
        <InputLabel shrink htmlFor="">
          {(label || i18n.t('common:text.contact_category')) + "*"}
        </InputLabel>
        <Select
          value={selectedItem}
          onChange={handleChange}
          autoWidth={false}
          {...rest}
        >
          {(!_.isEmpty(availableItems) &&
            availableItems.map((c) => {
              return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>;
            })) ||
            []}
        </Select>
      </FormControl>
      {!!(meta.touched) &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </div>
  );
};

export default CategorySelectField;
