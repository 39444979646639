import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as PropTypes from "prop-types";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  subtitle: {
    padding: '10px',
  },
  firstChip: {
    margin: '0.5vh',
  },
  
}));

const CRMListItem = props => {
  const classes = useStyles();
  const { detailCase, handleClick } = props;

  const renderTitle = value => {
    return (
      <Grid item container spacing={0}>
        <Typography variant="h6" gutterBottom>
          {_.get(value, 'title')}
          <Chip 
            label={_.get(value, 'section')} 
            variant="outlined" 
            size="small"
            color="primary"
            className={classes.firstChip}
          />
          { _.get(value, 'category') &&
            <Chip 
              label={_.get(value, 'category')} 
              variant="outlined" 
              size="small" 
              color="secondary"
            />
          }
        </Typography>
      </Grid>
    );
  }

  const renderSubtitle = (id, status) => {
    let alertMessage;

    switch(status.stage_seq){
      case 120:
      case 180:
        alertMessage = (
          <Grid item style={{color: 'green'}}>
            #{id} {status.stage}
          </Grid>
        );
        break;
      case 140:
        alertMessage = (
          <Grid item style={{color: 'red'}}>
            #{id} {status.stage}
          </Grid>
        );
        break;
      case 130:
        alertMessage = (
          <Grid item style={{color: 'orange'}}>
            #{id} {status.stage}
          </Grid>
        );
        break;
      case 110:
      case 150:
      case 160:
      case 170:
      default:
        alertMessage = (
          <Grid item style={{color: 'blue'}}>
            #{id} {status.stage || "En proceso"}
          </Grid>
        );
        break;
      }

    return (
      <Grid container item spacing={1}>
        {alertMessage}
      </Grid>
    );
  }

  const renderField = field => {
    const value = _.get(detailCase, 'data');
    const id = _.get(detailCase, 'id');
    const status = _.get(detailCase, 'status');
    return (
      <Grid container spacing={0}>
          {
            renderTitle(value)
          }
          {
            renderSubtitle(id, status)
          }
      </Grid>
    );
  }

  return (
    <TableRow hover>
      {
        <TableCell
          key={_.get(detailCase, 'contract')}
          style={{
            cursor: 'pointer'
          }}
          onClick={handleClick}
        >
          {
            renderField(detailCase)
          }
        </TableCell>
      }
    </TableRow>
  );
}

CRMListItem.propTypes = {
  detailCase: PropTypes.object,
};

export default CRMListItem;
