import _ from "lodash";
import React from "react";

/* theme creation */
import { i18n } from "../../config";
import Settings from "../../settings";
import "../../settings/validators";
import HelpDialog from "../../components/HelpDialog";

/* Application Headers */
import Header from "../../components/Header";
import HeaderHorizontal from "../../components/HeaderHorizontal";

import { Paper } from "@material-ui/core";

const headerVariant = _.get(Settings, "header.variant", "vertical");

const App = (props) => {

  let paper = false;
  switch (props.location.pathname) {
    // Container without envelope
    case i18n.t('common:url.login'):
    case i18n.t('common:url.altHome'):
    case i18n.t('common:url.connect'):
    case i18n.t('common:url.activate'):
      paper = false;
      break;
    // Container with an envelope based on a paper
    default:
      paper = true;
  }

  return (
    <section>
      {headerVariant === "vertical" ? (
        <Header />
      ) : (
        <HeaderHorizontal />
      )}
      {paper && (
        <Paper
          className="container paper-container"
          elevation={5}
        >
          {props.children}
        </Paper>
      )}
      {!paper && props.children}
      { _.get(Settings, "helpDialog.enabled", false) && (
        <HelpDialog />
      )}
    </section>
  );
};

export { App };
