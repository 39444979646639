import axios from 'axios';

class AxiosRequestsClass {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });
    this.ongoingRequests = [];
  }

  cancelRequests = () => {
    // We cancel all pending requests to API
    for (const cancelFunc of this.ongoingRequests) {
      cancelFunc();
    }
  };

  initializeApiConfig = () => {
    const config = {
      cancelToken: new axios.CancelToken(
        cancelFunc => this.ongoingRequests.push(cancelFunc),
      ),
      // 30 seconds (30.000ms) timeout
      timeout: 30 * 1000
    };
    return config;
  };

  getApi = () => {
    return this.api;
  };

  post = (url, data={}, config={}, API_PREFIX="/v1") => {
    const requestConfig = {
      ...this.initializeApiConfig(),
      ...config
    };
    return this.api.post('/api' + API_PREFIX + url, data, requestConfig);
  };

  put = (url, data={}, config={}, API_PREFIX="/v1") => {
    const requestConfig = {
      ...this.initializeApiConfig(),
      ...config
    };
    return this.api.put('/api' + API_PREFIX + url, data, requestConfig);
  };

  get = (url, config={}, API_PREFIX="/v1") => {
    const requestConfig = {
      ...this.initializeApiConfig(),
      ...config
    };
    return this.api.get('/api' + API_PREFIX + url, requestConfig);
  }
}

const apiWrapper = new AxiosRequestsClass();
export default apiWrapper;