import React from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import FloatingSearchButton from "../FloatingSearchButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  fab: {
    marginRight: 10,
    marginLeft: 10,
  },
  actionButtons: {
    textAlign: "right",
  },
}));

const ContentHeader = (props) => {
  const classes = useStyles();
  const title = props.title;
  const withAdd = props.addButton ? props.addButton : false;
  const withRefresh = props.refreshButton ? props.refreshButton : false;

  const withButton = withAdd || withRefresh;

  const handleAdd = props.addClickMethod ? props.addClickMethod : null;
  const handleRefresh = props.refreshClickMethod
    ? props.refreshClickMethod
    : null;

  // The Refresh Button
  const refreshButton = withRefresh ? (
    <Fab
      color="primary"
      aria-label="refresh"
      onClick={handleRefresh}
      className={classes.fab}
    >
      <RefreshIcon />
    </Fab>
  ) : null;

  // The Add Button
  const addButton = withAdd ? (
    <Fab
      color="primary"
      aria-label="add"
      onClick={handleAdd}
      className={classes.fab}
    >
      <AddIcon />
    </Fab>
  ) : null;

  const handleClickSearch = () => {};

  const searchButton = props.onSearch ? (
    <FloatingSearchButton
      onClick={handleClickSearch}
      onSearch={props.onSearch}
    />
  ) : null;

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={6}>
          <Box>
            <Typography variant={'h4'}>
                {title}
            </Typography>
          </Box>
        </Grid>
        {withButton && (
          <Grid item xs={6} className={classes.actionButtons}>
            {searchButton}
            {refreshButton}
            {addButton}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export { ContentHeader };
