import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {Popover, TextField, Fab } from '@material-ui/core';
import { i18n } from "../../config";

const useStyles = makeStyles((theme) => ({
  fab: {
    marginRight: 10,
    marginLeft: 10,
  },
  popover: {
    overflowY: "hidden",
  },
}));

const FloatingSearchButton = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleTextSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.onSearch(searchText);
      setOpen(false);
    }
  };

  return (
    <Fab
      color="primary"
      aria-label="add"
      style={props.style}
      onClick={handleClick}
      className={classes.fab}
    >
      <SearchIcon />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={handleClose}
        PaperProps={{variant: 'outlined' }}
      >
        <div style={{ margin: 10 }}>
          <TextField
            label={i18n.t('common:text.floating_search_button_placeholder')}
            onChange={handleTextSearchChange}
            onKeyPress={handleKeyDown}
            value={searchText}
            autoFocus
          />
        </div>
      </Popover>
    </Fab>
  );
};

export default FloatingSearchButton;
