import React from 'react';
import { connect } from 'react-redux';

import InvoicesView from './Invoices/InvoicesView';
import InvoicesViewGas from './InvoicesViewGas';
import { features } from "../settings";
import { Tab } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';

import GasIcon from '../assets/GasIcon';
import LightIcon from '../assets/LightIcon';
import { LoadingAnimation } from './LoadingAnimation';

function mapStateToProps(state) {
    const invoices = state.invoices;
    const invoicesGas = state.invoicesGas;
    return {
        total_items: invoices.total_items,
        total_items_gas: invoicesGas.total_items,
        loading_invoices: invoices.isFetching || invoicesGas.isFetching
    };
}

class InvoicesViewTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedElectricity: true,
            selectedGas: false,
            value: "electricity",
        };
    }

    handleChange = (event, value) => {
        console.log("selected", value);
        this.setState({
            selectedElectricity: value === 'electricity' ? true : false,
            selectedGas: value === 'gas' ? true : false,
            value: value,
        });
      };

    render() {
        let tabComponent;
        let showEmptyTabs = true;
        let empty = new Set([0, undefined])

        if (!features.showEmptyContractsInvoices){
            showEmptyTabs = !(empty.has(this.props.total_items) || empty.has(this.props.total_items_gas));
        }

        if ((features.newElectricityContract && features.newGasContract) && showEmptyTabs){
            tabComponent = (
                this.props.loading_invoices ?
                    <LoadingAnimation /> :
                    <TabContext value={this.state.value}>
                        <TabList className="tabs" onChange={this.handleChange}>
                            <Tab 
                                label="Electricitat"
                                icon={<LightIcon />}
                                className={"tab tab-electricity selected-tab-" + this.state.selectedElectricity}
                                value="electricity"
                            />
                            <Tab 
                                label="Gas"
                                icon={<GasIcon />}
                                className={"tab tab-gas selected-tab-" + this.state.selectedGas}
                                value="gas"
                            />
                        </TabList>
                        <TabPanel value={"electricity"}>
                            <InvoicesView {...this.props}/>
                        </TabPanel>
                        <TabPanel value={"gas"}>
                            <InvoicesViewGas {...this.props}/>
                        </TabPanel>
                    </TabContext>
            );
        } else if(features.newGasContract && this.props.showGas) {
            tabComponent = (<InvoicesViewGas {...this.props}/>);
        } else if(features.newElectricityContract) {
            tabComponent = (<InvoicesView {...this.props}/>);
        } else {
            tabComponent = (<div>Please configure a contract type.</div>);
        }

        return(tabComponent);
    }
}

export default connect(mapStateToProps)(InvoicesViewTabs);