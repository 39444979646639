import React from "react";
import FlexRow from "../FlexRow";

import { TextField, FormHelperText } from "@material-ui/core"

const EmailField = ({
  input,
  label,
  readOnly = false,
  meta,
  ...rest
}) => {

  return (
    <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
      <TextField
        label={label}
        disabled={readOnly}
        {...input}
        {...rest}
        error={!!(meta.error) && meta.touched}
      />
      {!!(meta.error) && meta.touched &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </FlexRow>
  );
};

export default EmailField;
