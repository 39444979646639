import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";
import { i18n } from "../../config";
import { data_fetch_api_resource } from "../../utils/http_functions";
import AddressField, {
  validarReferenciaCatastral,
} from "../form-fields/AddressField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import CheckboxField from "../form-fields/CheckboxField";
import CNAEField from "../form-fields/CNAEField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import RichContent from "../RichContent";
import FlexRow from "../FlexRow";

import { CircularProgress, Button, Divider, Grid, Box, Typography } from "@material-ui/core"

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutHome = (props) => {
  const [comerOriginEnabled, setComerOriginEnabled] = useState(false);
  const [cupsInvalid, setCupsInvalid] = useState(null);

  const handleSubmit = async (values) => {
    if(values.habitual || values.cnae === null) {
      values.cnae = { 
        id: 986, 
        name: "9820", 
        descripcio: "9820: Actividades de los hogares como productores de servicios para uso propio" 
      }
    }
    props.submitAboutHomeData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const validateCUPSAvailability = async (values, storeSIPSData = false) => {
    const result = await data_fetch_api_resource(
      null,
      `contractacio/config/cups/${values.cups}`
    );
    let r = null;
    if (result && result.status === 200) {
      if (result.data.cups_availability) {
        if (!result.data.cups_availability.available) {
          r = result.data.cups_availability.reason;
          setCupsInvalid(result.data.cups_availability.reason);
        } else {
          setCupsInvalid(null)
          if (storeSIPSData) {
            props.storeSIPSData({
              ...result.data,
              leadsConsent: values.leadsConsent,
              cups: values.cups,
              habitual: values.habitual,
              cnae: values.cnae,
            });
          }
        }
      }
    } else {
      r = i18n.t('common:text.cannot_validate_async');
    }

    return (r && { cups: r }) || null;
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value && cupsValid(value)) {
      validateCUPSAvailability(values, true);
    }
  };

  const { isAuthenticated } = props;

  // Set leads consent to true by default if user is authenticated
  let leadsConsent = isAuthenticated;
  if (props.leadsConsent !== null) {
    leadsConsent = props.leadsConsent;
  }

  const leadsNoteTemplate = _.template(i18n.t('common:text.contractation_leads_note'));
  const leadsNote = (
    <RichContent
      content={leadsNoteTemplate({
        client: _.get(Settings, "organization.name", ""),
        privacyPolicy: _.get(Settings, "links.privacyPolicy", ""),
      })}
    />
  );

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">{i18n.t('common:text.contractation_about_home')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          leadsConsent: leadsConsent,
          cups: props.cups,
          address: props.address,
          habitual: props.habitual,
          cnae: props.cnae,
          invoice: props.invoice,
          comerOrigin: props.comerOrigin,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.cups) {
            errors.cups = i18n.t('common:text.required_field');
          } else if (!cupsValid(values.cups)) {
            errors.cups = i18n.t('common:text.contractation_cups_not_valid');
          }

          if (!_.get(values, "address.zipcode")) {
            _.set(errors, "address.zipcode", i18n.t('common:text.required_field'));
          } else if (values.address.zipcode.length !== 5) {
            _.set(
              errors,
              "address.zipcode",
              i18n.t('common:text.contractation_postal_code_length')
            );
          }

          // City
          if (!_.get(values, "address.city")) {
            _.set(errors, "address.city", i18n.t('common:text.required_field'));
          }

          // Street Type
          if (!_.get(values, "address.tv")) {
            _.set(errors, "address.tv", i18n.t('common:text.required_field'));
          }

          // Street Name
          if (!_.get(values, "address.nv")) {
            _.set(errors, "address.nv", i18n.t('common:text.required_field'));
          } else if (values.address.nv.length >= 256) {
            _.set(errors, "address.nv", i18n.t('common:text.address_validation_nv_length'));
          }

          // Street Number
          if (!_.get(values, "address.pnp")) {
            _.set(errors, "address.pnp", i18n.t('common:text.required_field'));
          } else if (values.address.pnp.length >= 9) {
            _.set(errors, "address.pnp", i18n.t('common:text.address_validation_pnp_length'));
          }

          // Block
          if (_.get(values, "address.bq") && values.address.bq.length >= 4) {
            _.set(errors, "address.bq", i18n.t('common:text.address_validation_bq_length'));
          }

          // Stair
          if (_.get(values, "address.es") && values.address.es.length >= 4) {
            _.set(errors, "address.es", i18n.t('common:text.address_validation_es_length'));
          }

          // Floor
          if (_.get(values, "address.pt") && values.address.pt.length >= 10) {
            _.set(errors, "address.pt", i18n.t('common:text.address_validation_pt_length'));
          }

          // Door
          if (_.get(values, "address.pu") && values.address.pu.length >= 4) {
            _.set(errors, "address.pu", i18n.t('common:text.address_validation_pu_length'));
          }

          // Referencia Catastral
          if (_.get(values, "address.ref_catastral")) {
            if (
              validarReferenciaCatastral(values.address.ref_catastral) !== 1
            ) {
              _.set(
                errors,
                "address.ref_catastral",
                i18n.t('common:text.contractation_not_valid_ref')
              );
            }
          }

          const fileErrors = validateFileUpload(values.invoice, {
            min: 1,
            minMessage: i18n.t('common:text.multiupload_validation_min'),
          });
          if (fileErrors) {
            _.set(errors, "invoice", fileErrors);
          }

          return Object.keys(errors).length
            ? errors
            : validateCUPSAvailability(values, false);
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            {_.get(Settings, "features.leads", false) && (
              <div className="helper-text">
                {isAuthenticated ? (
                  leadsNote
                ) : (
                  <Field
                    name="leadsConsent"
                    label={leadsNote}
                    component={CheckboxField}
                  />
                )}
              </div>
            )}
            <div style={{ width: 300 }}>
              <Field
                name="cups"
                label={props.gas ? i18n.t('common:text.contractation_cups_electricity') : i18n.t('common:text.contractation_cups')}
                component={CUPSField}
                style={{width: "100%"}}
                onUpdate={(value) => handleCUPSUpdate({ ...values, cups: value })}
                cupsAvailability={cupsInvalid}
              />

              <div className="helper-text">{i18n.t('common:text.contractation_cups_helper')}</div>
            </div>
            <Divider style={{marginBottom: 20, marginTop: 20}}/>
            <Field name="address" component={AddressField} />
            <Divider style={{marginBottom: 20, marginTop: 20}}/>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  name="habitual"
                  label={i18n.t('common:text.contractation_habitual_residence')}
                  component={CheckboxField}
                />
              </Grid>
              { !values.habitual &&
                <Grid item xs={12} md={6} lg={4}>
                  <div style={{width: 300}}>
                    <Field
                      name="cnae"
                      component={CNAEField}
                    />
                  </div>
                </Grid>
              }
              {comerOriginEnabled &&
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    name="comerOrigin"
                    component={ComerOriginSelectField}
                    onAvailableComersUpdate={handleAvailableComersUpdate}
                  />
                </Grid>
              }
            </Grid>
            <Divider style={{marginBottom: 20, marginTop: 20}}/>
            <FlexRow>
              <Field
                name="invoice"
                component={FileUploadField}
                min={1}
                max={3}
                label={i18n.t('common:text.contractation_last_invoice')}
                hint={i18n.t('common:text.contractation_last_invoice_helper')}
                anotherLabel={i18n.t('common:text.contractation_last_invoice_add')}
                removeLabel={i18n.t('common:text.remove')}
              />
            </FlexRow>

            <div style={{ marginTop: 25 }}>
              <Button
                variant={'contained'}
                color={'primary'}
                type="submit"
                disabled={submitting || validating || (cupsInvalid !== null)}
              >
                {
                  submitting || validating ? (
                    <CircularProgress size={25} />
                  ) : null
                }
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutHome);
