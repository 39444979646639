import { createTheme } from "@material-ui/core";
import { StyleVars } from "../../settings";

const theme = createTheme({
  typography: {
    fontFamily: [
      StyleVars.fontBase
    ].join(','),
  },
  palette: {
    primary: {
      main: StyleVars.colorPrimary,
      contrastText: StyleVars.colorPrimaryText,
    },
  }
});

export default theme;