import React from 'react';
import PropTypes from 'prop-types';

export const LogoBar = (props) => {
  return (
    <img
      src="/images/logoBar.png"
      style={{
        maxHeight: "85%",
        marginTop: "5px",
        marginLeft: "5px",
        marginRight: "5px",
        cursor: 'default',
      }}
      alt=""
    />
  );
};

LogoBar.propTypes = {
    openLinkInNewTab: PropTypes.bool,
};
