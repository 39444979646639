import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";
import FlexRow from "../FlexRow";

import VATField, { validateVAT, isCompanyVAT } from "../form-fields/VATField";
import TextField from "../form-fields/TextField";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import CheckboxField from "../form-fields/CheckboxField";
import AddressField from "../form-fields/AddressField";
import PhoneField, { validPhone } from "../form-fields/PhoneField";
import EmailField from "../form-fields/EmailField";
import LangSelectField from "../form-fields/LangSelectField";
import { i18n } from "../../config";

import { CircularProgress, Button, Grid, Box, Typography, Divider } from "@material-ui/core"

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutYou = (props) => {

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    values.company = isCompanyVAT(values.vat);
    props.submitAboutYouData(values);
    
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const isReadOnly = (field) => {
    let r = false;
    if (props.contractationConfig && props.contractationConfig[field]) {
      r = true;
    }
    return r;
  };

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">
            {i18n.t('common:text.contractation_about')}{" "}{props.company ? i18n.t('common:text.contractation_enterprise') : i18n.t('common:text.contractation_you')}
          </Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          vat: props.vat,
          company: props.company,
          name: props.name,
          surName1: props.surName1,
          surName2: props.surName2,
          cif: props.cif,
          changeOwner: props.changeOwner,
          vatRepresentante: props.vatRepresentante,
          nameRepresentante: props.nameRepresentante,
          surName1Representante: props.surName1Representante,
          surName2Representante: props.surName2Representante,
          sameInvoiceAddress: props.sameInvoiceAddress,
          invoiceAddress: props.invoiceAddress,
          commsLanguage: props.commsLanguage,
          email: props.email,
          mobile: props.mobile,
          nifFaceup: props.nifFaceup,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.vat) {
            errors.vat = i18n.t('common:text.required_field');
          } else if (!validateVAT(values.vat ? "ES" + values.vat : "")) {
            errors.vat = i18n.t('common:text.vat_field_validation_invalid');
          }

          if (!values.name) {
            errors.name = i18n.t('common:text.required_field');
          }

          if (!values.surName1) {
            errors.surName1 = i18n.t('common:text.required_field');
          }

          if (isCompanyVAT(values.vat)) {
            if (!values.vatRepresentante) {
              errors.vatRepresentante = i18n.t('common:text.required_field');
            } else if (
              !validateVAT(
                values.vatRepresentante ? "ES" + values.vatRepresentante : ""
              )
            ) {
              errors.vatRepresentante = i18n.t('common:text.vat_field_validation_invalid');
            }

            if (!values.nameRepresentante) {
              errors.nameRepresentante = i18n.t('common:text.required_field');
            }

            if (!values.surName1Representante) {
              errors.surName1Representante = i18n.t('common:text.required_field');
            }
          }

          if (!values.sameInvoiceAddress) {
            if (!values.invoiceAddress.zipcode) {
              _.set(errors, "invoiceAddress.zipcode", i18n.t('common:text.required_field'));
            } else if (values.invoiceAddress.zipcode.length !== 5) {
              _.set(
                errors,
                "invoiceAddress.zipcode",
                i18n.t('common:text.contractation_postal_code_length')
              );
            }

            // City
            if (!values.invoiceAddress.city) {
              _.set(errors, "invoiceAddress.city", i18n.t('common:text.required_field'));
            }

            // Street Type
            if (!values.invoiceAddress.tv) {
              _.set(errors, "invoiceAddress.tv", i18n.t('common:text.required_field'));
            }

            // Street Name
            if (!values.invoiceAddress.nv) {
              _.set(errors, "invoiceAddress.nv", i18n.t('common:text.required_field'));
            } else if (values.invoiceAddress.nv.length >= 256) {
              _.set(
                errors,
                "invoiceAddress.nv",
                i18n.t('common:text.address_validation_nv_length')
              );
            }

            // Street Number
            if (!values.invoiceAddress.pnp) {
              _.set(errors, "invoiceAddress.pnp", i18n.t('common:text.required_field'));
            } else if (values.invoiceAddress.pnp.length >= 9) {
              _.set(
                errors,
                "invoiceAddress.pnp",
                i18n.t('common:text.address_validation_pnp_length')
              );
            }

            // Block
            if (
              values.invoiceAddress.bq &&
              values.invoiceAddress.bq.length >= 4
            ) {
              _.set(
                errors,
                "invoiceAddress.bq",
                i18n.t('common:text.address_validation_bq_length')
              );
            }

            // Stair
            if (
              values.invoiceAddress.es &&
              values.invoiceAddress.es.length >= 4
            ) {
              _.set(
                errors,
                "invoiceAddress.es",
                i18n.t('common:text.address_validation_es_length')
              );
            }

            // Floor
            if (
              values.invoiceAddress.pt &&
              values.invoiceAddress.pt.length >= 10
            ) {
              _.set(
                errors,
                "invoiceAddress.pt",
                i18n.t('common:text.address_validation_pt_length')
              );
            }

            // Door
            if (
              values.invoiceAddress.pu &&
              values.invoiceAddress.pu.length >= 4
            ) {
              _.set(
                errors,
                "invoiceAddress.pu",
                i18n.t('common:text.address_validation_pu_length')
              );
            }
          }

          if (!values.mobile) {
            errors.mobile = i18n.t('common:text.required_field');
          } else if (!validPhone(values.mobile)) {
            errors.mobile = i18n.t('common:text.contractation_mobile_not_valid');
          }

          const nifFaceupErrors = validateFileUpload(values.nifFaceup, {
            min: 1,
            minMessage: i18n.t('common:text.multiupload_validation_min'),
          });
          if (nifFaceupErrors) {
            _.set(errors, "nifFaceup", nifFaceupErrors);
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          let company = isCompanyVAT(values.vat);
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
                    <Field
                      name="vat"
                      component={VATField}
                      label={i18n.t('common:text.contractation_nif')}
                      readOnly={isReadOnly("vat")}
                      style={{width: 300}}
                    />
                    <Field
                      name="name"
                      component={TextField}
                      style={{width: 300}}
                      label={
                        company
                          ? i18n.t('common:text.contractation_social_reason')
                          : i18n.t('common:text.contractation_name')
                      }
                      readOnly={isReadOnly("name")}
                    />
                  {!company && (
                      <Field
                        name="surName1"
                        component={TextField}
                        label={i18n.t('common:text.contractation_surname1')}
                        readOnly={isReadOnly("surName1")}
                        style={{width: 300}}
                      />
                  )}
                  {!company && (
                      <Field
                        name="surName2"
                        component={TextField}
                        label={i18n.t('common:text.contractation_surname2')}
                        readOnly={isReadOnly("surName2")}
                        style={{width: 300}}
                      />
                  )}
                    <Field
                      name="mobile"
                      component={PhoneField}
                      label={i18n.t('common:text.contractation_mobile_phone')}
                      readOnly={isReadOnly("mobile")}
                      style={{width: 300}}
                    />
                    <Field
                      name="email"
                      component={EmailField}
                      label={i18n.t('common:text.contractation_email')}
                      readOnly={isReadOnly("email")}
                      style={{width: 300}}
                    />
                  </FlexRow>
                </Grid>

                {company && (
                  <Grid item xs={12} md={6} lg={4}>
                    <div>
                      <Box>
                      <Typography variant="h6">
                          {i18n.t('common:text.contractation_legal_representative')}
                        </Typography>
                      </Box>
                      <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
                        <Field
                          name="vatRepresentante"
                          label={i18n.t('common:text.contractation_nif')}
                          component={VATField}
                          style={{width: 300}}
                        />
                        <Field
                          name="nameRepresentante"
                          component={TextField}
                          label={i18n.t('common:text.contractation_legal_representative_name')}
                          style={{width: 300}}
                        />
                        <Field
                          name="surName1Representante"
                          component={TextField}
                          label={i18n.t('common:text.contractation_legal_representative_surname1')}
                          style={{width: 300}}
                        />
                        <Field
                          name="surName2Representante"
                          component={TextField}
                          label={i18n.t('common:text.contractation_legal_representative_surname2')}
                          style={{width: 300}}
                        />
                        <Field
                          name="cif"
                          component={FileUploadField}
                          min={1}
                          max={3}
                          label={i18n.t('common:text.contractation_cif')}
                          anotherLabel={i18n.t('common:text.contractation_cif_add')}
                          removeLabel={i18n.t('common:text.remove')}
                        />
                      </FlexRow>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    name="changeOwner"
                    label={i18n.t('common:text.contractation_change_owner')}
                    component={CheckboxField}
                  />
                  <Field
                    name="sameInvoiceAddress"
                    label={i18n.t('common:text.contractation_same_address')}
                    component={CheckboxField}
                  />
                </Grid>
                {!values.sameInvoiceAddress && (
                  <Grid item xs={12}>
                    <Divider style={{marginBottom: 20}}/>
                    <Field
                      name="invoiceAddress"
                      component={AddressField}
                      label={i18n.t('common:text.contractation_invoicing_address')}
                    />
                    <Divider style={{marginTop: 20}}/>
                  </Grid>
                )}
              </Grid>
              <FlexRow style={{ marginTop: 20}}>
                <Field
                  name="nifFaceup"
                  component={FileUploadField}
                  min={1}
                  max={2}
                  label={i18n.t('common:text.contractation_dni_front')}
                  hint={i18n.t('common:text.contractation_cif_invoice_helper')}
                  anotherLabel={i18n.t('common:text.contractation_dni_back')}
                  removeLabel={i18n.t('common:text.remove')}
                />
              </FlexRow>
              {_.get(
                Settings,
                props.gas
                  ? "newDualContract.commsLanguage"
                  : "newContract.commsLanguage",
                false
              ) && (
                <FlexRow>
                  <Field name="commsLanguage" component={LangSelectField} />
                </FlexRow>
              )}

              <div style={{ marginTop: 25 }}>
                <Button
                  variant={'text'}
                  onClick={handleGoBack}
                  style={{ marginRight: 12 }}
                  disabled={submitting}
                >
                  {i18n.t('common:text.contractation_previous')}
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress size={25} /> : null}
                  {i18n.t('common:text.contractation_next')}
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutYou);
