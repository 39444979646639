/* eslint camelcase: 0 */

import { API_PREFIX, API_SPECIFICATION } from "../constants/index";
import { i18n } from "../config";
import apiWrapper from "./axiosRequestClass";
import axios from "axios";
import { history } from '../config';
import { store_token, remove_token } from "./auth";
import Settings from "../settings";
import { getUrlParams, addParamToResource } from "./misc";
import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import _ from "lodash";

const tokenConfig = (token) => ({
  headers: {
    Authorization: token, // eslint-disable-line quote-props
  },
});

export const getAPIPrefix = (version = API_SPECIFICATION) => {
  return `/api/v${version}`;
};

const addLangIfPresent = (resource) => {
  const urlParams = getUrlParams();
  if (urlParams && urlParams.lang) {
    return addParamToResource("lang", urlParams.lang, resource);
  } else {
    return resource;
  }
};

export function dispatchNewRoute(route) {
  if (route) {
    if (route.indexOf("http") === 0) {
      // It is an external route, so we use window.location.href
      window.location.href = route;
    } else {
      history.push(route);
    }
  } else {
    throw new Error("Route is empty!");
  }
}

export function navigationHistoryGoBack() {
  history.goBack();
}

/**
 * Stores token to browser storage and sets to AJAX authorization header.
 */
export function define_token(token) {
  store_token(token);
  axios.defaults.headers.common["Authorization"] = token;
  apiWrapper.getApi().defaults.headers.common['Authorization'] = token;
  const token_values = jwt_decode(token);
  Sentry.setUser(token_values);
}

/**
 * Removes token from browser storage and from AJAX authorization header.
 */
export function undefine_token() {
  remove_token();
  axios.defaults.headers.common["Authorization"] = "";
}

export function validate_token(token) {
  return axios.post(API_PREFIX + "/is_token_valid", {
    token,
  });
}

export function get_github_access() {
  window.open("/github-login", "_blank");
}

export function create_user(user, password) {
  return axios.post(API_PREFIX + "/user/", {
    user,
    password,
  });
}

export function get_token(user, password) {
  return axios.post(API_PREFIX + "/get_token", {
    user,
    password,
  });
}

export function refresh_token() {
  return axios.get(API_PREFIX + "/user/refresh");
}

export function get_token_by_client_id(clientId, secret) {
  return axios.post(API_PREFIX + "/user/client_id", {
    client_id: clientId,
    secret,
  });
}

export function openid_logout(token) {
  const { providerUrl } = Settings.oidc;
  const { logout } = Settings.oidc.endpoints;
  window.location.href = `${providerUrl}${logout.resource}?${logout.params.token.name}=${token}&${logout.params.redirectUrl.name}=${logout.params.redirectUrl.value}`;
}

export function openid_login(code, nonce) {
  return axios.post(API_PREFIX + "/user/openid", {
    code,
    nonce,
  });
}

export function ask_recover(email) {
  return axios.post(API_PREFIX + "/recover", {
    email,
  });
}

function intercept_api_response(response) {
  if (response) {
    switch (response.status) {
      //handle passwd_change request from the API
      case 419:
        dispatchNewRoute(i18n.t('common:url.changePassword'));
        return null;

      //handle enforced logouts
      case 401:
        dispatchNewRoute(i18n.t('common:url.logout'));
        return null;

      default:
        break;
    }
  }

  return response;
}

export function has_github_token(token) {
  return axios.get(API_PREFIX + "/has_github_token", tokenConfig(token));
}

export function data_about_user(token) {
  return axios.get(API_PREFIX + "/user/", tokenConfig(token));
}

export function data_fetch_api_resource(
  token,
  resource,
  version = API_SPECIFICATION
) {
  return axios
    .get(getAPIPrefix(version) + "/" + addLangIfPresent(resource))
    .then((response) => {
      return intercept_api_response(response);
    })
    .catch((error) => {
      return intercept_api_response(error.response);
    });
}

export function data_download_api_resource(
  token,
  resource,
  version = API_SPECIFICATION
) {
  return axios.get(getAPIPrefix(version) + "/" + addLangIfPresent(resource), {
    responseType: "blob",
  });
}

export const data_create_api_resource = async (
  token,
  resource,
  new_data,
  version = API_SPECIFICATION
) => {
  return axios.post(
    getAPIPrefix(version) + "/" + addLangIfPresent(resource),
    new_data
  );
};

export function data_update_api_resource(
  token,
  resource,
  new_data,
  version = API_SPECIFICATION
) {
  return axios.put(
    getAPIPrefix(version) + "/" + addLangIfPresent(resource),
    new_data
  );
}

export function data_delete_api_resource(
  token,
  resource,
  version = API_SPECIFICATION
) {
  return axios.delete(getAPIPrefix(version) + "/" + addLangIfPresent(resource));
}

export const sendElectricityLeads = async (data) => {
  return data_create_api_resource(null, "leads", data).catch((e) => {
    Sentry.captureException(e);
  });
};

export const sendGasLeads = async (data) => {
  return data_create_api_resource(null, "gas/leads", data, 2).catch((e) => {
    Sentry.captureException(e);
  });
};

export const parseResponse = (response) => {
  // The response from AXIOS is different if it's a Promise.reject or
  // Promise.resolve like
  const statusCode = _.get(response, 'status', _.get(response, 'response.status', 500));
  const data = _.get(response, 'data', _.get(response, 'response.data', {}));
  return [statusCode, data];
}

/*
* Delayed Promise creation
* */
export const pause = (duration) => new Promise(res => setTimeout(res, duration));

/*
* Method to handle retries over a called function until it resolves the Promise.
* In our cas we'll retry the call if the HTTP response is 202 (Accepted)
* */
export const insist = (fn, args=[], retries, delay = 500) => {
  return fn(...args)
    .catch(
      (error) => {
        const [statusCode,] = parseResponse(error)
        return statusCode === 202 && retries > 1 ?
          pause(delay).then(() => insist(fn, args, retries - 1, delay))
         :
          Promise.reject(error)
      }
    );
}

export class CRMService {
  cancelRequests = () => {
    return apiWrapper.cancelRequests();
  };

  constructor(config) {
    this.config = config;
  }

  getCRMCasesList = (section) => {
    let url = '/crm/cases/';
    if (section) {
      url += `?filter=('section_code','=','${section}')`;
    }
    return apiWrapper.get(url, this.config, '/v2');
  };

  getCRMCaseDetail = id => {
    return apiWrapper.get(`/crm/cases/${id}/`, this.config, '/v2');
  };

  getCRMCaseSections = (section) => {
    let url = '/crm/cases/sections/';
    if (section) {
      url += `?filter=('section_code','=','${section}')`;
    }
    return apiWrapper.get(url, this.config, '/v2');
  }

  getCRMCaseCategories = id => {
    return apiWrapper.get(`/crm/cases/sections/${id}/categories/`, this.config, '/v2');
  }

  getCRMCaseDownloadAttachment = (caseId, attachmentId) => {
    return apiWrapper.get(`/crm/cases/${caseId}/attachments/${attachmentId}/`, this.config, '/v2');
  }

  postCRMCaseMessage = (id, data) => {
    return apiWrapper.post(
      `/crm/cases/${id}/`,
      data,
      this.config,
      '/v2'
    )
  }

  postCRMCase = (data) => {
    return apiWrapper.post(
      '/crm/cases/',
      data,
      this.config,
      '/v2'
    )
  }
}