import _ from "lodash";
import {
    FETCH_INVOICES_REQUEST_GAS,
    RECEIVE_INVOICES_GAS,
    EXPORT_INVOICE_REQUEST_GAS, EXPORT_INVOICE_DONE_GAS, MESSAGE_INVOICE_GAS
} from '../constants/index'
import {
    data_fetch_api_resource,
    data_download_api_resource
} from '../utils/http_functions'
import {
    parseJSON
} from '../utils/misc'
import {
    logoutAndRedirect
} from './auth'
import saveAs from 'file-saver';
import Settings from "../settings";
import { i18n } from "../config";


export function fetchInvoicesRequest(initial) {
    const message = (initial)?null:"Actualizando lista de facturas...";

    return {
        type: FETCH_INVOICES_REQUEST_GAS,
        payload: {
            message,
        },
    };
}

export function receiveInvoices(response, initial) {
    const message = (initial)?null:"Listado de facturas actualizado";
    return {
        type: RECEIVE_INVOICES_GAS,
        payload: {
            response,
            message,
        },
    };
}

export function fetchInvoices(token, contractId = false, initial = false, offset = 0) {
    return (dispatch) => {
        dispatch(fetchInvoicesRequest(initial));


        let resource;
        if (contractId) {
            resource = `contracts/${contractId}/invoices/`;
        } else {
            resource = 'invoices/';
        }
        resource = `${resource}?offset=${offset}&limit=${_.get(Settings, "invoices.itemsPerPage", 100)}`;

        data_fetch_api_resource(token, resource, 2)
            .then(parseJSON)
            .then((response) => {
                dispatch(receiveInvoices(response, initial));
            })
            .catch((error) => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
            });
    };
}

export const messageInvoice = message => {
    return {
        type: MESSAGE_INVOICE_GAS,
        payload: {
            message
        }
    }
}

export function exportInvoiceRequest(invoice) {
    return {
        type: EXPORT_INVOICE_REQUEST_GAS,
        payload: {
            invoice,
            message: null,
        },
    };
}

export function exportInvoiceDone(invoice) {
    return {
        type: EXPORT_INVOICE_DONE_GAS,
        payload: {
            invoice
        },
    };
}

export function exportInvoice(token, invoice) {
    return (dispatch) => {
        dispatch(exportInvoiceRequest(invoice));
        data_download_api_resource(token, `invoices/${ invoice }/pdf/`, 2)
            .then(response => {
                const filename = response.headers["content-disposition"].split("=");
                dispatch(messageInvoice(i18n.t('common:text.invoices_download_ready'))); 
                saveAs(response.data, filename[1]);
            })
            .catch(error => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
            })
            .finally(() => {
                dispatch(exportInvoiceDone(invoice));
            });
    };
}
