import _ from "lodash";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as altActionCreators from '../../actions/altHome';
import * as authActionCreators from '../../actions/auth';

import { LogoBar } from "../LogoBar";
import { dispatchNewRoute} from '../../utils/http_functions';
import { i18n } from "../../config";
import {is_debug} from '../../utils/debug';
import Settings, { features } from "../../settings";
import jwt_decode from "jwt-decode";
import {
  ExpandMore,
  ExpandLess,
  Menu,
  Receipt,
  Feedback,
  Close,
  Person,
  PersonAdd,
  AddBox,
  PermIdentity,
  Message,
  PermDeviceInformation,
  VpnKey,
  EuroSymbol,
  Assignment,
} from "@material-ui/icons";
import {
  Collapse,
  ListItemText,
  ListItem,
  List,
  Drawer,
  Box,
  Typography,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import {contraction_url} from '../../constants/i18n';

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        userRoles: state.auth.userRoles,
        userGroups: state.auth.userGroups,
        userImage: state.auth.userImage,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            auth: bindActionCreators(authActionCreators, dispatch),
            alt: bindActionCreators(altActionCreators, dispatch),
        }
    }
}

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            contracts_open: false,
            invoices_open: false,
            consumption_open: false,
            profile_open: true,
        };

    }

    openProfileCollapse = () => {
        this.setState(state => ({ profile_open: !state.profile_open }));
    }

    openContractsCollapse = () => {
        this.setState(state => ({ contracts_open: !state.contracts_open }));
    }

    openInvoicesCollapse = () => {
        this.setState(state => ({ invoices_open: !state.invoices_open }));
    }

    openConsumptionCollapse = () => {
        this.setState(state => ({ consumption_open: !state.consumption_open }));
    }

    dispatchRoute(route) {
        dispatchNewRoute(route);
        this.setState({
            open: false,
        });

    }

    handleClickOutside() {
        this.setState({
            open: false,
        });
    }

    logout(e) {
        e.preventDefault();
        this.props.actions.auth.logoutAndRedirect();
        this.setState({
            open: false,
        });
    }

    openNav() {
        this.setState({
            open: true,
        });
    }

    contractFun(funktion) {
        this.props.actions.alt.altContractFunction(funktion);
        dispatchNewRoute(i18n.t('common:url.alt_contracts_detail'));
        this.setState({
            open: false,
        });

    }

    render() {

        let user = null;

        if (this.props.token) {
            const token_values = jwt_decode(this.props.token);
            user = (
              <div
                style={{ color: "#fff", paddingRight: "30px", fontSize: "14px" }}
                className={"userMenu"}
              >
                Hola,<br />
                <Box fontWeight="fontWeightBold">{token_values.name}</Box>
                {token_values.email}
              </div>
            );
        }

        return (
          <header>
            <Drawer open={this.state.open}>
              <AppBar
                position="static"
                onClick={() => this.setState({ open: false })}
                elevation={0}
              >
                <Toolbar>
                  <IconButton edge="start" color="inherit">
                    <Close />
                  </IconButton>
                  <Typography variant="h6" noWrap>
                    {"Oficina Virtual"}
                  </Typography>
                </Toolbar>
              </AppBar>
              {!this.props.isAuthenticated ? (
                <div>
                  {_.get(Settings, "altHome.sidebar", false) && (
                    <div>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openContractsCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_contract_title')}
                            </Typography>
                          </ListItemText>
                          {this.state.contracts_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.contracts_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="newCustomer"
                                onClick={() =>
                                  this.dispatchRoute(contraction_url)
                                }
                              >
                                {i18n.t('common:text.altHome_contract_create')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="contracts"
                                onClick={() =>
                                  this.dispatchRoute(i18n.t('common:url.contracts'))
                                }
                              >
                                {i18n.t('common:text.altHome_contract_view')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="download"
                                onClick={() => this.contractFun("download")}
                              >
                                {i18n.t('common:text.altHome_contract_download')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="powChange"
                                onClick={() => this.contractFun("powChange")}
                              >
                                {i18n.t('common:text.altHome_contract_power')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="ibanChange"
                                onClick={() => this.contractFun("ibanChange")}
                              >
                                {i18n.t('common:text.altHome_contract_iban')}
                              </MenuItem>
                            </div>
                            {features.notificacionAddressChange && (
                              <div>
                                <MenuItem
                                  key="notifAddrChange"
                                  onClick={() =>
                                    this.contractFun("notifAddrChange")
                                  }
                                >
                                  {i18n.t('common:text.altHome_contract_contact')}
                                </MenuItem>
                              </div>
                            )}
                            {features.noCesionSips && (
                              <div>
                                <MenuItem
                                  key="noCesionSips"
                                  onClick={() =>
                                    this.contractFun("noCesionSips")
                                  }
                                >
                                  {i18n.t('common:text.altHome_contract_sips')}
                                </MenuItem>
                              </div>
                            )}
                          </List>
                        </Collapse>
                      </List>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openInvoicesCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_invoices_title')}
                            </Typography>
                          </ListItemText>
                          {this.state.invoices_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.invoices_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="invoiceView"
                                onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                              >
                                {i18n.t('common:text.altHome_invoices_view')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="invoiceDownload"
                                onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                              >
                                {i18n.t('common:text.altHome_invoices_download')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="invoicePayOnline"
                                onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                              >
                                {i18n.t('common:text.altHome_invoices_payonline')}
                              </MenuItem>
                            </div>
                          </List>
                        </Collapse>
                      </List>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openConsumptionCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_consumption_title')}
                            </Typography>
                          </ListItemText>
                          {this.state.consumption_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.consumption_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="consumption"
                                onClick={() => this.contractFun("consumption")}
                              >
                                {i18n.t('common:text.altHome_consumption_view')}
                              </MenuItem>
                            </div>
                          </List>
                        </Collapse>
                      </List>
                    </div>
                  )}
                  {!features.oidc && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="oidc"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.login'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <Person color="action" />
                        </Box>
                        {i18n.t('common:text.login_title')}
                      </MenuItem>
                    </div>
                  )}
                  {features.signup && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="signup"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.activate'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <PersonAdd color="action" />
                        </Box>
                        {i18n.t('common:text.login_view_button_activate')}
                      </MenuItem>
                    </div>
                  )}
                  {_.get(Settings, "features.sidebarContract", false) && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="newCustomer"
                        onClick={() => this.dispatchRoute(contraction_url)}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <AddBox color="action" />
                        </Box>
                        {i18n.t('common:text.new_customer_title')}
                      </MenuItem>
                    </div>
                  )}
                  {features.unsignedContact && features.contact && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="unsigned_contact"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.unsigned_contact'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <Message color="action" />
                        </Box>
                        {i18n.t('common:text.contact_title')}
                      </MenuItem>
                    </div>
                  )}
                  {_.get(Settings, "comparator.enabled", false) && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="priceComparator"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.priceComparator'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <EuroSymbol color="action" />
                        </Box>
                        {i18n.t('common:text.menu_price_comparator_title')}
                      </MenuItem>
                    </div>
                  )}
                  {_.get(Settings, "powerCalculator.enabled", false) && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="powerCalculator"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.powerCalculator'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <EuroSymbol color="action" />
                        </Box>
                        {i18n.t('common:text.menu_power_calculator_title')}
                      </MenuItem>
                    </div>
                  )}
                  <Divider />
                </div>
              ) : (
                <div>
                  {_.get(Settings, "altHome.sidebar", false) ? (
                    <div>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openContractsCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_contract_title')}
                            </Typography>
                          </ListItemText>
                          {this.state.contracts_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.contracts_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="newCustomer"
                                onClick={() =>
                                  this.dispatchRoute(contraction_url)
                                }
                              >
                                {i18n.t('common:text.altHome_contract_create')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="contracts"
                                onClick={() =>
                                  this.dispatchRoute(i18n.t('common:url.contracts'))
                                }
                              >
                                {i18n.t('common:text.altHome_contract_view')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="download"
                                onClick={() => this.contractFun("download")}
                              >
                                {i18n.t('common:text.altHome_contract_download')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="powChange"
                                onClick={() => this.contractFun("powChange")}
                              >
                                {i18n.t('common:text.altHome_contract_power')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="ibanChange"
                                onClick={() => this.contractFun("ibanChange")}
                              >
                                {i18n.t('common:text.altHome_contract_iban')}
                              </MenuItem>
                            </div>
                            {features.notificacionAddressChange && (
                              <div>
                                <MenuItem
                                  key="notifAddrChange"
                                  onClick={() =>
                                    this.contractFun("notifAddrChange")
                                  }
                                >
                                  {i18n.t('common:text.altHome_contract_contact')}
                                </MenuItem>
                              </div>
                            )}
                            {features.noCesionSips && (
                              <div>
                                <MenuItem
                                  key="noCesionSips"
                                  onClick={() =>
                                    this.contractFun("noCesionSips")
                                  }
                                >
                                  {i18n.t('common:text.altHome_contract_sips')}
                                </MenuItem>
                              </div>
                            )}
                          </List>
                        </Collapse>
                      </List>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openInvoicesCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_invoices_title')}
                            </Typography>
                          </ListItemText>
                          {this.state.invoices_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.invoices_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="invoicesView"
                                onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                              >
                                {i18n.t('common:text.altHome_invoices_view')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="invoicesDownload"
                                onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                              >
                                {i18n.t('common:text.altHome_invoices_download')}
                              </MenuItem>
                            </div>
                            <div>
                              <MenuItem
                                key="invoicesPayOnline"
                                onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                              >
                                {i18n.t('common:text.altHome_invoices_payonline')}
                              </MenuItem>
                            </div>
                          </List>
                        </Collapse>
                      </List>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openConsumptionCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_consumption_title')}
                            </Typography>
                          </ListItemText>
                          {this.state.consumption_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.consumption_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="consumption"
                                onClick={() => this.contractFun("consumption")}
                              >
                                {i18n.t('common:text.altHome_consumption_view')}
                              </MenuItem>
                            </div>
                          </List>
                        </Collapse>
                      </List>
                    </div>
                  ) : (
                    <div>
                      <Divider />
                      <MenuItem
                        key="contracts"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.contracts'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <PermIdentity color="action" />
                        </Box>
                        {i18n.t('common:text.contracts_title')}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        key="invoices"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <Receipt color="action" />
                        </Box>
                        {i18n.t('common:text.invoices_title')}
                      </MenuItem>
                    </div>
                  )}
                  { features.contact && 
                    <div>
                      <Divider />
                      <MenuItem
                        key="contact"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.contact'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <Receipt color="action" />
                        </Box>
                        {i18n.t('common:text.contact_title')}
                      </MenuItem>
                      {_.get(Settings, "features.CRMcases", false) && 
                        <div>
                          <Divider />
                          <MenuItem
                            key={"crm_manage"}
                            onClick={() => {
                              this.dispatchRoute(i18n.t('common:url.CRM_list'));
                            }}
                          >
                            <Box component="span" mr={1} style={{ height: 24 }}>
                              <Assignment color="action" />
                            </Box>
                            {i18n.t('common:text.crm_manage_requests')}
                          </MenuItem>
                        </div>
                      }
                    </div>
                  }
                  {features.reclamATCMenu && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="reclamations"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.reclamations'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <Feedback color="action" />
                        </Box>
                        {i18n.t('common:text.reclamations_title')}
                      </MenuItem>
                    </div>
                  )}
                  {is_debug() && (
                    <div>
                      <Divider />
                      <MenuItem
                        key="about"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.about'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <PermDeviceInformation color="action" />
                        </Box>
                        {i18n.t('common:text.about_title')}
                      </MenuItem>

                      <Divider />
                    </div>
                  )}
                  {_.get(Settings, "altHome.sidebar", false) ? (
                    <div>
                      <Divider />
                      <List>
                        <ListItem onClick={this.openProfileCollapse} style={{ cursor: 'pointer'}}>
                          <ListItemText>
                            <Typography variant="h6" noWrap>
                              {i18n.t('common:text.altHome_my_profile')}
                            </Typography>
                          </ListItemText>
                          {this.state.profile_open ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={this.state.profile_open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <div>
                              <MenuItem
                                key="changePassword"
                                onClick={() =>
                                  this.dispatchRoute(i18n.t('common:url.changePassword'))
                                }
                              >
                                <Box
                                  component="span"
                                  mr={1}
                                  style={{ height: 24 }}
                                >
                                  <VpnKey color="action" />
                                </Box>
                                {i18n.t('common:text.change_password_view_title')}
                              </MenuItem>
                              <MenuItem
                                key="logout"
                                onClick={(e) => this.logout(e)}
                              >
                                <Box
                                  component="span"
                                  mr={1}
                                  style={{ height: 24 }}
                                >
                                  <Close color="action" />
                                </Box>
                                {i18n.t('common:text.logout_title')}
                              </MenuItem>
                            </div>
                          </List>
                        </Collapse>
                      </List>
                    </div>
                  ) : (
                    <div>
                      <Divider />
                      <MenuItem
                        key="changePassword"
                        onClick={() => this.dispatchRoute(i18n.t('common:url.changePassword'))}
                      >
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <VpnKey color="action" />
                        </Box>
                        {i18n.t('common:text.change_password_view_title')}
                      </MenuItem>
                      <Divider />
                      <MenuItem key="logout" onClick={(e) => this.logout(e)}>
                        <Box component="span" mr={1} style={{ height: 24 }}>
                          <Close color="action" />
                        </Box>
                        {i18n.t('common:text.logout_title')}
                      </MenuItem>
                    </div>
                  )}
                  <Divider />
                </div>
              )}
            </Drawer>

            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => this.openNav()}
                >
                  <Menu />
                </IconButton>
                <Box height={64} display="flex" flexGrow={1} flexShrink={1}>
                  {<LogoBar />}
                </Box>
                {this.props.isAuthenticated ? user : null}
              </Toolbar>
            </AppBar>
          </header>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.propTypes = {
    logoutAndRedirect: PropTypes.func,
    isAuthenticated: PropTypes.bool,
};
