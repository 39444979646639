import _ from "lodash";
import React, { Component } from 'react';
import {data_fetch_api_resource, data_download_api_resource} from "../../utils/http_functions";
import { Chart } from '../Chart';
import { Vega } from 'react-vega';
import { Handler } from 'vega-tooltip';
import { i18n } from "../../config";
import { regional_settings } from "../../constants"

import { GetApp } from "@material-ui/icons"

import {
    Button, CircularProgress, MenuItem,
    Select, FormControl, InputLabel, Grid,
} from "@material-ui/core"

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import saveAs from 'file-saver';

class CCHChart extends Component {
    constructor(props) {
        super(props);

        const startDate = new Date();
        startDate.setDate(1);

        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);

        this.state = {
            chart_spec_url: `contracts/${this.props.contractId}/cch/chart/monthly/`,
            chart_loading: true,
            chart_downloading: false,
            chart_spec_lite: null,
            chart_rechart_data: null,
            chart_type: 'monthly',
            chart_start: startDate,
            chart_end: endDate
        };
    }

    handleChange = (event, index, value) => {
        let timing = event.target.value;
        let spec_url = `contracts/${this.props.contractId}/cch/chart/${timing}/`;
        this.setState({
            chart_type: timing,
            chart_spec_url: spec_url,
            chart_rechart_data: null,
            chart_spec_lite: null
        });
        if (timing !== 'monthly') {
            const startDate = this.state.chart_start.toISOString();
            const chart_end = new Date(
              this.state.chart_start.getFullYear(),
              this.state.chart_start.getMonth() + 1,
              0,
            );
            this.setState({ chart_end });
            const endDate = chart_end.toISOString();
            spec_url = `contracts/${this.props.contractId}/cch/chart/${timing}/?start=${startDate}&end=${endDate}`;

        }
        this.fetchChartSpec(spec_url);
    };

    handleStartChange = (event, value) => {
        const startDateDate = event;
        this.setState({chart_start: startDateDate});
        const startDateISO = startDateDate.toISOString();
        const endDateISO = this.state.chart_end.toISOString();
        const spec_url = `${this.state.chart_spec_url}?start=${startDateISO}&end=${endDateISO}`;
        this.fetchChartSpec(spec_url);
    };

    handleEndChange = (event, value) => {
        const endDateDate = event;
        this.setState({chart_end: endDateDate});
        const startDateISO = this.state.chart_start.toISOString();
        const endDateISO = endDateDate.toISOString();
        const spec_url = `${this.state.chart_spec_url}?start=${startDateISO}&end=${endDateISO}`;
        this.fetchChartSpec(spec_url);
    };

    downloadCCH = () => {
        const startDate = this.state.chart_start.toISOString();
        const endDate = this.state.chart_end.toISOString();
        this.setState({chart_downloading: true});
        const url = `contracts/${this.props.contractId}/cch/download/?start=${startDate}&end=${endDate}`;
        data_download_api_resource(this.props.token, url, 1)
            .then(response => {
                const filename = response.headers["content-disposition"].split("=");
                saveAs(response.data, filename[1]);
            })
            .finally(() => {
                this.setState({chart_downloading: false});
            })
    };

    fetchChartSpec(spec_url) {
        // We will still try to request to V2 in an hourly graph, but then endpoint will not answer properly
        // hence we will fallback to V1 which should work as always.
        if (spec_url) {
            this.setState({chart_loading: true});
            data_fetch_api_resource(this.props.token, spec_url, 2)
            .then((response) => {
                if(response.status === 200 && !_.isEmpty(response.data)) { 
                    this.setState({chart_rechart_data: response.data, chart_spec_lite: null});
                } else if (response.status === 200 && _.isEmpty(response.data)) {
                    this.setState({chart_rechart_data: null, chart_spec_lite: null});
                } else {
                    data_fetch_api_resource(this.props.token, spec_url, 1)
                    .then((response) => {
                        if(response.status === 200 && !_.isEmpty(response.data)) {
                            this.setState({chart_spec_lite: response.data, chart_rechart_data: null});
                        }
                    })
                }
            }).finally(() => {
                this.setState({chart_loading: false});
        });
        }
    }

    componentDidMount() {
        this.fetchChartSpec(this.state.chart_spec_url);
        //this.setState({chart_rechart_data: ['empty'], chart_loading: false});
    }

    parseChartData(contracts) { 
        //{date: 'yyyy-MM-dd', period: String, activa: Double, reactiva: Double, exportada: Double}
        // this.state.chart_type

        let data = [];
        let periods = [];
        let components = {};
        const number_of_months = 12;

        const current_year = new Date().getUTCFullYear();
        let init_year = current_year;
        //find the earliest year
        contracts.forEach((contract) => {
            let contract_year = (new Date(contract.date)).getUTCFullYear();
            
            if(contract_year < init_year){
                init_year = contract_year;
            }
        })

        if (this.state.chart_type ===  'monthly'){
            let energy_per_year = {};
            for (let year=init_year; year <= current_year; year++) {
                energy_per_year[year] = [];
    
                // Assign the 12 months
                for (let i=0; i<number_of_months; i++) {
                    energy_per_year[year].push({'name': i, 'total':0});
                }
            }
    
            contracts.forEach((contract) => {
                const end_date = new Date(contract.date);
                
                // Extract the date
                const month = end_date.getMonth();
                const year = end_date.getUTCFullYear();
                const year_lite = year - 2000;
                
                //const energy = (parseFloat(contract.consumption));
                const activa = contract.activa;
                const exportada = contract.exportada;
                const reactiva = contract.reactiva;
                const period = contract.period;
                
                if (!periods.includes(period)) {
                    //make sure all periods are saved for the component var (used in the graph legend)
                    periods.push(period);
                }
                // Set the energy and amount
                energy_per_year[year][month]['activa'+ period]= activa;
                energy_per_year[year][month]['exportada'+ period]= exportada;
                energy_per_year[year][month]['reactiva'+ period]= reactiva;
    
                // Override title by default by shorted mont and the year
                energy_per_year[year][month]['name'] = i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
            });
            
            periods.forEach((period) => {
                components['activa' + period] = {'title': 'activa' + period}
                components['exportada' + period] = {'title': 'activa' + period}
                components['reactiva' + period] = {'title': 'activa' + period}
            });
    
            let final_energy = [];
            for (let year=init_year; year <= current_year; year++) {
                for (let month=0; month < number_of_months; month++) {
                    //Select just non-empty elements
                    energy_per_year[year][month].total = 0;
                    // Calculate totals for each month
                    _.forEach(Object.keys(energy_per_year[year][month]), (k) => {
                        if (k !== 'total' && k !== 'name') {
                            energy_per_year[year][month].total += energy_per_year[year][month][k];
                        }
                    });

                    // Format decimals
                    if( typeof energy_per_year[year][month].name === 'string' ){
                        energy_per_year[year][month].total = Number(energy_per_year[year][month].total).toFixed(0);
                        const the_energy = Object.assign({}, energy_per_year[year][month]);
                        final_energy.push(the_energy);
                    }
                }
            }
            data = final_energy;
        } else if (this.state.chart_type === 'daily' || this.state.chart_type === 'hourly') {
            let aux_data = []
            contracts.forEach((contract) => {
                let date = new Date(contract.date);
                let activa = contract.activa;
                let exportada = contract.exportada;
                let reactiva = contract.reactiva;
                let period = this.state.chart_type === 'hourly' ? "" : contract.period;

                if (!periods.includes(period)) {
                    //make sure all periods are saved for the component var (used in the graph legend)
                    periods.push(period);
                }
                

                let trobat = false;
                aux_data.forEach( el => {
                    if (el.name.getTime() === date.getTime()){
                        (el['activa' + period] === undefined) ? 
                            el['activa' + period] = activa : el['activa' + period] += activa;
                        (el['exportada' + period] === undefined) ? 
                            el['exportada' + period] = exportada : el['exportada' + period] += exportada;
                        (el['reactiva' + period] === undefined) ? 
                            el['reactiva' + period] = reactiva : el['reactiva' + period] += reactiva;
                        trobat = true;
                    }
                });
                if (!trobat) { aux_data.push({name: date, 
                                                ['activa' + period]: activa, 
                                                ['exportada' + period]: exportada, 
                                                ['reactiva' + period]: reactiva})}
            })
            periods.forEach((period) => {
                components['activa' + period] = {'title': 'activa' + period}
                components['exportada' + period] = {'title': 'activa' + period}
                components['reactiva' + period] = {'title': 'activa' + period}
            });
            aux_data.sort(function(a,b){ return (a.name - b.name); });
            aux_data.forEach( el => {
                const date = new Date(el.name);
                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getUTCFullYear();
                const year_lite = year - 2000;
                //we compose the date as a readable thing like 12'Ene'22 or 3:00-12'Ene
                if (this.state.chart_type === 'hourly') {
                    const hour = date.getHours();
                    el.name = hour + "h-" + day;
                } else {
                    el.name = day + "'" + i18n.t('common:text.the_months_lite', { returnObjects: true })[month] + "'" + year_lite;
                }

                //we add all the consumptions into the total
                _.forEach(Object.keys(el), (k) => {
                    el.total = 0;
                    if (k !== 'total' && k !== 'name') {
                        el.total += el[k];
                    }
                });

            });
            data = aux_data;
        }

        return {
            data,
            components,
        }
    }

    render() {
        const download_icon = this.state.chart_downloading?<CircularProgress size={20} thickness={3}/>:<GetApp/>;

        const unit = regional_settings.energy_unit;

        const chartData =
        !!(this.state.chart_rechart_data) ?
            this.parseChartData(this.state.chart_rechart_data) : [];

        return (
            <div>
                <FormControl>
                    <InputLabel shrink htmlFor="">
                        {i18n.t('common:text.cch_visualization')}
                    </InputLabel>
                    <Select
                        onChange={this.handleChange}
                        value={this.state.chart_type}
                    >
                        <MenuItem key="monthly" value="monthly">{i18n.t('common:text.cch_monthly')}</MenuItem>
                        <MenuItem key="daily" value="daily">{i18n.t('common:text.cch_daily')}</MenuItem>
                        <MenuItem key="hourly" value="hourly">{i18n.t('common:text.cch_hourly')}</MenuItem>
                    </Select>
                </FormControl>
                {this.state.chart_type !== 'monthly' &&
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format={'dd/MM/yyyy'}
                                    style={{marginRight: '15'}}
                                    margin="normal"
                                    label="Inicio"
                                    maxDate={this.state.chart_end}
                                    value={this.state.chart_start}
                                    onChange={this.handleStartChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format={'dd/MM/yyyy'}
                                    style={{marginRight: '15'}}
                                    margin="normal"
                                    label="Final"
                                    value={this.state.chart_end}
                                    minDate={this.state.chart_start}
                                    onChange={this.handleEndChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        {(this.state.chart_spec_lite || this.state.chart_rechart_data) && this.state.chart_type === 'hourly' &&
                          <div>
                                <Button
                                    variant={'contained'}
                                    onClick={() => this.downloadCCH()}
                                    disabled={this.state.chart_downloading}
                                    style={{marginRight: '15'}}
                                >
                                    {download_icon}
                                    {i18n.t('common:text.contract_cch_download')}
                                </Button>
                          </div>
                        }
                  </div>
                }
                {this.state.chart_loading &&
                    <div style={{textAlign: "center"}}>
                        <CircularProgress/>
                    </div>
                }
                {!this.state.chart_loading &&
                <div>
                    {this.state.chart_spec_lite &&
                    <Vega
                        spec={this.state.chart_spec_lite}
                        tooltip={new Handler().call}
                        style={{ marginTop: 15, overflow: 'auto' }}
                    />
                    }
                    {this.state.chart_rechart_data &&
                        <Chart
                            data={chartData.data}
                            components={chartData.components}
                            unit={unit}
                            animated={true}
                            stacked={false}
                            lineGraph={false}
                            compoundBars={!(this.state.chart_type === 'hourly')}
                        />
                    }
                    {!this.state.chart_spec_lite && !this.state.chart_rechart_data &&
                    <div style={{textAlign: "center"}}>{i18n.t('common:text.contract_cch_chart_no_data')}</div>
                    }
                </div>
                }
            </div>
        )
    }
}

export default CCHChart;