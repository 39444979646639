import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contractActionCreators from '../../actions/contracts';
import * as authActionCreators from '../../actions/auth';
import * as altActionCreators from '../../actions/altHome';

import { Card, CardContent, CardActions, CardHeader, Typography, Box,
        Grid, FormControl, Select, MenuItem, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Refresh, Feedback, FlashOn, GetApp,
        Visibility, Create, Receipt, AccountBalance } from '@material-ui/icons';

import { locale_code } from '../../constants/i18n';
import {SmartTable} from "../SmartTable";
import TextFieldReadOnly from "../TextFieldReadOnly";
import CCHChart from "../CCHChart";
import ContractNoCesion from "../ContractNoCesion";
import ContractElectronicNotification from "../ContractElectronicNotification";
import ChangeAddress from "../ChangeAddress";
import ChangeIban from "../ChangeIban";
import ChangePower from '../ChangePower';
import CustomizeDoubleTariff from '../CustomizeDoubleTariff';
import CustomizeHappyHours from '../CustomizeHappyHours';
import CustomizeHappyDay from '../CustomizeHappyDay';
import TextFieldEdit from "../TextFieldEdit";
import jwt_decode from 'jwt-decode';
import Settings, { features } from "../../settings";
import { i18n } from "../../config";
import ReclamATC from "../ReclamATC";
import InvoiceOnline from "./InvoiceOnline";
import _ from 'lodash';
import {Notification} from "../Notification";

const styles = {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    entry: {
      marginBottom: '20px',
    },
    root: {
        // display: 'flex',
        // flexWrap: 'wrap' ,
    },
    div: {
        overflowX: 'auto',
    },
    customWidth: {
        width: 150,
    },
    iconBefore: {
        marginRight: '10px',
    },
};

function mapStateToProps(state) {
    return {
        data: state.contracts,
        contract: state.contracts.view_contract,
        contract_function: state.contracts.contract_function,
        token: state.auth.token,
        loaded: state.contracts.loaded,
        isFetching: state.contracts.isFetching,
        error: state.contracts.error,
        errorMessage: state.contracts.data,
        downloading_contract: state.contracts.downloading_contract,
        editFormOpen: state.contracts.editFormOpen,
        message: state.contracts.message,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            contract: bindActionCreators(contractActionCreators, dispatch),
            auth: bindActionCreators(authActionCreators, dispatch),
            alt: bindActionCreators(altActionCreators, dispatch),
        }
    } 
}

class AltContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contract: this.props.contract.id,
            form_url: null,
            downloading_contract: false,
            dialogReclamOpen: false,
            dialogSipsOpen: false,
            onlyConsumption: this.props.contract_function === 'consumption' ,
        };
    }

    componentDidMount() {
        this.useContractFunction()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.contract_function !== prevProps.contract_function){
            this.useContractFunction();
        }
    }

    componentWillUnmount() {
        switch (this.props.contract_function) {
            case 'consumption':
                this.props.actions.alt.altContractFunction('consumption');
                break;
            default:
                this.props.actions.alt.altContractFunction('');
                break;
        }
    }

    useContractFunction() {
        const element = this.props.contract;
        this.setState({onlyConsumption: false})
        switch (this.props.contract_function) {
            case 'download':
                if (this.props.downloading_contract) this.exportContractFun()
                break;
            case 'powChange':
                if (element.state === 'activa' && element.atr_available && features.powerChange) this.changePower();
                break;
            case 'ibanChange':
                if (features.ibanChange && element.state === 'activa') this.changeIBAN();
                break;
            case 'notifAddrChange':
                if (features.notificacionAddressChange && element.state === 'activa') this.changeNotificationAddress();
                break;
            case 'noCesionSips':
                if (features.noCesionSips && !(element.state !== 'activa' || !element.atr_available || element.no_cession_sips === 'active')) this.setState({ dialogSipsOpen: true })
                break;
            case 'consumption':
                this.setState({onlyConsumption: true})
                break;
            default:
                break;
        }
    }

    changeDialogSipsOpen = (value) => {
        this.setState({
            dialogSipsOpen: value
        })
    };

    dialogClose = () => {
        this.props.actions.contract.editContract(false);
    };

    changePower() {
        this.props.actions.contract.editContract(true);
        this.setState({
            form_url: null,
            formContent: <ChangePower power_periods={this.props.contract.contracted_power_period}/>
        })
    }

    handleContractChange = (event) => {
        let contract = this.props.data.items.filter((c) => c.id === event.target.value)[0];
        this.props.actions.contract.viewContract(contract);
    }

    handleUpdateTariffCustomization = async () => {
        const token = this.props.token;
        this.props.actions.contract.fetchContracts(token, true, 0, null);
        this.props.actions.contract.editContract(false);
    }

    changeIBAN() {
        this.props.actions.contract.editContract(true);
        this.setState({
            form_url: null,
            formContent: <ChangeIban actual={this.props.contract.bank_account}/>
        })
    }

    onUpdatePhone = (value) => {
        this.props.actions.contract.setPhone(value);
    };

    onUpdateEmail = (value) => {
        this.props.actions.contract.setEmail(value);
    };

    changeNotificationAddress() {
        this.props.actions.contract.editContract(true);
        this.setState({
            form_url: null,
            formContent: <ChangeAddress actual={this.props.contract.notification_address}/>,
        })
    }

    exportContract = (event) => {
        event.preventDefault();
        this.props.actions.contract.exportContract(this.props.token, this.props.contract.name);
    };

    exportContractFun = () => {
        this.props.actions.contract.exportContract(this.props.token, this.props.contract.name);
    };

    JSON_to_arrays(data_servicios) {
        const header =
        [
            {
                title: i18n.t('common:text.contract_name_of_service'),
            },
            {
                title: i18n.t('common:text.contract_name_of_service_start_date'),
            },
            {
                title: i18n.t('common:text.contract_name_of_service_end_date'),
            }
        ]
        let has_undefined = (data_servicios.length > 0 && data_servicios.includes(undefined))
        let content = []
        if(data_servicios !== [] && !has_undefined) {
            content = data_servicios.map( function(data_servicio, index) {
                if (data_servicio === undefined) return []
                const values = [
                    data_servicio.name || "",
                    new Date(data_servicio.start_date).toLocaleDateString(locale_code) || "",
                    data_servicio.end_date ?  new Date(data_servicio.end_date).toLocaleDateString(locale_code) : "-",
                ]
    
                return values;
            }) ;
        }


        return {
            header,
            content
        };
    }

    render() {
        let token_values = {};
        try {
            token_values = jwt_decode(this.props.token);
        } catch (error) {
            this.props.actions.auth.logoutAndRedirect();
        }
        const element = this.props.contract;
        const showForm = this.props.editFormOpen;

        const iban = element.bank_account ? element.bank_account.printable_iban : i18n.t('common:text.contract_no_domiciled');
        let servicios = (element.services !== null || element.services !== undefined)? element.services : [];
        let bonosSociales = (element.bonos_sociales !== null || element.bonos_sociales !== undefined) ? element.bonos_sociales : [];
        let data_servicios = servicios.concat(bonosSociales);
        const adaptedData = data_servicios && this.JSON_to_arrays(data_servicios);

        const reclamActions = [
            <Button
                key={'close'}
                variant={'text'}
                color={'primary'}
                onClick={() => this.setState({ dialogReclamOpen: false })}
            > 
                { i18n.t('common:text.contract_close_dialog') }
            </Button>,
        ];
        const dialogReclam = (
            <Dialog 
                open={this.state.dialogReclamOpen}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogContent>
                    <ReclamATC value={this.props.contract} />
                </DialogContent>
                <DialogActions>
                    {reclamActions}
                </DialogActions>
            </Dialog>
        );

        const notifyAddr = _.get(element, 'notification_address', {});

        const contractedPowers = (element.contracted_power_period && element.contracted_power_period.length) ? element.contracted_power_period.reduce((accumulator, currentValue) => {
            return `${accumulator} ${currentValue.period}: ${currentValue.power.toLocaleString(locale_code)} kW`;
        }, '') : '';

        const selectContractField = (
            <FormControl>
                <Select
                    value={element.id}
                    onChange={this.handleContractChange}
                >
                    { this.props.data.items &&
                        this.props.data.items.map(contract => <MenuItem key={contract.id} value={contract.id}> {contract.name} </MenuItem>)
                    }
                </Select>
            </FormControl>
        )

        const Contract = (
            <div style={styles.root} className="contract-component">

                <Dialog
                    open={showForm}
                    disableBackdropClick={true}
                    onBackdropClick={this.dialogClose}
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogContent>
                        {this.state.formContent}
                    </DialogContent>
                    <DialogActions></DialogActions>
                </Dialog>

                {dialogReclam}
                { !this.state.onlyConsumption && 
                    <div>
                        <Card style={styles.entry}>
                            <CardContent>
                                <Box pb={2}>
                                    <Typography variant="h5">
                                        {i18n.t('common:text.contract_data_contract')}{selectContractField}
                                    </Typography>
                                </Box>
                                { features.noCesionSips &&
                                <div>
                                    <ContractNoCesion 
                                        contract={ element } 
                                        changeDialogSipsOpen={this.changeDialogSipsOpen.bind(this)} 
                                        open={ this.state.dialogSipsOpen }
                                    />
                                </div>
                                }
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_owner') }>{_.get(element, 'owner_partner.name', '')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_payer') }>{_.get(element, 'fiscal_partner.name', '')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_contracted_power') }>
                                            {contractedPowers}

                                            {features.powerChange && !_.get(Settings, "contract.horizontalButtons", false) &&
                                                <span>
                                                    <Button
                                                        variant={'text'}
                                                        color={'primary'}
                                                        disabled={element.state !== 'activa' || !element.atr_available}
                                                        title={ i18n.t('common:text.contract_power_modification') }
                                                        onClick={() => this.changePower()}
                                                    > 
                                                        { i18n.t('common:text.contract_modification') }
                                                    </Button>
                                                    {!element.atr_available &&
                                                    <div style={{fontSize: '12px'}}><em>{ i18n.t('common:text.contract_process_currently') }</em></div>
                                                    }
                                                </span>
                                            }
                                        </TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div>
                                            <TextFieldReadOnly label={ i18n.t('common:text.contract_contracted_product') }>{_.get(element, 'pricelist.name', '')}</TextFieldReadOnly>
                                            <CustomizeDoubleTariff
                                                contractId={element.name}
                                                onUpdated={this.handleUpdateTariffCustomization}
                                            />
                                            <CustomizeHappyHours
                                                contractId={element.name}
                                                onUpdated={this.handleUpdateTariffCustomization}
                                            />
                                            <CustomizeHappyDay
                                                contractId={element.name}
                                                onUpdated={this.handleUpdateTariffCustomization}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_payment_account') }>{iban}
                                            {features.ibanChange && !_.get(Settings, "contract.horizontalButtons", false) &&
                                                <Button
                                                    variant={'text'}
                                                    color={'primary'}
                                                    disabled={(element.state !== 'activa')}
                                                    title={ i18n.t('common:text.contract_account_modification') }
                                                    onClick={() => this.changeIBAN()}
                                                >
                                                    {iban !== i18n.t('common:text.contract_no_domiciled') ? i18n.t('common:text.contract_modification') : i18n.t('common:text.contract_enable_domiciliation') }
                                                </Button>
                                            }
                                        </TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_nif') }>{_.get(element, 'fiscal_partner.vat', '')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_fiscal_address') }>{_.get(element, 'fiscal_address.full_address', '')}</TextFieldReadOnly>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Box flexWrap="wrap" px={1} pb={1}>
                                    <Button
                                        key={'download'}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={this.exportContract}
                                        disabled={this.props.downloading_contract}
                                        style={{marginRight: 12, marginTop: 8}}
                                    >
                                        {(this.props.downloading_contract?<Refresh style={styles.iconBefore}/>:<GetApp style={styles.iconBefore}/>)}
                                        {i18n.t('common:text.contract_download')}
                                    </Button>
                                    <Button
                                        key={'invoices'}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => this.props.actions.contract.filterContractsInvoices(element)}
                                        title={ i18n.t('common:text.contract_related_invoices') }
                                        style={{marginRight: 12, marginTop: 8}}
                                    >
                                        {<Receipt style={styles.iconBefore}/>}
                                        {i18n.t('common:text.contracts_view_invoices_button_button')}
                                    </Button>

                                    { features.reclamATCContract && 
                                    <Button
                                        key={'reclamation'}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={ () => this.setState({dialogReclamOpen: true}) }
                                        style={{marginRight: 12, marginTop: 8}}
                                    >
                                        { <Feedback style={styles.iconBefore}/> }
                                        { i18n.t('common:text.contract_create_atc_reclamation') }
                                    </Button>
                                    }
                                    {features.powerChange && _.get(Settings, "contract.horizontalButtons", false) && 
                                    <Button
                                        key={'changePower'}
                                        variant={'contained'}
                                        color={'primary'}
                                        disabled={element.state !== 'activa' || !element.atr_available}
                                        title={ i18n.t('common:text.contract_power_modification') }
                                        onClick={() => this.changePower()}
                                        style={{marginRight: 12, marginTop: 8}}
                                    >
                                        { <FlashOn style={styles.iconBefore}/> }
                                        { i18n.t('common:text.contract_power_modification') }
                                    </Button>
                                    }
                                    {features.noCesionSips && _.get(Settings, "contract.horizontalButtons", false) && 
                                    <Button
                                        key={'sips'}
                                        variant={'contained'}
                                        color={'primary'}
                                        disabled={element.state !== 'activa' || !element.atr_available || element.no_cession_sips === 'active'}
                                        title={ i18n.t('common:text.sips_title') }
                                        onClick={() =>  this.setState({dialogSipsOpen: true})}
                                        style={{marginRight: 12, marginTop: 8}}
                                    >
                                        { <Visibility style={styles.iconBefore}/> }
                                        { i18n.t('common:text.sips_title') }
                                    </Button>
                                    }
                                    {features.ibanChange && iban !== i18n.t('common:text.contract_no_domiciled') && _.get(Settings, "contract.horizontalButtons", false) &&
                                    <Button
                                        key={'iban'}
                                        variant={'contained'}
                                        color={'primary'}
                                        disabled={(element.state !== 'activa')}
                                        title={ iban !== i18n.t('common:text.contract_no_domiciled') ? i18n.t('common:text.contract_account_modification') : i18n.t('common:text.contract_enable_domiciliation') }
                                        onClick={() => this.changeIBAN()}
                                        style={{marginRight: 12, marginTop: 8}}
                                    >
                                        { <AccountBalance style={styles.iconBefore}/> }
                                        { iban !== i18n.t('common:text.contract_no_domiciled') ? i18n.t('common:text.contract_account_modification') : i18n.t('common:text.contract_enable_domiciliation') }
                                    </Button>
                                    }
                                </Box>
                            </CardActions>
                        </Card>

                        <Card style={styles.entry}>
                            <CardContent>
                                <Box pb={2}>
                                    <Typography variant="h5">
                                    { i18n.t('common:text.contract_contact_data') }
                                    </Typography>
                                </Box>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_contact_data_name') }>{notifyAddr.name}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_contact_data_address') }>{notifyAddr.full_address}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        { !notifyAddr.email &&
                                        <TextFieldEdit field="email" label={ i18n.t('common:text.contract_contact_data_email') } onUpdated={this.onUpdateEmail} value={token_values.email}/>
                                        }
                                        {notifyAddr.email &&
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_contact_data_email') }>
                                            {notifyAddr.email}
                                            <InvoiceOnline/>
                                            {
                                                _.get(Settings, "contract.electronicNotificationButton", false) && <ContractElectronicNotification />
                                            }
                                        </TextFieldReadOnly>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        { !notifyAddr.mobile &&
                                        <TextFieldEdit field="phone" label={ i18n.t('common:text.contract_contact_data_phone') } onUpdated={this.onUpdatePhone} value={token_values.mobile}/>
                                        }
                                        {notifyAddr.mobile &&
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_contact_data_phone') }>{notifyAddr.mobile}</TextFieldReadOnly>
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Box flexWrap="wrap" px={1} pb={1}>
                                    {features.notificacionAddressChange &&
                                        <Button
                                            key={'modifyAddress'}
                                            variant={'contained'}
                                            color={'primary'}
                                            disabled={(element.state !== 'activa')}
                                            title={ i18n.t('common:text.contract_modification') }
                                            onClick={() => this.changeNotificationAddress()}
                                            style={{marginRight: 12, marginTop: 8}}
                                        >
                                            {<Create style={styles.iconBefore}/>}
                                            { i18n.t('common:text.contract_modification') }
                                        </Button>
                                    }
                                </Box>
                            </CardActions>
                        </Card>

                        <Card style={styles.entry}>
                            <CardContent>
                                <Box pb={2}>
                                    <Typography variant="h5">
                                    { i18n.t('common:text.contract_cups_info') }
                                    </Typography>
                                </Box>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_cups') }>{_.get(element, 'cups.name', '')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_cups_address') }>{_.get(element, 'cups.street', '')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_distri') }>{_.get(element, 'dso.name', '')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_contract_distri') }>{element.dso_reference}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_breakdown_num') }>
                                            {element.dso_phone && <a href={`tel:${element.dso_phone}`}>{ element.dso_phone }</a>}
                                        </TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_access_tariff') }>{_.get(element, 'tariff.name')}</TextFieldReadOnly>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextFieldReadOnly label={ i18n.t('common:text.contract_cups_info_ref') }>{_.get(element, 'cups.ref_catastral', '')}</TextFieldReadOnly>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {adaptedData.content.length !== 0 &&

                            <Card style={styles.entry}>
                                <CardContent>
                                    <Box pb={2}>
                                        <Typography variant="h5">
                                        { i18n.t('common:text.contract_services') }
                                        </Typography>
                                    </Box>
                                    <div>
                                        <SmartTable
                                            header={adaptedData.header}
                                            data={adaptedData.content}
                                        />
                                    </div>
                                </CardContent>
                            </Card>

                        }
                    </div>
                }
                <Card style={styles.entry}>
                    {this.state.onlyConsumption ?
                        <Box p={2}>
                            <Typography variant="h5">
                                {i18n.t('common:text.contract_data_consumption')}{selectContractField}
                            </Typography>
                        </Box>
                        :
                        <CardHeader title={ i18n.t('common:text.contract_consumptions') }/>
                    }
                    <CardContent>
                        <CCHChart contractId={element.name} token={this.props.token}/>
                    </CardContent>
                </Card>
                <Notification
                    message={this.props.message}
                    time={6000}
                    open={true}
                    onRequestClose={() => this.props.actions.contract.messageContract(null)}
                />

            </div>

        );
        return Contract;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AltContract);

AltContract.propTypes = {
};
