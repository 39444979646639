import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from '../config';
import * as actionCreators from '../actions/auth';

import { i18n } from "../config";

import { validate_token, undefine_token } from '../utils/http_functions';
import { retrieve_token } from '../utils/auth';
import { features } from '../settings';
import CookieDialog from './CookieDialog';

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export function requireAuthentication(Component) {
    class AuthenticatedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loaded_if_needed: false
            };
        }

        static getDerivedStateFromProps(props, state) {
            return AuthenticatedComponent.checkAuth(props, state);
        }

        static redirectTo(new_url, props) {
          //It redirects to new_url preserving the passed queryString
          const {search} = props.location;
          const queryString = search?
                  search + "&" + i18n.t('common:url.next') + '=' + props.location.pathname
                  :"?" + i18n.t('common:url.next') + '=' + props.location.pathname;
          history.push(new_url + queryString);
        }

        static checkAuth(props, state) {
            let login_url;
            if (features.oidc) {
                login_url = i18n.t('common:url.connect');
            } else {
                login_url = i18n.t('common:url.login');
            }
            
            if (!props.isAuthenticated) {
                const token = retrieve_token();
                if (!token) {
                    AuthenticatedComponent.redirectTo(login_url, props);
                } else {
                    validate_token(token)
                        .then(res => {
                            if (res.status === 200) {
                                props.loginUserSuccess(token);
                                state = { loaded_if_needed: true };
                            } else {
                                AuthenticatedComponent.redirectTo(login_url, props);
                            }
                        })
                        .catch(error => {
                            undefine_token();
                            AuthenticatedComponent.redirectTo(login_url, props);
                        });
                }
            } else {
                state = { loaded_if_needed: true };
            }
            return state;
        }

        render() {
            return (
                <div>
                    {this.props.isAuthenticated && this.state.loaded_if_needed
                        ? <Component {...this.props} />
                        : null
                    }
                    <CookieDialog/>
                </div>
            );

        }
    }

    AuthenticatedComponent.propTypes = {
        loginUserSuccess: PropTypes.func,
        isAuthenticated: PropTypes.bool,
    };

    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
