import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import { Button, Fade, MenuItem, TextField, Grid } from '@material-ui/core';
import { InfoContainer } from "../InfoContainer";
import { ArrowBack, Send } from '@material-ui/icons';
import MultiUploadButton from "./components/MultiUploadButton";
import { fetchCRMCaseCategories, sendNewCRMCase, fetchCRMCases } from '../../actions/crm';
import { fetchContracts } from "../../actions/contracts";
import { i18n } from "../../config";
import { dispatchNewRoute } from '../../utils/http_functions';

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
});

class CRMCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      category: '',
      validationErrors: {},
    };
  }

  async componentDidMount() {

    if (_.isEmpty(this.props.contracts)) {
      await this.props.fetchContracts(this.props.token);
    }
  }

  returnButton() {
    const { classes, t } = this.props;
    return (
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<ArrowBack />}
        onClick={() => {dispatchNewRoute(i18n.t('common:url.CRM_list'))}}
      >
        {t("crm_go_back")}
      </Button>
    );
  }

  handleChangeCategory = (event) => {
    this.setState({category: event.target.value});
  }

  handleChangePolicy = (event) => {
    this.setState({policy: event.target.value});
  }

  handleChangeTitle = (event) => {
    this.setState({title: event.target.value});
  }

  handleChangeDescription = (event) => {
    this.setState({description: event.target.value});
  }

  handleUpload = (file) => {
    this.setState({attachments: file});
  };
  
  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      'polissa_id': this.state.policy,
      'categ_id': this.state.category,
      'title': this.state.title,
      'message': this.state.description,
      'attachments': this.state.attachments
    }
    this.props.sendNewCRMCase(data)
    .then(
      response => {
        this.props.fetchCRMCases();
        if(response[0] === 200){
          dispatchNewRoute(i18n.t('common:url.CRM_detail_id', { id: response[1].id }));
        };
      }
    );
  }

  renderForm(){
    const { t, categories, contracts } = this.props;
    return(
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <div>
          
          { !_.isEmpty(categories.data) &&
            <TextField
              id="select_category"
              select
              label={t("common:category")}
              value={t("common:"+this.state.category)}
              fullWidth
              onChange={this.handleChangeCategory}
              style={{marginBottom: '3%'}}
              required
            >
              { categories.data.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))
              }
            </TextField>
          }

          { !_.isEmpty(contracts) &&
            <TextField
              id="select_policy"
              select
              label={t("common:contract")}
              value={this.state.policy ? this.state.policy : ''}
              fullWidth
              onChange={this.handleChangePolicy}
              style={{marginBottom: '3%'}}
            >
              { _.toArray(contracts).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name} - {option.notification_address.full_address}
                  </MenuItem>
                ))
              }
            </TextField>
          }

          <TextField
            id="asunto"
            label={t("common:subject")}
            fullWidth
            required
            onChange={this.handleChangeTitle}
            style={{marginBottom: '3%'}}
          >
          </TextField>

          <TextField
            id="descripcion"
            label={t("common:description")}
            fullWidth
            multiline
            minRows={8}
            required
            onChange={this.handleChangeDescription}
            style={{marginBottom: '3%'}}
          >
          </TextField>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <MultiUploadButton
                min={1}
                max={3}
                label={t('crm_attachment_add')}
                onChange={this.handleUpload}
                value={this.state.attachment}
                anotherLabel={t('crm_attachment')}
                removeLabel={t('crm_attachment_remove')}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                endIcon={<Send />}
                type="submit"
                disabled={!(this.state.title && this.state.description)}
                style={{marginLeft: 5, marginTop: 5}}
              >
                {t("common:send")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }

  render() {
    const { classes, t } = this.props;
    return (
      <Fade in={true}>
        <InfoContainer
          title={t("crm_new_case")}
          className={classes.container}
          actionButton={this.returnButton()}
        >
          <div style={{ display: "flex", justifyContent: "left" }}>
            <div style={{ flexBasis: "400px" }}>{this.renderForm()}</div>
          </div>
        </InfoContainer>
      </Fade>
    );
  }
}

const mapDispatchToProps = {
  fetchCRMCaseCategories,
  fetchCRMCases,
  fetchContracts,
  sendNewCRMCase,
};

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    contracts: state.contracts.items || [],
    categories: _.get(state, 'CRMCases.categories', {}),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common'])
)(CRMCreate);
