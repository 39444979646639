import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { dispatchNewRoute } from "../../utils/http_functions";
import { i18n } from "../../config";
import * as actionCreators from "../../actions/auth";
import jwt_decode from "jwt-decode";
import { LogoBar } from "../LogoBar";
import { history } from '../../config';

import UILangSelector from "../UILangSelector";
import Settings from "../../settings";
import { retrieve_openid_token } from "../../utils/auth"

import { Button, Divider, MenuItem, AppBar, Toolbar, Menu, Box } from "@material-ui/core"

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    userName: state.auth.userName,
    userRoles: state.auth.userRoles,
    userGroups: state.auth.userGroups,
    userImage: state.auth.userImage,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class UserProfileButton extends Component {
  state = { open: false, element: null };

  handleMenuOpen = (event) => {
    this.setState({ open: true });
    this.setState({ element: event.currentTarget });
  };

  dispatchRoute(route) {
    dispatchNewRoute(route);
    this.setState({
      open: false,
    });
  }

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ element: null });
  };

  render() {
    const { user } = this.props;
    let sub = "";
    if (_.get(Settings, "features.oidc", false)) {
      const oid_token = retrieve_openid_token();
      try {
        const oid_token_values = jwt_decode(oid_token);
        console.log(oid_token_values);
        sub = oid_token_values.sub;
      } catch (exp) {}
    }
    return (
      <div>
        <Button
          className="horizontal-btn"
          variant={'contained'}
          onClick={this.handleMenuOpen}
        >
          {i18n.t('common:text.myprofile_title')}
        </Button>
        <Menu
          open={this.state.open}
          anchorEl={this.state.element}
          onClose={this.handleClose}
        >
          <Box
            className="header-horizontal-profile-name"
            title={sub} m={1}
          >
            <MenuItem key={"0"}>
              <div>
                {i18n.t('common:text.user_welcome')} <br />
                <strong>{user.name}</strong>
                <br />
                {user.email}
              </div>
            </MenuItem>
          </Box>
          <Divider />
          <Box m={1}>
            {!_.get(Settings, "features.oidc", false) ?
              <MenuItem
                key={"1"}
                value="1"
                onClick={() => {
                  this.dispatchRoute(i18n.t('common:url.changePassword'))
                }}
              >
                {i18n.t('common:text.change_password_view_title')}
              </MenuItem>
              :
              <MenuItem
                key={"1"}
                value="1"
                onClick={() => {
                  window.open(Settings.oidc.changePasswordUrl, "_blank");
                }}
              >{i18n.t('common:text.change_password_title')}</MenuItem>
            }
            {_.get(Settings, "features.CRMcases", false) &&
              <MenuItem
                key={"1"}
                value="1"
                onClick={() => {
                  history.push(i18n.t('common:url.CRM_list'));
                }}
              >{i18n.t('common:text.crm_manage_requests')}</MenuItem>
            }
            <MenuItem
              key={"2"}
              value="2"
              onClick={this.props.logout}
            >{i18n.t('common:text.logout_title')}</MenuItem>
          </Box>
        </Menu>
      </div>
    );
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  dispatchRoute(route) {
    dispatchNewRoute(route);
    this.setState({
      open: false,
    });
  }

  logout = (e) => {
    e.preventDefault();
    this.props.logoutAndRedirect();
    this.setState({
      open: false,
    });
  };

  render() {
    let current_path = history.location.pathname;
    let user = null;
    let noUser = (
      
      <div className="header-horizontal">
        {(current_path !== i18n.t('common:url.login')) &&
          !_.get(Settings, "features.oidc", false) && (
          <Button
            className="horizontal-btn"
            variant={'contained'}
            onClick={() => this.dispatchRoute(i18n.t('common:url.login'))}
            >
            {i18n.t('common:text.login_title')}
          </Button>
        )}
        {_.get(Settings, "features.signup", false) &&
          !_.get(Settings, "features.oidc", false) && (
            <Button
              className="horizontal-btn"
              variant={'contained'}
              onClick={() => this.dispatchRoute(i18n.t('common:url.activate'))}
            >
              {i18n.t('common:text.login_view_button_activate')}
            </Button>
          )}
        {_.get(Settings, "features.unsignedContact", false) &&
          _.get(Settings, "features.contact", false) && (
            <Button
              className="horizontal-btn"
              variant={'contained'}
              onClick={() => this.dispatchRoute(i18n.t('common:url.unsigned_contact'))}
            >
              {i18n.t('common:text.contact_title')}
            </Button>
          )}
        {(current_path !== i18n.t('common:url.gasPriceComparator')) && _.get(Settings, "comparator.enabled", false) && (
          <Button
            className="horizontal-btn"
            variant={'contained'}
            onClick={() => this.dispatchRoute(i18n.t('common:url.priceComparator'))}
          >
            {i18n.t('common:text.menu_price_comparator_title')}
          </Button>
        )}
        {(current_path !== i18n.t('common:url.gasPriceComparator')) && _.get(Settings, "powerCalculator.enabled", false) && (
          <Button
            className="horizontal-btn"
            variant={'contained'}
            onClick={() => this.dispatchRoute(i18n.t('common:url.powerCalculator'))}
          >
            {i18n.t('common:text.menu_power_calculator_title')}
          </Button>
        )}
        <UILangSelector />
      </div>
    );

    if (this.props.token) {
      const token_values = jwt_decode(this.props.token);
      user = (
        <div className="header-horizontal">
          <Button
            className="horizontal-btn"
            variant={'contained'}
            onClick={() => this.dispatchRoute(i18n.t('common:url.contracts'))}
          >
            {i18n.t('common:text.contracts_title')}
          </Button>
          <Button
            className="horizontal-btn"
            variant={'contained'}
            onClick={() => this.dispatchRoute(i18n.t('common:url.invoices'))}
          >
            {i18n.t('common:text.invoices_title')}
          </Button>
          {_.get(Settings, "features.contact", false) && (
            <Button
              className="horizontal-btn"
              variant={'contained'}
              onClick={() => this.dispatchRoute(i18n.t('common:url.contact'))}
            >
              {i18n.t('common:text.contact_title')}
            </Button>
          )}
          {_.get(Settings, "features.reclamATCMenu", false) && (
            <Button
              className="horizontal-btn"
              variant={'contained'}
              onClick={() => this.dispatchRoute(i18n.t('common:url.reclamations'))}
            >
              {i18n.t('common:text.reclamations_title')}
            </Button>
          )}
          {_.get(Settings, "features.contactAutoReads", false) && (
            <Button
              className="horizontal-btn"
              variant={'contained'}
              onClick={() => {
                  this.dispatchRoute(i18n.t('common:url.contact') + '?autolectures=true');
                }
              }
            >{i18n.t('common:text.send_readings_label')}
            </Button>
          )}
          <UserProfileButton logout={this.logout} user={token_values} />
          <UILangSelector />
        </div>
      );
    }

    return (
      <header>
        <AppBar position={'static'}>
          <Toolbar>
            {
              <div className="logo-wrapper">
                <LogoBar openLinkInNewTab={false} />
              </div>
            }
            {this.props.isAuthenticated ? user : noUser}
          </Toolbar>
        </AppBar>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

Header.propTypes = {
  logoutAndRedirect: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};
