import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/auth';
import { validate_token, undefine_token } from '../utils/http_functions';
import { retrieve_token } from '../utils/auth';
import CookieDialog from './CookieDialog';

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

export function DetermineAuth(Component) {

    class AuthenticatedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loaded_if_needed: false
            };
        }

        componentDidMount() {
            this.setState(AuthenticatedComponent.checkAuth(this.props, this.state));
        }

        static getDerivedStateFromProps(props, state) {
            return AuthenticatedComponent.checkAuth(props, state);
        }

        static checkAuth(props, state) {
            let newState = {};
            if (!props.isAuthenticated) {
                const token = retrieve_token();
                if (token) {
                    validate_token(token)
                        .then(res => {
                            if (res.status === 200) {
                                props.loginUserSuccess(token);
                            } else {
                                // Server returned a non-ok status, so we remove the token
                                undefine_token();
                            }
                            newState = { loaded_if_needed: true };
                        }).catch(error => {
                            // Token probably not valid or has expired so we remove it
                            undefine_token();
                            newState = { loaded_if_needed: true };
                        });
                } else {
                    newState = { loaded_if_needed: true };
                }
            } else {
                newState = { loaded_if_needed: true };
            }
            return newState;
        }

        render() {
            return (
                <div>
                    {this.state.loaded_if_needed
                        ? <Component {...this.props} />
                        : null
                    }
                    <CookieDialog/>
                </div>
            );
        }
    }

    AuthenticatedComponent.propTypes = {
        loginUserSuccess: PropTypes.func,
        isAuthenticated: PropTypes.bool,
    };

    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);

}
