import React from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core"

const CheckboxField = ({
  input: { onChange, value },
  onUpdate,
  meta,
  label,
  ...rest
}) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={!!value}
          onChange={(event, isInputChecked) => {
            onChange(isInputChecked);
            if (typeof onUpdate === "function") {
              onUpdate(isInputChecked);
            }
          }}
          {...rest}
        />
      }
      label={label}
    />
    {meta.error && meta.touched && (
      <span
        style={{
          fontSize: "12px",
          lineHeight: "12px",
          color: "rgb(244, 67, 54)",
        }}
      >
        {meta.error}
      </span>
    )}
  </div>
);

export default CheckboxField;
