import _ from "lodash";
import Settings from "../settings";
import {
  SUBMIT_ABOUT_HOME_DATA,
  SUBMIT_ABOUT_YOU_DATA,
  SUBMIT_PAYMENT_DATA,
  SET_ACCESS_TARIFF,
  SET_POWER,
  SET_POWER2,
  SET_POWER3,
  SET_POWER4,
  SET_POWER5,
  SET_POWER6,
  SUBMIT_ABOUT_CONTRACT_DATA,
  SUBMIT_ABOUT_GAS_CONTRACT_DATA,
  SUBMIT_CONFIRM_DATA,
  STORE_SIPS_DATA,
  FETCH_AVAILABLE_PRODUCTS_REQUEST,
  RECEIVE_AVAILABLE_PRODUCTS,
  SELECT_PRODUCT,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST,
  NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE,
  FETCH_CONTRACTATION_CONFIG_REQUEST,
  RECEIVE_CONTRACTATION_CONFIG,
} from "../constants";
import { createReducer } from "../utils/misc";


const initialState = {
  leadsConsent: null,
  cups: null,
  address: {},
  habitual: true,
  cnae: null,
  invoice: null,
  comerOrigin: null,

  vat: null,
  company: false,
  name: null,
  surName1: null,
  surName2: null,
  cif: null,
  changeOwner: false,
  vatRepresentante: null,
  nameRepresentante: null,
  surName1Representante: null,
  surName2Representante: null,
  sameInvoiceAddress: true,
  invoiceAddress: {},
  commsLanguage: null,
  email: null,
  mobile: null,

  power: null,
  power2: null,
  power3: null,
  power4: null,
  power5: null,
  power6: null,
  accessTariff: null,
  selfcons: false,
  selfconsAttachment: null,

  includeGas: _.get(Settings, 'newDualContract.gasIsMandatory', false),

  availableProducts: [],
  selectedProduct: null,

  iban: null,
  isOwner: false,

  promotionalCode: null,
  acceptOVConditions: false,
  acceptGdpr: false,

  isConfirming: false,
};

export default createReducer(initialState, {
  [SUBMIT_ABOUT_HOME_DATA]: (state, payload) =>
    Object.assign({}, state, {
      leadsConsent: payload.leadsConsent,
      cups: payload.cups,
      address: payload.address,
      habitual: payload.habitual,
      cnae: payload.cnae,
      invoice: payload.invoice,
      comerOrigin: payload.comerOrigin,
    }),
  [SUBMIT_ABOUT_YOU_DATA]: (state, payload) =>
    Object.assign({}, state, {
      vat: payload.vat,
      company: payload.company,
      name: payload.name,
      surName1: payload.surName1,
      surName2: payload.surName2,
      changeOwner: payload.changeOwner,
      vatRepresentante: payload.vatRepresentante,
      nameRepresentante: payload.nameRepresentante,
      surName1Representante: payload.surName1Representante,
      surName2Representante: payload.surName2Representante,
      sameInvoiceAddress: payload.sameInvoiceAddress,
      invoiceAddress: payload.invoiceAddress,
      commsLanguage: payload.commsLanguage,
      email: payload.email,
      mobile: payload.mobile,
      nifFaceup: payload.nifFaceup,
    }),
  [SET_ACCESS_TARIFF]: (state, payload) =>
    Object.assign({}, state, {
      accessTariff: payload.accessTariff,
    }),
  [SET_POWER]: (state, payload) =>
    Object.assign({}, state, {
      power: payload.power,
    }),
  [SET_POWER2]: (state, payload) =>
  Object.assign({}, state, {
    power2: payload.power,
  }),
  [SET_POWER3]: (state, payload) =>
  Object.assign({}, state, {
    power3: payload.power,
  }),
  [SET_POWER4]: (state, payload) =>
  Object.assign({}, state, {
    power4: payload.power,
  }),
  [SET_POWER5]: (state, payload) =>
  Object.assign({}, state, {
    power5: payload.power,
  }),
  [SET_POWER6]: (state, payload) =>
  Object.assign({}, state, {
    power6: payload.power,
  }),
  [SUBMIT_ABOUT_CONTRACT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      power: payload.power,
      power2: payload.power2,
      accessTariff: payload.accessTariff,
      selectedProduct: payload.selectedProduct,
      selfcons: payload.selfcons,
      selfconsAttachment: payload.selfconsAttachment,
    }),
  [SUBMIT_ABOUT_GAS_CONTRACT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      cupsGas: payload.cupsGas,
      comerGasOrigin: payload.comerGasOrigin,
      selectedGasProduct: payload.selectedGasProduct,
    }),
  [SUBMIT_PAYMENT_DATA]: (state, payload) =>
    Object.assign({}, state, {
      iban: payload.iban,
      isOwner: payload.isOwner,
    }),
  [SUBMIT_CONFIRM_DATA]: (state, payload) =>
    Object.assign({}, state, {
      promotionalCode: payload.promotionalCode,
      acceptOVConditions: payload.acceptOVConditions,
      acceptGdpr: payload.acceptGdpr,
    }),
  [SELECT_PRODUCT]: (state, payload) =>
    Object.assign({}, state, {
      selectedProduct: payload.product,
    }),
  [FETCH_AVAILABLE_PRODUCTS_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_AVAILABLE_PRODUCTS]: (state, payload) =>
    Object.assign({}, state, {
      availableProducts: payload.availableProducts,
      isFetching: false,
    }),
  [NEW_ELECTRICITY_CONTRACT_CONFIRM_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isConfirming: true,
    }),
  [NEW_ELECTRICITY_CONTRACT_CONFIRM_RECEIVE]: (state, payload) =>
    Object.assign({}, state, {
      confirmResponse: payload.confirmResponse,
      isConfirming: false,
    }),
  [FETCH_CONTRACTATION_CONFIG_REQUEST]: (state, payload) =>
    Object.assign({}, state, {
      isFetching: true,
    }),
  [RECEIVE_CONTRACTATION_CONFIG]: (state, payload) =>
    Object.assign({}, state, {
      contractationConfig: payload.contractationConfig,
      ...payload.contractationConfig,
      isFetching: false,
    }),
  [STORE_SIPS_DATA]: (state, payload) =>
    Object.assign({}, state, {
      power: payload.sipsData.power_p1,
      power2: payload.sipsData.power_p2,
      selfcons: payload.sipsData.selfcons,
      accessTariff: payload.sipsData.access_tariff,
      address: payload.sipsData.address,
      sipsData: !!payload.sipsData.power,
      // To avoid data loss
      leadsConsent: payload.sipsData.leadsConsent,
      cups: payload.sipsData.cups,
      habitual: payload.sipsData.habitual,
      cnae: payload.sipsData.cnae,
    }),
});
