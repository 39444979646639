import _ from "lodash";
import {
    FETCH_CONTRACTS_REQUEST,
    RECEIVE_CONTRACTS,
    EXPORT_CONTRACT_REQUEST,
    EXPORT_CONTRACT_DONE,
    FILTER_CONTRACTS_REQUEST,
    MESSAGE_CONTRACT,
} from '../constants/index'
import {
  data_fetch_api_resource,
  data_download_api_resource,
  dispatchNewRoute,
  data_create_api_resource,
} from '../utils/http_functions'
import {
  parseJSON
} from '../utils/misc'
import {
  logoutAndRedirect
} from './auth'
import { i18n } from "../config";
import Settings from "../settings";
import saveAs from 'file-saver';


export function fetchContractsRequest(initial) {
    const message = initial ? null: i18n.t('common:text.contracts_list_updating');

    return {
        type: FETCH_CONTRACTS_REQUEST,
        payload: {
            message,
        },
    };
}

export function filterContractsInvoicesRequest(contract) {
    return {
        type: FILTER_CONTRACTS_REQUEST,
        payload: {
            contract
        }
    }
}

export function filterContractsInvoices(contract) {
    return (dispatch) => {
        dispatch(filterContractsInvoicesRequest(contract));
        dispatchNewRoute(i18n.t('common:url.invoices'));
    }
}


export function receiveContracts(response, initial) {
    const message = initial ? null : i18n.t('common:text.contracts_list_updated');
    return {
        type: RECEIVE_CONTRACTS,
        payload: {
            response,
            message,
        },
    };
}

export function fetchContracts(token, initial=false, offset=0, filter=null) {
    return (dispatch) => {
        dispatch(fetchContractsRequest(initial));
        let url = `contracts/?offset=${offset}&limit=${_.get(Settings, "contracts.itemsPerPage", 100)}`;
        if (filter) {
            filter = filter.replace(/ /g, '%');
            url = `${url}&filter=['|',('name', 'ilike', '${filter}'), '|', ('cups.direccio', 'ilike', '${filter}'), ('cups.name', 'ilike', '${filter}')]`;
        }
        data_fetch_api_resource(token, url)
            .then(parseJSON)
            .then(response => {
                dispatch(receiveContracts(response, initial));
            })
            .catch(error => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
                else {
                    dispatch(receiveContracts({
                        items: [], limit: 80, n_items: 0, offset: 0
                    }, initial))
                }
            });
    };
}

export const messageContract = message => {
  return {
    type: MESSAGE_CONTRACT,
    payload: {
      message
    }
  }
}


export function exportContractRequest() {
    return {
        type: EXPORT_CONTRACT_REQUEST,
    };
}

export function exportContractDone() {
    return {
        type: EXPORT_CONTRACT_DONE,
    };
}

export function exportContract(token, contract) {
    return (dispatch) => {
        dispatch(exportContractRequest());
        if (_.get(Settings, "invoices.downloadInNewTab", false)) {
          let newTab = window.open(i18n.t('common:url.loading'), '_blank');
          return data_download_api_resource(token, `contracts/${ contract }/pdf/`)
            .then(response => response.data)
            .then(blob => URL.createObjectURL(blob))
            .then(anUrl => {
              newTab.location.href = anUrl;
              URL.revokeObjectURL(i18n.t('common:url'));
            })
            .catch(error => {
              if (error.status === 401) {
                dispatch(logoutAndRedirect(error));
              }
            })
            .finally(() => {
              dispatch(exportContractDone());
            });
        } else {
            data_download_api_resource(token, `contracts/${ contract }/pdf/`)
              .then(response => {
                  const filename = response.headers["content-disposition"].split("=");
                  dispatch(messageContract(i18n.t('common:text.contract_download_ready')));
                  saveAs(response.data, filename[1]);
              })
              .catch(error => {
                  if (error.status === 401) {
                      dispatch(logoutAndRedirect(error));
                  }
              })
              .finally(() => {
                  dispatch(exportContractDone());
              });
        }
    };
}

export const viewContract = contract => ({
   type: 'VIEW_CONTRACT',
   payload: contract
});

export const editContract = (open = true) => ({
  type: 'EDIT_CONTRACT',
  payload: open
});


export const fetchAddress = () => async (dispatch, getState) => {
    const { token } = getState().auth;
    const result = await data_fetch_api_resource(token, 'addresses/');
    return dispatch({
        type: 'FETCH_ADDRESSES',
        payload: result && result.data
    });
};

export const setAddress = value => async (dispatch, getState) => {
  dispatch({type: 'SET_ADDRESS_REQUEST'});
  const { token } = getState().auth;
  const { view_contract } = getState().contracts;
  try {
    let address_id = null;
    const result = await data_create_api_resource(token, `contracts/${view_contract.name}/notification/`, value);
    if (value.address_type === 'new') {
      await dispatch(fetchAddress());
      address_id = result.data.result;
    } else {
      address_id = value.address_id;
    }
    const { availableAddresses } = getState().contracts;
    const address = availableAddresses.filter(addr => addr.id === address_id)[0];
    dispatch({
        type: 'SET_ADDRESS',
        payload: address
    });
    dispatch({type: 'SET_ADDRESS_DONE'});
  } catch (e) {
    dispatch({type: 'SET_ADDRESS_ERROR'})
  } finally {
    dispatch(editContract(false));
  }
};

export const fetchIbans = () => async (dispatch, getState, message) => {
  const { token } = getState().auth;
  dispatch({
    type: 'FETCH_IBANS',
    payload: {
      loadingIbans: true
    }
  });
  return data_fetch_api_resource(token, 'ibans/')
    .then( response => {
      dispatch({
        type: 'RECEIVE_IBANS',
        payload: {
          ibans: response.data,
          loadingIbans: false
        }
      }
    );
  });
};

export const setIban = bank_account => async (dispatch) => {
  dispatch({
    type: 'SET_IBAN',
    payload: bank_account
  });
  dispatch(editContract(false));
};

export const setPhone = phone => ({
  type: 'SET_PHONE',
  payload: phone
});

export const setEmail = email => ({
  type: 'SET_EMAIL',
  payload: email
});

export const setCessionSIPS = (status) => ({
  type: 'SET_CESSION_SIPS',
  payload: status
});

export const setElectronicNotification = (status) => ({
  type: 'SET_ELECTRONIC_COMUNICATION',
  payload: status
});

export const setATRAvailable = (status) => ({
  type: 'SET_ATR_AVAILABLE',
  payload: status
});

export const setInvoiceOnline = (status) => ({
  type: 'SET_INVOICE_ONLINE',
  payload: status
});
