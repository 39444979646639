import React from "react";
import { Grid, CardHeader } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import { theme } from '../config';
import { isDefined } from "../utils/misc";

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0, 2, 0)
  },
  header: {
    padding: theme.spacing(0),
  },
  nonMainHeader: {
    color: theme.palette.primary.main,
  },
  mainHeader: {
    color: theme.palette.primary.contrastText,
  },
  mainContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px 4px 0px 0px',
    padding: theme.spacing(1),
  }
}));

export const InfoContainer = props => {
  const {
    title, children, subtitle, titleAvatar, actionButton, mainContainer, className, ...rest
  } = props;
  const classes = useStyles();
  return (
    <div
      {...rest}
    >
      <Grid
        container justifyContent="space-between" direction="row" alignItems="center"
        className={clsx({
          [classes.mainContainer]: mainContainer
        })}
      >
        <Grid item xs>
          <CardHeader
            variant="h5"
            title={title}
            className={classes.header}
            titleTypographyProps={{
              className: clsx({
                [classes.nonMainHeader]: !isDefined(mainContainer) || !mainContainer,
                [classes.mainHeader]: isDefined(mainContainer) && mainContainer
              })
            }}
            subheader={subtitle}
            subheaderTypographyProps={{
              style: {
                ...theme.typography.subtitle1,
                color: mainContainer ? theme.palette.primary.contrastText : theme.typography.h3.color,
              }
            }}
          />
        </Grid>
        {titleAvatar}
        <Grid item>
          {actionButton}
        </Grid>
      </Grid>
      {children}
    </div>
  );
};
