import React from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { i18n } from "../../../config";

class StatusIndicator extends React.Component {
  alertMessage() {
    const {stage, stage_seq} = this.props.status;
    
    switch(stage_seq){
      case 120:
      case 180:
        return(
          <div>
            <Alert severity="success">
              <AlertTitle>{stage}</AlertTitle>
              {i18n.t("common:case_accepted")}
            </Alert>
          </div>
        );
      case 140:
        return(
          <div>
            <Alert severity="error">
              <AlertTitle>{stage}</AlertTitle>
              {i18n.t("common:case_error")}
            </Alert>
          </div>
        );
      case 130:
        return(
          <div>
            <Alert severity="warning">
              <AlertTitle>{stage}</AlertTitle>
              {i18n.t("common:case_warning")}
            </Alert>
          </div>
        );
      case 110:
      case 150:
      case 160:
      case 170:
        return(
          <div>
            <Alert severity="info">
              <AlertTitle>{stage}</AlertTitle>
              {i18n.t("common:case_info")}
            </Alert>
          </div>
        );
      default:
        return (
          <div>
            <Alert severity="info">
              <AlertTitle>{i18n.t("common:case_process")}</AlertTitle>
              {i18n.t("common:case_info")}
            </Alert>
          </div>
        );
    }
  }

  render() {
    if(this.props.status){
      return this.alertMessage();
    }else{
      return (
        <div>
          <Alert severity="info">
            <AlertTitle>{i18n.t("common:case_process")}</AlertTitle>
            {i18n.t("common:case_info")}
          </Alert>
        </div>
      );
    }
    
  };

}

export default compose(
  withTranslation(['common'])
)(StatusIndicator);